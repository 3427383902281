import styled from "styled-components/macro";
import { DEVICE_SIZES } from "../config";

const OptionsLinkContainer = styled.div`
  margin: 12px;
  text-decoration: underline;
  cursor: pointer;
  @media (${DEVICE_SIZES.tablet}) {
    margin: 0 15px 20px 0;
    font-size: ${(props) => props.theme.mobileFontSize.M};
  }
`;

export default OptionsLinkContainer;
