import { RcFile } from "antd/lib/upload";
import firebase from "firebase/app";
import { useState } from "react";
import { StorageFolder } from "../types";
import { validateImageFile } from "../utils";

export interface FileProps {
  folder: StorageFolder;
  userId: string;
  fileName: string;
}

export interface UploadFileProps extends FileProps {
  successCallback: (downloadUrl: string) => void;
  uploadErrorCallback: (error: any) => void;
  validationErrorCallback: (error: string) => void;
  metadata: any;
}
export interface RemoveFileProps extends FileProps {
  successCallback: () => void;
  removeErrorCallback: (error: string) => void;
}
function useFileUpload() {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  function uploadFile(
    file: RcFile,
    {
      folder,
      userId,
      fileName: id,
      successCallback,
      uploadErrorCallback,
      validationErrorCallback,
      metadata,
    }: UploadFileProps
  ) {
    validateImageFile(
      file,
      async () => {
        setLoading(true);
        const storageRef = firebase
          .storage()
          .ref()
          .child(`${folder}/${userId}/${id}`);

        try {
          const uploadTask = storageRef.put(file, {
            customMetadata: metadata,
          });

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

              setProgress(progress);
            },
            () => null,
            async () => {
              const url = await uploadTask.snapshot.ref.getDownloadURL();
              successCallback(url);
              setProgress(0);
            }
          );
        } catch (e) {
          uploadErrorCallback(e);
        } finally {
          setLoading(false);
        }
      },
      (e) => {
        validationErrorCallback(e);
      }
    );
  }

  async function removeFile({
    folder,
    userId,
    fileName: id,
    successCallback,
    removeErrorCallback,
  }: RemoveFileProps) {
    setLoading(true);
    const storageRef = firebase
      .storage()
      .ref()
      .child(`${folder}/${userId}/${id}`);

    try {
      await storageRef.delete();
      successCallback();
    } catch (e) {
      if (e.code === "storage/object-not-found") {
        successCallback();
      } else {
        removeErrorCallback(e.message);
      }
    } finally {
      setLoading(false);
    }
  }

  return [loading, uploadFile, removeFile, progress] as const;
}

export default useFileUpload;
