import { DeleteOutlined } from "@ant-design/icons";
import { Popconfirm } from "antd";
import firebase from "firebase/app";
import { ActionText } from "./EditDeleteActionStyles";
import { EntityExtendedType, EntityType } from "../types";

function EntityDelete<T extends EntityExtendedType>({
  type,
  entity,
  handleDelete,
  entityType,
}: {
  type: "icon" | "text";
  entity: T;
  handleDelete: (id: string) => void;
  entityType: EntityType | "comment";
}) {
  const currentUser = firebase.auth().currentUser;
  return (
    <div className="delete-action" style={{ cursor: "pointer" }}>
      {currentUser && currentUser.uid === entity.authorUid ? (
        <>
          <Popconfirm
            title={`Are you sure to delete the ${entityType}?`}
            onConfirm={() => handleDelete(entity.id)}
            okText="Yes"
            cancelText="No"
          >
            {type === "icon" ? (
              <DeleteOutlined />
            ) : (
              <ActionText>Delete</ActionText>
            )}
          </Popconfirm>
        </>
      ) : null}
    </div>
  );
}

export default EntityDelete;
