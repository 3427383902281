import { CSSProperties } from "react";
import styled from "styled-components/macro";
import Discord from "../assets/images/discord.png";
import Facebook from "../assets/images/facebook.png";
import Instagram from "../assets/images/instagram.png";
import Reddit from "../assets/images/reddit.png";
import Twitter from "../assets/images/twitter.png";
import { SOCIAL_LINKS } from "../config";
import { MediaType } from "../types";
import useWindowSize, { WindowSize } from "../hooks/useWindowSize";

function getImagePath(media: MediaType) {
  switch (media) {
    case "instagram":
      return Instagram;
    case "twitter":
      return Twitter;
    case "facebook":
      return Facebook;
    case "reddit":
      return Reddit;
    case "discord":
      return Discord;
  }
}

const StyledLink = styled.a`
  margin: 0 5px;
`;
function SocialLinks({ style }: { style?: CSSProperties }) {
  const windowSize = useWindowSize();

  const links =
    windowSize > WindowSize.md
      ? SOCIAL_LINKS
      : SOCIAL_LINKS.filter((l) => !l.desktopOnly);
  return (
    <div style={style} className="social-links">
      {links.map((socialMedia) => (
        <StyledLink
          href={socialMedia.link}
          target="_blank"
          key={socialMedia.type}
        >
          <img
            src={getImagePath(socialMedia.type as MediaType)}
            height="20px"
            alt={socialMedia.type}
          />
        </StyledLink>
      ))}
    </div>
  );
}

export default SocialLinks;
