import { ReactNode, useState, createContext, useContext } from "react";

export type EditObjType = {
  isEdit: boolean;
  id?: string;
  text?: string;
};
type EditContentContextValue =
  | undefined
  | {
      editStatusObj: EditObjType;
      setEditObjStatus: React.Dispatch<React.SetStateAction<EditObjType>>;
    };
const EditContentStateContext = createContext<EditContentContextValue>(
  undefined
);

function EditContentProvider({ children }: { children: ReactNode }) {
  const [editStatusObj, setEditObjStatus] = useState<EditObjType>({
    isEdit: false,
  });

  return (
    <EditContentStateContext.Provider
      value={{ editStatusObj, setEditObjStatus }}
    >
      {children}
    </EditContentStateContext.Provider>
  );
}

function useEditContentContext() {
  const context = useContext(EditContentStateContext);
  if (!context) {
    //we may use the context outside of the provider. So the consumer should check if the
    //context value is undefined or not
    return undefined;
  }
  return context;
}

export { EditContentProvider, useEditContentContext };
