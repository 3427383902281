import firebase from "firebase/app";
import { useState, useEffect } from "react";
import { EntityType, EntityExtendedType } from "../types";
import { getEntityCollection, getEntityObjFromAny } from "../utils";

function useEntitySnapshot(type: EntityType, id: string) {
  const [entity, setEntity] = useState<EntityExtendedType>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const collection = getEntityCollection(type);

    const unsubscribe = firebase
      .firestore()
      .collection(collection)
      .doc(id)
      .onSnapshot((doc) => {
        if (doc.exists) {
          setEntity(getEntityObjFromAny(type, doc));
        }

        setLoading(false);
      });
    return () => unsubscribe();
  }, [id, type]);

  return [entity, loading] as const;
}

export default useEntitySnapshot;
