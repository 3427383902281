import { message, Form } from "antd";
import firebase from "firebase/app";
import { useEffect } from "react";
import { goalMaxLength } from "../config";
import useGameHashTag from "../hooks/useGameHashTag";
import useWindowSize, { WindowSize } from "../hooks/useWindowSize";
import { useEditContentContext } from "../stores/edit-content-context";
import { useUserProfileState } from "../stores/UserProfileProvider";
import { AnalyticsEvent, Collection, GameBasicInfo, Goal } from "../types";
import { createGoal, logEvents } from "../utils";
import ActivityTextInput from "./ActivityTextInput";
import ModalForm from "./ModalForm";

function GoalForm({ gameInfo }: any) {
  const [form] = Form.useForm();
  const currentUser = firebase.auth().currentUser as firebase.User;
  const [searchingGame, getGameInfo] = useGameHashTag(currentUser.uid);
  const userProfile = useUserProfileState();
  const editContext = useEditContentContext();
  const windowSize = useWindowSize();
  const isSmallScreen = windowSize <= WindowSize.md;

  useEffect(() => {
    if (editContext && editContext.editStatusObj.text) {
      form.setFieldsValue({ goal: editContext.editStatusObj.text });
    }
  }, [editContext, form]);

  async function handleSubmit() {
    if (!userProfile) {
      throw Error(`userProfile cant be falsy`);
    }
    let gameInfoToSave: GameBasicInfo | undefined = undefined;
    let goalText = form.getFieldValue("goal");

    if (!editContext) {
      if (gameInfo) {
        gameInfoToSave = { ...gameInfo };
      } else {
        gameInfoToSave = await getGameInfo(goalText);
        if (gameInfoToSave) {
          goalText = goalText.slice(0, goalText.indexOf("#")).trim();
        }
      }
      const query = firebase.firestore().collection(Collection.GOALS).doc();

      const goal: Goal = createGoal(
        goalText,
        query.id,
        userProfile,
        gameInfoToSave
      );
      try {
        form.setFieldsValue({ goal: "" }); //Optimistically clearing, if failed rollbacking in error
        await query.set({ ...goal });
        logEvents(AnalyticsEvent.goals, {
          user_id: userProfile.username,
        });
      } catch (e) {
        console.log(e);
        form.setFieldsValue({ goal: goalText });
        message.error(`Unable to save the goal, please try again.`);
      }
    } else if (
      editContext.editStatusObj.isEdit &&
      editContext.editStatusObj.id
    ) {
      try {
        editContext.editStatusObj.isEdit &&
          editContext.setEditObjStatus({ isEdit: false });
        await firebase
          .firestore()
          .collection(Collection.GOALS)
          .doc(editContext.editStatusObj.id)
          .update({
            text: goalText,
            modifiedOn: firebase.firestore.FieldValue.serverTimestamp(),
            modifiedBy: userProfile.username,
            isEdited: true,
          } as Partial<Goal>);
      } catch (e) {
        console.log(e);
        form.setFieldsValue({ goal: goalText });
        message.error(`Unable to save the goal, please try again.`);
      }
    }
  }
  return (
    <ModalForm form={form} style={{ width: "100%" }}>
      <Form.Item
        name="goal"
        rules={[
          { required: true, message: "Please enter your goal." },
          { max: goalMaxLength, message: "Max 60 characters allowed." },
        ]}
        style={{ width: "100%" }}
        className="remove-bottom-margin"
      >
        <ActivityTextInput
          autoFocus={!isSmallScreen}
          placeholder="Type your goal and press enter"
          onSubmit={!searchingGame ? handleSubmit : undefined}
        />
      </Form.Item>
    </ModalForm>
  );
}

export default GoalForm;
