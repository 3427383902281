import styled from "styled-components/macro";
import notFound from "../assets/images/404.png";

const NotFound = styled.div`
  height: 550px;
  background-image: ${`url(${notFound})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export default NotFound;
