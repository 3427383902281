import { forwardRef, ReactNode } from "react";
import { useEditContentContext } from "../stores/edit-content-context";
import { Input } from "antd";

const ActivityTextInput = forwardRef<
  any,
  {
    placeholder: string;
    autoFocus?: boolean;
    disabled?: boolean;
    suffix?: ReactNode;
    onSubmit?: any;
  }
>(({ onSubmit, ...props }, ref) => {
  const editContext = useEditContentContext();

  function handleKeyDown(e: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (e.key === "Escape") {
      editContext && editContext.setEditObjStatus({ isEdit: false });
      e.stopPropagation();
    } else if (e.key === "Enter" && !e.shiftKey) {
      editContext && editContext.setEditObjStatus({ isEdit: false });
      e.preventDefault();
      e.stopPropagation();
      onSubmit && onSubmit();
    }
  }

  return (
    <Input.TextArea
      ref={ref}
      autoSize={{ minRows: 1, maxRows: 3 }}
      onKeyDown={handleKeyDown}
      {...props}
    />
  );
});

export default ActivityTextInput;
