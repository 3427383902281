import { DEVICE_SIZES } from "../config";
import styled from "styled-components/macro";

export const ActionText = styled.span`
  font-size: 14px;
  @media (${DEVICE_SIZES.tablet}) {
    font-size: ${(props) => props.theme.mobileFontSize.XS};
    text-decoration: underline;
  }

  line-height: 1;
  color: ${(props) => props.theme.colors.tertiaryTextColor} !important;
`;
