import { useState, useEffect } from "react";
import firebase from "firebase/app";
import List from "./List";
import LoadMoreButton from "./LoadMoreButton";
import {
  getCommentObjFromAny,
  getEntityCollection,
  getEntityDocPath,
} from "../utils";
import { Collection, Comment, EntityType } from "../types";
import { message } from "antd";
import { useParams } from "react-router-dom";
import FilterLink from "./FilterLink";
import { ACTIVITIES_PAGE_SIZE } from "../config";

function EntityDetailsComments({ entityType }: { entityType: EntityType }) {
  const { id } = useParams<{ id: string }>();
  const [comments, setComments] = useState<Comment[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const [sortBy, setSortBy] = useState<"recent" | "top">("recent");
  const pathPrefix = `${getEntityDocPath(entityType, id)}/comments`;
  const entityCollection = getEntityCollection(entityType);

  const [pageDisplayed, setPageDisplayed] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    let query = firebase
      .firestore()
      .collection(entityCollection)
      .doc(id)
      .collection(Collection.COMMENTS) as firebase.firestore.Query;

    if (sortBy === "top") {
      query = query.orderBy("likes", "desc");
    } else {
      query = query.orderBy("createdOn", "desc");
    }
    if (pageDisplayed === 1) {
      setLoading(true);
    } else {
      setLoadingMore(true);
    }
    const unsubscribeCommentCollection = query
      .limit(ACTIVITIES_PAGE_SIZE.comments * pageDisplayed)
      .onSnapshot(
        { includeMetadataChanges: true },
        (data) => {
          const receivedComments: Comment[] = [];

          data.docs.forEach((commentDoc) => {
            if (!commentDoc.metadata.hasPendingWrites) {
              const comment = getCommentObjFromAny({
                ...commentDoc.data(),
              });
              receivedComments.push(comment);
            }
          });
          setComments([...receivedComments]);
          setLoading(false);
          setLoadingMore(false);
        },
        (e) => {
          console.log(e);
          setLoading(false);
          setLoadingMore(false);
          message.error(`Unable to load the comments`);
        }
      );
    return () => unsubscribeCommentCollection();
  }, [id, sortBy, pageDisplayed]);

  return (
    <>
      {comments.length ? (
        <>
          <FilterLink
            active={sortBy === "recent"}
            onClick={() => setSortBy("recent")}
          >
            Recent
          </FilterLink>{" "}
          |{" "}
          <FilterLink
            active={sortBy === "top"}
            onClick={() => setSortBy("top")}
          >
            Top
          </FilterLink>
        </>
      ) : null}
      <List<Comment>
        listData={comments}
        avatar={"user"}
        dateField="createdOn"
        pathPrefix={pathPrefix}
        loading={loading}
        displayAuthor={true}
        displayComment={false}
        linkableMainText={false}
        fullWidthMainText={true}
        noDataText="No comments yet"
      />
      {(comments.length === ACTIVITIES_PAGE_SIZE.comments * pageDisplayed ||
        loadingMore) && (
        <LoadMoreButton
          loading={loadingMore}
          onPaginate={() => setPageDisplayed(pageDisplayed + 1)}
        />
      )}
    </>
  );
}

export default EntityDetailsComments;
