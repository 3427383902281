import firebase from "firebase/app";
import { useUserProfileState } from "../stores/UserProfileProvider";
import CommentForm from "./CommentForm";
import { message } from "antd";
import { createComment, getEntityCollection, logEvents } from "../utils";
import { EntityType, Collection, AnalyticsEvent } from "../types";

export function NewCommentForm({
  entityId,
  entityType,
}: {
  entityId: string;
  entityType: EntityType;
}) {
  const userProfile = useUserProfileState();
  const entityCollection = getEntityCollection(entityType);

  function handleSubmit(
    { comment, mentions }: { comment: string; mentions: Array<string> },
    onSuccess: () => void
  ) {
    if (!userProfile) {
      throw new Error(`userProfile can't be falsy`);
    }

    const query = firebase
      .firestore()
      .collection(entityCollection)
      .doc(`${entityId}`)
      .collection(Collection.COMMENTS)
      .doc();

    const commentObj = createComment(comment, query.id, userProfile);

    query
      .set({
        ...commentObj,
        mentions,
      })
      .then(
        () => {
          onSuccess();
          logEvents(AnalyticsEvent.comment, entityType);
        },
        (e) => {
          console.log(e);
          message.error(`Unable to save your comment, please try again.`);
        }
      );
  }

  return <CommentForm onSubmit={handleSubmit} />;
}
export default NewCommentForm;
