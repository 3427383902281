import Modal from "../components/Modal";
import InviteCodePanelForm from "../components/InviteCodePanelForm";
import H1 from "../components/H1";
import { useHistory } from "react-router-dom";
import { useState } from "react";

function VerifyCode() {
  const history = useHistory();
  const [visible, setVisible] = useState(true);

  return (
    <>
      <Modal
        afterClose={() => history.replace("/")}
        visible={visible}
        onCancel={() => setVisible(false)}
        logoAsAvatar={true}
      >
        <>
          <H1>Invite-only Mode</H1>
          <p style={{ textAlign: "center" }}>
            WeMake.Games is currently in Close Beta to test functionality &
            network performance.
          </p>
          <InviteCodePanelForm />
        </>
      </Modal>
    </>
  );
}

export default VerifyCode;
