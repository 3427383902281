import { LoadingOutlined } from "@ant-design/icons";
import { Form, Input, message, Popover } from "antd";
import { RcFile } from "antd/lib/upload";
import firebase from "firebase/app";
import { useEffect, useState } from "react";
import styled from "styled-components/macro";
import useFileUpload from "../hooks/useFileUpload";
import useWindowSize, { WindowSize } from "../hooks/useWindowSize";
import { useUserProfileState } from "../stores/UserProfileProvider";
import { AnalyticsEvent, WiP, Collection, GameBasicInfo } from "../types";
import { logEvents, createWip } from "../utils";
import ActivityTextInput from "./ActivityTextInput";
import MediaUrlForm, { MediaSelectionType } from "./MediaUrlForm";
import ModalForm from "./ModalForm";
import Button from "./Button";

const WipFieldsWrapper = styled(Input.Group)`
  &&& {
    display: grid;
    grid-template-columns: auto min-content;
    grid-gap: 10px;
    .ant-input:first-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`;

type WiPTextFormPropsType = {
  initialValue?: string;
  gameInfo?: GameBasicInfo;
  mediaUpload?: boolean;
  handleEditSubmit?: (wip: string, clearWiP: () => void) => void;
};

function WipTextForm(props: WiPTextFormPropsType) {
  const [form] = Form.useForm();
  const userProfile = useUserProfileState();
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<{
    type: MediaSelectionType;
    media: string | RcFile;
  }>();
  const [uploading, uploadFile] = useFileUpload();
  const windowSize = useWindowSize();
  const isSmallScreen = windowSize <= WindowSize.md;

  function saveWip(
    wipRef: firebase.firestore.DocumentReference,
    wip: string,
    url: string,
    fileName?: string
  ) {
    if (userProfile) {
      const wipObj: WiP = createWip(
        wipRef.id,
        wip,
        url,
        userProfile,
        props.gameInfo,
        fileName
      );
      setLoading(true);
      wipRef.set({ ...wipObj }).then(
        () => {
          setLoading(false);
          form.setFieldsValue({ wip: "" });
          setFile(undefined);
          logEvents(AnalyticsEvent.wips, {
            user_id: userProfile.username,
          });
        },
        (e) => {
          setLoading(false);
          console.log(e);
          message.error(`Unable to save the Wip, please try again.`);
        }
      );
    }
  }

  async function handleSubmit() {
    if (!userProfile) {
      return;
    }

    const wip = form.getFieldValue("wip");

    //if edit mode, let the prop handle the submit
    if (props.handleEditSubmit) {
      props.handleEditSubmit(wip, () => {
        form.setFieldsValue({ wip: "" });
      });
      return false;
    }

    //new wip create. file is mandatory
    if (!file) {
      if (props.mediaUpload) {
        form.setFields([
          {
            name: "wip",
            value: wip,
            errors: [`Please select a file`],
          },
        ]);
        return false;
      }
    } else {
      const wipRef = firebase.firestore().collection(Collection.WIPS).doc();

      if (file.type === "file") {
        const fileName = `${new Date().getTime()}`;

        uploadFile(file.media as RcFile, {
          folder: "wips",
          userId: userProfile.username,
          fileName: fileName,
          metadata: {
            wipId: wipRef.id,
          },
          successCallback: (url) => {
            saveWip(wipRef, wip, url, fileName);
          },
          uploadErrorCallback: () => {},
          validationErrorCallback: () => {},
        });
      } else {
        saveWip(wipRef, wip, file.media as string);
      }
    }
  }

  const uploadButton = (
    <div
      style={{
        alignSelf: "start",
        display: "flex",
        width: "100%",
      }}
    >
      <Popover
        content={
          <MediaUrlForm
            onMediaSelect={(type: MediaSelectionType, file) => {
              setFile({ type, media: file });
              return false;
            }}
          />
        }
        destroyTooltipOnHide
        title="Image/Video"
        placement="right"
        trigger="click"
      >
        {uploading || loading ? (
          <LoadingOutlined />
        ) : (
          <Button size="small" style={{ height: "31px" }}>
            {file ? `File selected` : `Upload`}
          </Button>
        )}
      </Popover>
    </div>
  );

  useEffect(() => {
    if (props.initialValue) {
      form.setFieldsValue({ wip: props.initialValue });
    }
  }, [form, props.initialValue]);

  return (
    <ModalForm style={{ width: "100%" }} form={form}>
      <WipFieldsWrapper>
        <Form.Item
          style={{ width: "100%" }}
          className="remove-bottom-margin"
          name="wip"
          rules={[
            {
              required: true,
              message:
                "Please provide a heading to go with your work-in-progress.",
            },
          ]}
        >
          <ActivityTextInput
            autoFocus={!isSmallScreen}
            placeholder="Write something about your Wip"
            disabled={uploading || loading}
            onSubmit={handleSubmit}
          />
        </Form.Item>
        {props.mediaUpload ? uploadButton : null}
      </WipFieldsWrapper>
    </ModalForm>
  );
}

export default WipTextForm;
