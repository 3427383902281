import { useState, useEffect } from "react";
import useFirebaseFnCalling from "../hooks/useFirebaseFnCalling";
import firebase from "firebase/app";
import { Tooltip, message, Button } from "antd";
import { Activity, EntityType, AnalyticsEvent } from "../types";
import { useHistory, useLocation } from "react-router-dom";
import { logEvents } from "../utils";
import { LikeFilled, LikeOutlined } from "@ant-design/icons";

function Like({ tooltip, children, ...props }: any) {
  return (
    <Tooltip title={tooltip}>
      <Button size="small" {...props}>
        {children}
      </Button>
    </Tooltip>
  );
}

function LikeAction<T extends Activity>(props: {
  entity: T;
  docPath: string;
  entityType: EntityType | undefined; //Undefined is for Comments. Possible Improvement
}) {
  const location = useLocation();
  const history = useHistory();
  const currentUser = firebase.auth().currentUser;
  const [, callLikeFunction] = useFirebaseFnCalling();
  const [fakeLikeState, setFakeLikeState] = useState<
    "like" | "unlike" | undefined
  >(undefined);

  useEffect(() => {
    setFakeLikeState(undefined);
  }, [props.entity]);
  async function handleLike(id: string, type: "add" | "remove" = "add") {
    //setEntityIdsUnderLiking(union(entityIdsUnderLiking, [id]));

    setFakeLikeState(type === "add" ? "like" : "unlike");
    if (currentUser) {
      try {
        const token = await currentUser.getIdToken();

        try {
          await callLikeFunction(`like`, {
            likes: type === "remove" ? -1 : 1,
            token: token,
            path: props.docPath,
            gameId: props.entity.gameId,
          });

          logEvents(
            type === "remove" ? AnalyticsEvent.unlike : AnalyticsEvent.like,
            props.entityType || "comments"
          );
        } catch (e) {
          console.log(e);
          message.error(`Unable to add your like, please try again.`);
        }
      } catch (e) {
        console.log(e);
        message.error(`Invalid request found, please login.`);
        firebase.auth().signOut();
      }
    } else {
      message.info(`Join Now to like the goal!`);
    }
    //setEntityIdsUnderLiking(entityIdsUnderLiking.filter(gId => gId !== id));
  }

  const likeCountElement = (
    <span style={{ cursor: "auto", marginLeft: 5 }}>
      {`(${
        props.entity.likes +
        (fakeLikeState === "like" ? 1 : fakeLikeState === "unlike" ? -1 : 0)
      })`}
    </span>
  );

  return (
    <div className="like-action">
      {currentUser ? (
        currentUser.uid === props.entity.authorUid ? (
          <Like tooltip="You can't like your own content" disabled>
            <LikeOutlined />
            {likeCountElement}
          </Like>
        ) : (fakeLikeState === undefined && //It should priority to likesBy only if not in fake mode
            props.entity.likesBy.indexOf(currentUser.uid) > -1) ||
          fakeLikeState === "like" ? (
          <Like
            onClick={() => handleLike(props.entity.id, "remove")}
            tooltip="Remove Like"
          >
            <LikeFilled />
            {likeCountElement}
          </Like>
        ) : (
          <Like onClick={() => handleLike(props.entity.id)} tooltip="Like">
            <LikeOutlined />
            {likeCountElement}
          </Like>
        )
      ) : (
        <Like
          onClick={() =>
            history.push({
              pathname: `/sign-in`,
              state: { backdropLocation: location },
            })
          }
          tooltip="Like"
        >
          <LikeOutlined />
          {likeCountElement}
        </Like>
      )}
    </div>
  );
}

export default LikeAction;
