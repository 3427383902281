import { LoadingOutlined } from "@ant-design/icons";
import { Input, Select, message, Form, Popconfirm } from "antd";
import { RcFile } from "antd/lib/upload";
import firebase from "firebase/app";
import moment from "moment";
import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  gameShortNameMaxLength,
  studioNameMaxLength,
  gameOneLinePitchMaxLength,
  GENRES,
  PLATFORMS,
} from "../config";
import useFileUpload from "../hooks/useFileUpload";
import useWindowSize, { WindowSize } from "../hooks/useWindowSize";
import { AnalyticsEvent, Collection, GameProfile } from "../types";
import { getGameObjFromAny, createGameProfile, logEvents } from "../utils";
import { FieldContainer, PageContainer } from "./ProfileForm";
import H1 from "./H1";
import FileUpload from "./FileUpload";
import MobileViewFileEditDelete from "./MobileViewFileEditDelete";
import ModalForm from "./ModalForm";
import UniqueIdControl from "./UniqueIdControl";
import LocationAutocomplete from "./LocationAutocomplete";
import ReleaseDate from "./ReleaseDate";
import SelectUser from "./SelectUser";
import Button from "./Button";

const CreateEditGameProfileForm = (props: any) => {
  const [form] = Form.useForm();

  const windowSize = useWindowSize();
  const isSmallScreen = windowSize <= WindowSize.md;
  const params: { shortCode: string } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [file, setFile] = useState<RcFile>();
  const [fileError, setFileError] = useState<string>();
  const [deleting, setDeleting] = useState(false);
  const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);

  const [, uploadFile] = useFileUpload();

  const { type } = props;
  const { Option } = Select;

  const uid = (firebase.auth().currentUser as any).uid;

  useEffect(() => {
    if (type === "edit" && params.shortCode) {
      setLoading(true);
      var docRef = firebase
        .firestore()
        .collection(Collection.GAMEPROFILES)
        .doc(params.shortCode);
      const unsubscribe = docRef.onSnapshot(
        function (doc) {
          if (doc.exists) {
            const data = { ...getGameObjFromAny(doc.data()) };

            setTimeout(() => {
              form.setFieldsValue({
                name: data.name,
                shortCode: data.shortCode,
                studioName: data.studioName,
                location: data.location,
                oneLinePitch: data.oneLinePitch,
                genre: data.genre,
                releaseDate: data.releaseDate
                  ? moment((data.releaseDate as any).toDate())
                  : undefined,
                platform: data.platform,
                collaborators: data.collaborators,
                websiteURL: data.websiteURL,
                facebookURL: data.facebookURL,
                youTubeURL: data.youTubeURL,
                twitterURL: data.twitterURL,
              });

              setImageUrl(data.profilePic as string);
            }, 500);
            setLoading(false);
          } else {
            console.log("No such document!");
            setLoading(false);
          }
        },
        function (error) {
          console.log("Error getting document:", error);
          setLoading(false);
        }
      );
      return () => unsubscribe();
    }
  }, [type, params.shortCode]);

  async function saveGame(
    docRef: firebase.firestore.DocumentReference,
    profileData: Partial<GameProfile>,
    url?: string
  ) {
    const data = url ? { ...profileData, profilePic: url } : { ...profileData };
    await docRef.set({ ...data }, { merge: true });

    props.onSuccess && props.onSuccess(docRef.id);
    history.push(`/games/${docRef.id}`);

    if (type === "create") {
      logEvents(AnalyticsEvent.games_creation, docRef.id);
    }

    message.success(
      `${
        type === "create"
          ? "Game created successfully!"
          : "Profile updated successfully!"
      }`
    );
  }

  function handleSubmit(values: any) {
    if (file || imageUrl) {
      const country = values.location
        ? values.location.slice(
            values.location.lastIndexOf(", ") + 2,
            values.location.length
          )
        : "";

      let stakeholders: string[] = [];

      if (values.collaborators) {
        stakeholders = [...values.collaborators, uid];
      } else {
        stakeholders = [uid];
      }

      const clonedValue = { ...values, country, stakeholders };
      clonedValue.releaseDate = clonedValue.releaseDate
        ? new Date(
            (clonedValue.releaseDate as any).toDate().setHours(0, 0, 0, 0)
          )
        : null;
      if (clonedValue.shortCode) {
        setUpdating(true);

        const docRef = firebase
          .firestore()
          .collection(Collection.GAMEPROFILES)
          .doc(clonedValue.shortCode.toLowerCase());
        docRef.get().then(async (doc) => {
          if (doc.exists && type === "create") {
            form.setFields([
              {
                name: "shortCode",
                value: clonedValue.shortCode,
                errors: ["Shortname already in use, try something else."],
              },
            ]);
            setUpdating(false);
          } else {
            const profileData: Partial<GameProfile> =
              type === "create"
                ? createGameProfile(clonedValue, uid)
                : {
                    ...clonedValue,
                    modifiedOn: firebase.firestore.FieldValue.serverTimestamp(),
                    modifiedBy: uid,
                  };

            console.log(profileData);

            try {
              //upload game icon
              if (file && profileData.shortCode) {
                setUpdating(true);
                uploadFile(file, {
                  fileName: profileData.shortCode,
                  userId: uid,
                  folder: "game-icon",
                  metadata: {
                    gameId: profileData.shortCode,
                  },
                  successCallback: async (url) => {
                    await saveGame(docRef, profileData, url);
                    setUpdating(false);
                  },
                  uploadErrorCallback: (e) => {
                    setUpdating(false);
                    message.error(
                      `Failed to upload the game's icon, please try again.`
                    );
                    throw e;
                  },
                  validationErrorCallback: (e) => {
                    setUpdating(false);
                    setFileError(e);
                  },
                });
              } else {
                setUpdating(true);
                await saveGame(docRef, profileData);
                setUpdating(false);
              }
            } catch (error) {
              setUpdating(false);
              message.error(
                `Error ocurred while saving profile data, please try again.`
              );
            }
          }
        });
      }
    } else {
      setFileError("Please provide a game's icon.");
    }
  }

  function onFileSelectSuccess(selectedFile: RcFile) {
    setFile(selectedFile);
    setFileError(undefined);

    //Preview the selected image before upload
    var reader = new FileReader();

    reader.readAsDataURL(selectedFile);
    reader.onload = function (e) {
      if (e.target) {
        setImageUrl((e.target as any).result);
      }
    };
  }

  function onFileDelete() {
    setFile(undefined);
    setFileError(undefined);
    setImageUrl(undefined);
  }
  return (
    <PageContainer>
      <H1>{props.type === "create" ? "Create Game" : "Edit Game"}</H1>
      {loading ? (
        <LoadingOutlined style={{ fontSize: 24 }} spin />
      ) : (
        <>
          <FileUpload
            imageUrl={imageUrl}
            onFileSelectSuccess={(image) => onFileSelectSuccess(image)}
            onFileSelectError={(e) => setFileError(e)}
            onFileDelete={onFileDelete}
            error={fileError}
          />

          <div style={{ display: "flex" }}>
            <MobileViewFileEditDelete onFileDelete={onFileDelete} />
          </div>
          <ModalForm
            form={form}
            onFinish={handleSubmit}
            style={{ width: "100%" }}
          >
            <FieldContainer>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please provide the name of the game.",
                  },
                ]}
              >
                <Input autoFocus={!isSmallScreen} placeholder="Name" />
              </Form.Item>
              <UniqueIdControl
                form={form}
                disabled={type === "edit"}
                collection={Collection.GAMEPROFILES}
                fieldName="shortCode"
                rules={[
                  {
                    required: true,
                    message: "Please provide a unique shortname for the game.",
                  },
                  {
                    max: gameShortNameMaxLength,
                    message: "Max 15 characters allowed",
                  },
                  {
                    pattern: /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/,
                    message: "Only alphanumeric and - allowed",
                  },
                ]}
                validateFirst={true}
                inputProps={{
                  placeholder: "Unique shortname",
                }}
              />
              <Form.Item
                name="studioName"
                rules={[
                  {
                    required: true,
                    message: "Please provide the studio's name.",
                  },
                  {
                    max: studioNameMaxLength,
                    message: "Max 25 characters allowed.",
                  },
                ]}
              >
                <Input placeholder="Studio Name" />
              </Form.Item>
              <Form.Item
                name="location"
                rules={[
                  {
                    required: true,
                    message: "Please provide the location of the studio.",
                  },
                ]}
              >
                <LocationAutocomplete placeholder="Search Location" />
              </Form.Item>
              <Form.Item
                name="oneLinePitch"
                rules={[
                  {
                    required: true,
                    message: "Please provide a one-line pitch for the game.",
                  },
                  {
                    max: gameOneLinePitchMaxLength,
                    message: "Max 70 characters allowed.",
                  },
                ]}
              >
                <Input placeholder="One-line Pitch" />
              </Form.Item>
              <Form.Item
                name="genre"
                rules={[
                  {
                    required: true,
                    message: "Please choose at least one genre.",
                    type: "array",
                  },
                  {
                    validator: (rule, value, callback) => {
                      if (value) {
                        if (value.length > 2) {
                          callback("No more than 2 genre allowed");
                        } else if (value.length <= 5) {
                          callback();
                        }
                      }
                      return;
                    },
                  },
                ]}
              >
                <Select
                  placeholder="Genre (max 2)"
                  mode="multiple"
                  maxTagCount={2}
                  onChange={(a: string[]) => {
                    if (a.length >= 2) {
                      return false;
                    }
                  }}
                >
                  {GENRES.map((genre) => (
                    <Option key={genre.value} value={genre.value}>
                      {genre.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <ReleaseDate
                form={form}
                style={{
                  display: "flex",
                  width: isSmallScreen ? "100%" : "40%",
                  margin: isSmallScreen ? "0" : "0 10px",
                }}
              />
              <Form.Item
                name="platform"
                rules={[
                  {
                    required: true,
                    message: "Please choose at least one platform!",
                    type: "array",
                  },
                ]}
              >
                <Select placeholder="Platform" mode="multiple">
                  {PLATFORMS.map((platform) => (
                    <Option value={platform.value} key={platform.value}>
                      {platform.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="collaborators"
                style={{ width: isSmallScreen ? "100%" : "85%" }}
              >
                <SelectUser {...form} />
              </Form.Item>

              <Form.Item
                name="websiteURL"
                rules={[
                  {
                    type: "url",
                    message: "Please provide a valid URL.",
                  },
                ]}
              >
                <Input placeholder="Website URL (optional)" />
              </Form.Item>

              <Form.Item
                name="twitterURL"
                rules={[
                  {
                    type: "url",
                    message: "Please provide a valid URL.",
                  },
                ]}
              >
                <Input placeholder="Twitter URL (optional)" />
              </Form.Item>
              <Form.Item
                name="facebookURL"
                rules={[
                  {
                    type: "url",
                    message: "Please provide a valid URL.",
                  },
                ]}
              >
                <Input placeholder="Facebook URL (optional)" />
              </Form.Item>
              <Form.Item
                name="youTubeURL"
                rules={[
                  {
                    type: "url",
                    message: "Please provide a valid URL.",
                  },
                ]}
              >
                <Input placeholder="YouTube URL (optional)" />
              </Form.Item>
            </FieldContainer>
            <Button
              type="primary"
              htmlType="submit"
              disabled={updating}
              style={{ width: "70%" }}
            >
              {updating ? <LoadingOutlined /> : "Save Changes"}
            </Button>
          </ModalForm>
          {type === "edit" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: 15,
              }}
            >
              <Popconfirm
                visible={isDeletePopupVisible}
                title="Are you sure you want to delete the game? It will permanently delete the profile and all the data associated with it."
                okText="Yes"
                cancelText="No"
                okButtonProps={{
                  danger: true,
                  loading: deleting,
                }}
                overlayStyle={{ width: 300 }}
                onCancel={() => setIsDeletePopupVisible(false)}
                onConfirm={async () => {
                  try {
                    setDeleting(true);
                    const deleteGameCascade = firebase
                      .functions()
                      .httpsCallable("deleteGameCascade");

                    await deleteGameCascade({ gameId: params.shortCode });
                    message.success(`Your game ${params.shortCode} deleted`);
                    history.push("/");
                  } catch (error) {
                    message.error(`Failed to delete ${params.shortCode}`);
                  } finally {
                    setDeleting(false);
                    setIsDeletePopupVisible(false);
                  }
                }}
              >
                <Button
                  danger={true}
                  style={{ width: "70%" }}
                  onClick={() => setIsDeletePopupVisible(true)}
                >
                  Delete Game
                </Button>
              </Popconfirm>
            </div>
          )}
        </>
      )}
    </PageContainer>
  );
};

export default CreateEditGameProfileForm;
