import styled from "styled-components/macro";
import { DEVICE_SIZES } from "../config";

const H1 = styled.h1`
  font-size: ${(props) => props.theme.fontSizes.h1.desktop};

  @media (${DEVICE_SIZES.tablet}) {
    font-size: ${(props) => props.theme.fontSizes.h1.mobile};
  }
`;

export default H1;
