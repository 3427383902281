import { Checkbox } from "antd";
import { Goal, EntityExtendedType, EntityAvatarType } from "../types";
import Avatar from "./Avatar";
import { Link } from "react-router-dom";
import { getThumbnailStorageUrl } from "../utils";

function EntityAvatar<T extends EntityExtendedType>(props: {
  type: EntityAvatarType;
  entity: T;
  handleChange?: (e: any) => void;
  linkTo?: string;
}) {
  const { authorName, authorUid, authorProfilePic } = props.entity;

  return (
    <>
      {props.type !== "none" ? (
        props.type === "checkbox" ? (
          <Checkbox
            value={props.entity.id}
            checked={((props.entity as unknown) as Goal).done}
            onChange={props.handleChange ? props.handleChange : () => false}
          />
        ) : authorProfilePic ? (
          props.linkTo ? (
            <Link to={props.linkTo}>
              <Avatar
                src={getThumbnailStorageUrl(authorProfilePic, 32)}
                alt={authorName}
              />
            </Link>
          ) : (
            <Avatar
              src={getThumbnailStorageUrl(authorProfilePic, 32)}
              alt={authorName}
            />
          )
        ) : props.linkTo ? (
          <Link to={props.linkTo}>
            <Avatar alt={authorName || authorUid} />
          </Link>
        ) : (
          <Avatar alt={authorName || authorUid} />
        )
      ) : null}
    </>
  );
}

export default EntityAvatar;
