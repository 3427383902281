import { CSSProperties } from "react";
import firebase from "firebase/app";
import { getTimeDistance } from "../utils";
import styled from "styled-components/macro";

const TimeText = styled.span`
  &&& {
    font-size: ${(props) => props.theme.fontSizes.timelineItem.time} !important;
  }
`;
function TimeDurationDisplay<T>({
  entity,
  dateField,
  style,
}: {
  entity: T;
  dateField: keyof T;
  style?: CSSProperties;
}) {
  const date = (entity[dateField] as unknown) as firebase.firestore.FieldValue;
  return <TimeText style={style}>{getTimeDistance(date)}</TimeText>;
}

export default TimeDurationDisplay;
