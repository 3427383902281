import firebase from "firebase/app";
import WipTextForm from "./WipTextForm";
import { Collection, WiP } from "../types";
import { useEditContentContext } from "../stores/edit-content-context";
import { useUserProfileState } from "../stores/UserProfileProvider";

function EditWiPText() {
  const userProfile = useUserProfileState();
  const editContext = useEditContentContext();

  async function handleSubmit(wip: string, clearWiP: () => void) {
    if (editContext && editContext.editStatusObj.isEdit && userProfile) {
      editContext.setEditObjStatus({ isEdit: false });
      await firebase
        .firestore()
        .collection(Collection.WIPS)
        .doc(editContext.editStatusObj.id)
        .update({
          text: wip,
          modifiedOn: firebase.firestore.FieldValue.serverTimestamp(),
          modifiedBy: userProfile.username,
          isEdited: true,
        } as Partial<WiP>);
    }
  }
  return (
    <WipTextForm
      initialValue={
        editContext && editContext.editStatusObj.isEdit
          ? editContext.editStatusObj.text
          : ""
      }
      handleEditSubmit={handleSubmit}
    />
  );
}

export default EditWiPText;
