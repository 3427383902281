import { EntityExtendedType, EntityType } from "../types";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "antd";
import { ENTITY_PATHS } from "../config";
import { MessageOutlined } from "@ant-design/icons";

function CommentAction<T extends EntityExtendedType>(props: {
  entity: T;
  entityType?: EntityType;
}) {
  const { entity, entityType } = props;
  const location = useLocation();
  const history = useHistory();

  // When the list is used for comments, the entityType will be undefined
  if (!entityType) {
    return null;
  }

  return (
    <Button
      size="small"
      onClick={() =>
        history.push({
          pathname: `/${ENTITY_PATHS[entityType]}/${entity.id}`,
          state: {
            backdropLocation: location,
          },
        })
      }
      className="comment-action"
    >
      <span style={{ marginRight: 8 }}>
        <MessageOutlined />
      </span>
      {` (${entity.commentCount})`}
    </Button>
  );
}
export default CommentAction;
