import logo from "../assets/images/logo.png";
import styled from "styled-components/macro";
import { DEVICE_SIZES } from "../config";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  margin: 0 32px;

  @media (${DEVICE_SIZES.tablet}) {
    margin: 0;
  }
`;

function Logo() {
  return (
    <StyledLink to="/" className="logo">
      <img src={logo} alt="logo" height="64px" />
    </StyledLink>
  );
}

export default Logo;
