import { useState, useEffect } from "react";

export enum WindowSize {
  sm = 1,
  md = 2,
  lg = 3,
  xlg = 4,
  undefined = 0,
}
function useWindowSize() {
  const isClient = typeof window === "object";

  function getSize(windowWidth: number): WindowSize {
    if (isClient) {
      if (windowWidth < 480) {
        return WindowSize.sm;
      } else if (windowWidth < 1024) {
        return WindowSize.md;
      } else if (windowWidth < 1200) {
        return WindowSize.lg;
      } else {
        return WindowSize.xlg;
      }
    } else {
      return WindowSize.undefined;
    }
  }

  const [windowSize, setWindowSize] = useState(getSize(window.innerWidth));

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize(window.innerWidth));
    }
    if (isClient) {
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [isClient, setWindowSize]);

  return windowSize;
}

export default useWindowSize;
