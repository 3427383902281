import { LoadingOutlined } from "@ant-design/icons";
import algoliasearch from "algoliasearch";
import { Avatar, Select as AntSelect } from "antd";
import firebase from "firebase/app";
import { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import styled from "styled-components/macro";
import { profileDataType } from "../types";
import { getConfig } from "../utils";

const { Option: AntOption } = AntSelect;

const Select = styled(AntSelect)`
  &&& .ant-avatar-string {
    line-height: 1.4;
  }
  &&& .ant-select-selection__choice__remove {
    color: ${(props) => props.theme.colors.inputColor};
  }
`;

const Option = styled(AntOption)`
  &&& .ant-avatar-string {
    line-height: 1.4;
  }
`;

const {
  algolia: { appId, searchKey },
} = getConfig();

const client = algoliasearch(appId, searchKey);
const index = client.initIndex("common");

function SelectUser(props: any) {
  const [searching, setSearching] = useState(false);
  const [users, setUsers] = useState<profileDataType[]>([]);
  const [user] = useAuthState(firebase.auth());

  async function search(query: string) {
    setSearching(true);

    const result = await index.search(query, {
      filters: `type:user AND NOT objectID:${user?.uid}`,
      hitsPerPage: 10,
    });

    setSearching(false);

    setUsers(
      result.hits.map((hit: any) => ({
        id: hit.objectID as any,
        name: hit.name as string,
        display: hit.username as string,
        profilePic: hit.profilePic as string,
      }))
    );
  }

  return (
    <Select
      mode="multiple"
      placeholder="Select makers (optional)"
      notFoundContent={searching ? <LoadingOutlined /> : null}
      filterOption={false}
      onSearch={search}
      onChange={() => setUsers([])}
      maxTagCount={5}
    >
      {users.map(({ id, name, profilePic }) => (
        <Option key={id} value={`${id}`} className="antd-demo-dynamic-option">
          <>
            {profilePic ? (
              <img src={profilePic} height="20" width="20" alt={id} />
            ) : (
              <Avatar
                style={{
                  width: "20px",
                  height: "20px",
                  textTransform: "uppercase",
                  lineHeight: "1.4",
                }}
                shape="square"
              >
                {name ? name.substring(0, 1) : id.substring(0, 1)}{" "}
              </Avatar>
            )}
            <span>{` ${name ? name + " " : ""}(${id})`}</span>
          </>
        </Option>
      ))}
    </Select>
  );
}

export default SelectUser;
