import { useState, useEffect } from "react";
import { UserProfile, Collection } from "../types";
import firebase from "firebase/app";
import { getUserProfileFromAny } from "../utils";

function useUserProfile(uid: string | undefined) {
  const [userProfile, setUserProfile] = useState<UserProfile | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (uid) {
      const unsubscribe = firebase
        .firestore()
        .collection(Collection.USERPROFILES)
        .doc(uid)
        .onSnapshot((doc) => {
          if (doc.exists) {
            const data = doc.data() as UserProfile;
            setUserProfile(getUserProfileFromAny(data));
          } else {
            console.log(
              `No Document existing Collection : ${"user-profiles"} Doc : ${uid}`
            );
            setUserProfile(undefined);
          }

          setLoading(false);
        });

      return () => unsubscribe();
    } else {
      setUserProfile(undefined);
    }
  }, [uid]);

  return [userProfile, loading] as const;
}

export default useUserProfile;
