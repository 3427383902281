import { Checkbox, Input } from "antd";
import firebase from "firebase/app";
import { Link, useHistory, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import useFirebaseFnCalling from "../hooks/useFirebaseFnCalling";
import {
  GameBasicInfo,
  WiP,
  EntityType,
  EntityExtendedType,
  Screen,
  NotificationSourceScreenType,
  MarkNotificationReadRequestData,
  Collection,
  AnalyticsEvent,
} from "../types";
import WipMediaContainer from "./WipMediaContainer";
import WipMediaWrapper from "./WipMediaWrapper";
import { DEVICE_SIZES, ENTITY_PATHS } from "../config";
import {
  getEntityCollection,
  getEntityDocPath,
  getFirstLineOfText,
  getThumbnailStorageUrl,
  parseVideo,
  logEvents,
} from "../utils";
import LikeAction from "./LikeAction";
import EntityContext from "../contexts/EntityContext";
import Avatar from "./Avatar";
import MediaViewer from "./MediaViewer";
import EditTextBadge from "./EditTextBadge";
import MuteUpdates from "./MuteUpdates";
import TimeDurationDisplay from "./TimeDurationDisplay";
import MetaTags from "./MetaTags";
import NewCommentForm from "./NewCommentForm";
import EntityDetailsComments from "./EntityDetailsComments";
import { useEffect, useState } from "react";
import EntityDelete from "./EntityDelete";
import ButtonLink from "./ButtonLink";
import useFileUpload from "../hooks/useFileUpload";

const ActivityText = styled.span`
  margin-bottom: 0.3rem;
  font-size: ${(props) => props.theme.fontSizes.detailsOnModal.main};
  line-height: ${(props) => props.theme.fontSizes.detailsOnModal.lineHeight};
  word-break: break-word;
  white-space: pre-line;
`;

const DetailsWrapper = styled.div`
  @media (${DEVICE_SIZES.tablet}) {
    padding: 10px;
  }
`;

const WiPMediaInnerContainer = styled.div`
  display: flex;
  justify-content: center;
  @media (${DEVICE_SIZES.tablet}) {
    justify-content: flex-start;
  }
`;

const ActionsContainer = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  grid-row-gap: 5px;
  .right {
    grid-column-start: 2;
    justify-self: end;
  }
  span,
  a,
  ${ButtonLink} {
    color: ${(props) => props.theme.colors.tertiaryTextColor};
  }

  .delete-action {
    display: inline-block;
    color: ${(props) => props.theme.colors.tertiaryTextColor};
    text-decoration: underline;
  }
`;

const MainItemWrapper = styled.div<{ entityType: EntityType }>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.entityType === "goal" ? `30px auto` : `100%`};
  padding: 10px 0;

  .avatar-section {
    display: ${(props) => (props.entityType === "goal" ? `block` : `none`)};
  }
  .mobile-only {
    display: none;
  }

  @media (${DEVICE_SIZES.tablet}) {
    grid-template-columns: 42px auto;

    .avatar-section {
      display: block;
    }
    .desktop-only {
      display: none;
    }
    .mobile-only {
      display: block;
    }
  }
`;

function MainItem({
  entity,
  entityType,
}: {
  entity: EntityExtendedType;
  entityType: EntityType;
}) {
  const history = useHistory();
  const { gameId, gameProfilePic } = (entity as unknown) as GameBasicInfo;
  let mediaUrl =
    entityType === "wip" ? ((entity as unknown) as WiP).mediaUrl : "";
  let mediaThumbnail = "";
  let hasThumbnail = false;
  let hasExternalUrl = mediaUrl && !entity.mediaName;
  const currentUser = firebase.auth().currentUser;
  const dateField = entityType === "goal" ? "completedOn" : "createdOn";
  const [, , removeFiles] = useFileUpload();
  const [editing, setEditing] = useState(false);
  const [entityText, setEntityText] = useState(entity.text);

  const videoType = parseVideo(mediaUrl).type;
  const isMediaVideo = Boolean(videoType);

  if (mediaUrl && !isMediaVideo) {
    mediaThumbnail = getThumbnailStorageUrl(mediaUrl, 250);
    hasThumbnail = entity.thumbs.includes(`${entity.mediaName}_250x250`);
  }

  async function deletePost(id: string) {
    const collection = getEntityCollection(entityType);

    const comments = await firebase
      .firestore()
      .doc(`${collection}/${id}`)
      .collection(Collection.COMMENTS)
      .get();

    for (const comment of comments.docs) {
      comment.ref.delete();
    }

    if (entityType === "wip") {
      const wipDoc = await firebase
        .firestore()
        .doc(`${collection}/${id}`)
        .get();

      const wip = wipDoc.data() as WiP;
      if (wip?.mediaName) {
        removeFiles({
          folder: "wips",
          userId: entity.authorUid,
          fileName: wip.mediaName,
          successCallback: () => {},
          removeErrorCallback: (e) => {
            throw new Error(e);
          },
        });
      }
    }

    await firebase.firestore().doc(`${collection}/${id}`).delete();
    logEvents(AnalyticsEvent.delete, entityType);
    history.push("/");
  }

  return (
    <MainItemWrapper entityType={entityType}>
      <div className="avatar-section">
        <div className="desktop-only">
          {entityType === "goal" && (
            <Checkbox
              checked={true}
              onChange={() => false}
              style={{ marginRight: "0.5rem" }}
            />
          )}
        </div>
        <div className="mobile-only" style={{ marginRight: "10px" }}>
          <Avatar
            src={entity.authorProfilePic || undefined}
            alt={entity.authorName || entity.authorUid}
            size={30}
          />
        </div>
      </div>
      <div>
        {editing ? (
          <Input.TextArea
            value={entityText}
            onChange={(e) => setEntityText(e.target.value)}
            onKeyDown={async (e) => {
              if (e.key === "Escape") {
                e.stopPropagation();
                setEditing(false);
              } else if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                e.stopPropagation();
                await firebase
                  .firestore()
                  .collection(getEntityCollection(entityType))
                  .doc(entity.id)
                  .update({
                    text: entityText,
                    modifiedOn: firebase.firestore.FieldValue.serverTimestamp(),
                    modifiedBy: entity.authorUid,
                    isEdited: true,
                  });
                setEditing(false);
              }
            }}
          />
        ) : (
          <ActivityText>
            {entity.text}{" "}
            {entity.isEdited ? (
              <EditTextBadge entity={entity} entityType={entityType} />
            ) : null}
          </ActivityText>
        )}

        <WipMediaContainer>
          <WiPMediaInnerContainer>
            {mediaUrl && (
              <>
                <WipMediaWrapper>
                  <MediaViewer
                    media={isMediaVideo ? mediaUrl : mediaThumbnail}
                    type={isMediaVideo ? "video" : "image"}
                    alt={getFirstLineOfText(entity.text)}
                    onClick={() => {
                      if (entityType) {
                        history.push({
                          pathname: `/${ENTITY_PATHS[entityType]}/${entity.id}/attachment`,
                        });
                      }
                    }}
                    hasThumbnail={hasExternalUrl || hasThumbnail}
                    videoType={videoType}
                  />
                </WipMediaWrapper>
              </>
            )}
          </WiPMediaInnerContainer>
        </WipMediaContainer>
        <ActionsContainer>
          <LikeAction
            entity={entity}
            docPath={getEntityDocPath(entityType, entity.id)}
            entityType={undefined}
          />

          {gameId && (
            <Link className="right" to={`/games/${gameId}`}>
              {gameId}{" "}
              <Avatar
                src={gameProfilePic || undefined}
                alt={gameId || undefined}
              />
            </Link>
          )}
          <div className="right">
            {currentUser ? (
              <>
                {currentUser.uid === entity.authorUid && (
                  <>
                    <ButtonLink
                      onClick={() => setEditing((editing) => !editing)}
                    >
                      {editing ? "Cancel" : "Edit"}
                    </ButtonLink>
                    <EntityDelete
                      type="text"
                      entity={entity}
                      handleDelete={deletePost}
                      entityType={entityType}
                    />
                  </>
                )}
                <MuteUpdates
                  entityType={entityType}
                  entityId={entity.id}
                  owner={entity.authorUid}
                  isMuted={entity.isMuted}
                />
              </>
            ) : null}
            <TimeDurationDisplay
              entity={entity}
              dateField={dateField}
              style={{ marginLeft: "10px" }}
            />
          </div>
        </ActionsContainer>
      </div>
    </MainItemWrapper>
  );
}

function getScreenFromEntityType(
  entityType: EntityType
): NotificationSourceScreenType {
  switch (entityType) {
    case "goal":
      return Screen.GOAL;
    case "wip":
      return Screen.WIP;
    case "question":
      return Screen.QUESTIONS;
  }
}

function EntityDetails({
  entity,
  entityType,
}: {
  entity: EntityExtendedType;
  entityType: EntityType;
}) {
  const { id } = useParams<{ id: string }>();
  const currentUser = firebase.auth().currentUser;
  const [, callFn] = useFirebaseFnCalling();

  let metaTitle = `${entity.createdBy} on WeMake.Games - ${entity.text}`;
  let metaLink = `https://wemake.games/${ENTITY_PATHS[entityType]}/${id}`;
  let metaDescription = `${entity.createdBy} on WeMake.Games - ${entity.text}`;

  if (entity.gameId) {
    metaTitle = `${entity.gameId} on WeMake.Games - ${entity.text}`;
  }

  useEffect(() => {
    async function markNotifsRead() {
      const screen = getScreenFromEntityType(entityType);
      if (currentUser) {
        const token = await currentUser.getIdToken();
        const requestData: MarkNotificationReadRequestData = {
          token,
          screen,
          id,
        };
        callFn(
          "markNotificationRead",
          requestData,
          () => {},
          () => {
            throw new Error(
              `unable to mark ${screen} notifications read for ${currentUser.uid} `
            );
          }
        );
      }
    }

    markNotifsRead();
  }, []);

  return (
    <EntityContext.Provider value={entity}>
      <DetailsWrapper>
        <MetaTags
          title={metaTitle}
          link={metaLink}
          description={metaDescription}
          imageURL={entity.mediaUrl}
          isCustomImage={entityType === "wip"}
        />
        <MainItem entity={entity} entityType={entityType} />
        <NewCommentForm entityId={id} entityType={entityType} />
        <EntityDetailsComments entityType={entityType} />
      </DetailsWrapper>
    </EntityContext.Provider>
  );
}

export default EntityDetails;
