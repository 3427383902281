import { RouteComponentProps } from "react-router-dom";
import EntityDetails from "../components/EntityDetails";
import EntityDetailsModal from "../components/EntityDetailsModal";
import NotFound from "../components/NotFound";
import useEntitySnapshot from "../hooks/useEntitySnapshot";
import useWindowSize, { WindowSize } from "../hooks/useWindowSize";

type GoalProps = RouteComponentProps<{ id: string }>;

function Goal(props: GoalProps) {
  const windowSize = useWindowSize();
  const isSmallScreen = windowSize <= WindowSize.md;
  const [entity, loading] = useEntitySnapshot("goal", props.match.params.id);

  if (loading) {
    return null;
  }

  if (isSmallScreen) {
    if (!entity) {
      return <NotFound />;
    }

    return <EntityDetails entityType="goal" entity={entity} {...props} />;
  }

  if (!entity) {
    return null;
  }

  return <EntityDetailsModal entityType="goal" entity={entity} {...props} />;
}

export default Goal;
