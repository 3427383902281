import { ListProps } from "antd/lib/list";
import { List as AntList } from "antd";
import styled, { css } from "styled-components/macro";
import { NotificationWithId } from "../types";

export const ExtendedStyleComp = ({
  isPopup,
  ...rest
}: ListProps<NotificationWithId | { text: string }> & { isPopup: boolean }) => (
  <AntList {...rest} />
);

export const NotificationList = styled(ExtendedStyleComp)`
  &&& {
    ${(props) =>
      props.isPopup &&
      css`
        width: ${(props) => props.theme.layoutSizes.notification.maxPopupWidth};
        max-height: ${(props) =>
          props.theme.layoutSizes.notification.maxPopupHeight};
        overflow-y: auto;
        border-radius: 4px;
        background: ${(props) => props.theme.colors.componentBackground};
        padding: 10px;
      `}

    .ant-list-item-meta-description {
      color: ${(props) => props.theme.colors.textColor};
    }

    ul {
      padding-left: 0px;
    }

    .ant-dropdown-menu-header {
      display: flex;
      justify-content: flex-end;
    }
    .ant-dropdown-menu-footer {
      display: flex;
      justify-content: center;
      margin: 5px 0;
    }
    .ant-list-item {
      a {
        width: 100%;
      }

      padding: ${(props) =>
        props.theme.layoutSizes.notification.paddingOfListItems};
      .ant-list-item-meta-title {
        font-size: ${(props) =>
          props.theme.fontSizes.notification.title.fontSize};
        line-height: ${(props) =>
          props.theme.fontSizes.notification.title.lineHeight};
      }
      .ant-list-item-meta-description {
        font-size: ${(props) =>
          props.theme.fontSizes.notification.content.fontSize};
        line-height: ${(props) =>
          props.theme.fontSizes.notification.content.lineHeight};
      }
      .ant-list-item-meta-avatar {
        align-self: center;
      }
    }
    .ant-list-item:not(:last-child) {
      border-bottom: 1px solid #cccccc54;
    }

    .ant-dropdown-menu-items {
      margin-bottom: 5px;
    }
  }
`;
