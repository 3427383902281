import styled from "styled-components/macro";
import { DEVICE_SIZES } from "../config";

const H2 = styled.h2`
  margin-bottom: 20px;
  font-size: ${(props) => props.theme.fontSizes.h2.desktop};
  @media (${DEVICE_SIZES.tablet}) {
    font-size: ${(props) => props.theme.fontSizes.h2.mobile};
  }
`;

export default H2;
