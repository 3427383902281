export const DESIGNATIONS = [
  {
    value: "artist",
    name: "Artist",
  },
  {
    value: "game-designer",
    name: "Game Designer",
  },
  {
    value: "producer",
    name: "Producer",
  },
  {
    value: "programmer",
    name: "Programmer",
  },
  {
    value: "project-manager",
    name: "Project Manager",
  },

  {
    value: "publisher",
    name: "Publisher",
  },
  {
    value: "qa",
    name: "QA",
  },
  {
    value: "sound-designer",
    name: "Sound Designer",
  },
];

export const GENRES = [
  {
    value: "action",
    name: "Action",
  },
  {
    value: "adventure",
    name: "Adventure",
  },
  {
    value: "arcade",
    name: "Arcade",
  },
  {
    value: "casual",
    name: "Casual",
  },
  {
    value: "driving",
    name: "Driving",
  },
  {
    value: "fighting",
    name: "Fighting",
  },
  {
    value: "horror",
    name: "Horror",
  },
  {
    value: "platformer",
    name: "Platformer",
  },
  {
    value: "puzzle",
    name: "Puzzle",
  },
  {
    value: "rpg",
    name: "RPG",
  },
  {
    value: "rhythm",
    name: "Rythm",
  },
  {
    value: "shooter",
    name: "Shooter",
  },
  {
    value: "simulation",
    name: "Simulation",
  },
  {
    value: "sport",
    name: "Sports",
  },
  {
    value: "story",
    name: "Story",
  },
  {
    value: "strategy",
    name: "Strategy",
  },
  {
    value: "trivia",
    name: "Trivia",
  },
  {
    value: "visual-novel",
    name: "Visual Novel",
  },
];

export const GAMES_BEING_MADE_COUNT = { desktop: 6, tablet: 5, mobile: 4 };

export const GAMES_PAGE_SIZE = {
  trending: { desktop: 9, mobile: 6 },
  recent: { desktop: 9, mobile: 8 },
  month: { desktop: 9, mobile: 8 },
  year: { desktop: 9, mobile: 8 },
};
export const ACTIVITIES_PAGE_SIZE = {
  goals: 25,
  wips: 25,
  questions: 25,
  comments: 15,
};

export const gameIconSizeOnGamesPage = { desktop: 99, mobile: 60 }; // in px
export const gameIconSizeOnGamesBeingMade = { desktop: 99, mobile: 60 }; // in px

export const totalActiveMembersToDisplay = 9;

export const trendingGamesCount = 5;
export const trendingQuestionsCount = 5;
export const releasingSoonGamesCount = 5;
export const topStreakRowCount = 10;
export const topSharesRowCount = 10;
export const topHelpersRowCount = 10;

export const promoInfoImageVisibility = {
  home: true, //only for non-signed in user.
  games: true,
  goals: true,
  wips: true,
  questions: true,
};

export const usernameLengthLimit = { lower: 3, upper: 15 };
export const minPasswordLength = 5;
export const goalMaxLength = 120;
export const questionMaxLength = 120;
export const gameShortNameMaxLength = 15;
export const gameOneLinePitchMaxLength = 70;
export const studioNameMaxLength = 25;
export const noOfMyGamesToDisplay = 5;
export const badgeOverflowCount = 9;
export const trendingGameTimeline:
  | "monthActivityCount"
  | "weekActivityCount"
  | "totalActivityCount"
  | "lastSevenDaysActivityCount"
  | "lastThirtyDaysActivityCount" = "lastSevenDaysActivityCount";

export const AdHyperLink = "https://discord.gg/W8EnrDD";

export const promoImageSVGString =
  "data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23faf5fa' fill-opacity='1' fill-rule='evenodd'/%3E%3C/svg%3E";

export const promoButton = [
  {
    text: "Join Now",
    link: "/sign-up",
    display: true,
    isModal: true,
    isPrimary: true,
  },
  {
    text: "Learn More",
    link: "/learn-more",
    display: true,
    isModal: false,
    isPrimary: false,
  },
];

export const PLATFORMS = [
  {
    value: "android",
    name: "Android",
  },
  {
    value: "ar",
    name: "AR",
  },
  {
    value: "ios",
    name: "iOS",
  },
  {
    value: "tvos",
    name: "tvOS",
  },
  {
    value: "macos",
    name: "macOS",
  },
  {
    value: "ps4",
    name: "PS4",
  },
  {
    value: "xboxone",
    name: "Xbox One",
  },
  {
    value: "switch",
    name: "Switch",
  },
  {
    value: "linux",
    name: "Linux",
  },
  {
    value: "web",
    name: "Web",
  },
  {
    value: "windows",
    name: "Windows",
  },
  {
    value: "vr",
    name: "VR",
  },
];

export const SOCIAL_LINKS = [
  {
    type: "instagram",
    link: "https://instagram.com/wemake.games",
  },
  {
    type: "twitter",
    link: "https://twitter.com/wemakegamesapp",
  },
  {
    type: "facebook",
    link: "https://fb.com/wemakegamesapp",
  },
  {
    type: "reddit",
    link: "https://reddit.com/r/wemakegames/",
    desktopOnly: true,
  },
  {
    type: "discord",
    link: "https://discord.gg/W8EnrDD",
    desktopOnly: true,
  },
];

export const FIREBASE_PROD = {
  apiKey: "AIzaSyCVBjBERXpp8QmGCeC1hH4Ii_mj1e55k5U",
  authDomain: "wmg-prod.firebaseapp.com",
  databaseURL: "https://wmg-prod.firebaseio.com",
  projectId: "wmg-prod",
  storageBucket: "wmg-prod.appspot.com",
  messagingSenderId: "684750762212",
  appId: "1:684750762212:web:4579c5e7cc1807e304fcf1",
  measurementId: "UA-132391848-3",
};

export const FIREBASE_DEV = {
  apiKey: "AIzaSyC1GEfWIZg20I_UajoywNVDm45apnNeR44",
  authDomain: "wmg-dev-us.firebaseapp.com",
  databaseURL: "https://wmg-dev-us.firebaseio.com",
  projectId: "wmg-dev-us",
  storageBucket: "wmg-dev-us.appspot.com",
  messagingSenderId: "810568219376",
  appId: "1:810568219376:web:a5323e2234baf3449de035",
  measurementId: "UA-132391848-4",
};

export const ALGOLIA_PROD = {
  appId: "5CYFSXHYLL",
  searchKey: "e409456b977179d3a0a6fa42b749d8d5",
};

export const ALGOLIA_DEV = {
  appId: "TTZO24O720",
  searchKey: "0a9a25287c1ac865b6207b4d752150e1",
};

export const ENTITY_PATHS = {
  goal: "goals",
  question: "discussions",
  wip: "wips",
};

export const CONSTANTS = {
  BrandName: "WeMake.Games",
  TotalStatsDoc: "totalStatsDoc",
  CommentersDoc: "commenters",
  EmailRegEx: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  UsernameRegEx: /^[A-Za-z0-9]+(?:_[A-Za-z0-9]+)*$/,
  DateFormat: "dd-MMM-yy",
};

export const BASE_URL = `https://firebasestorage.googleapis.com/v0/b/wmgdev-8c553.appspot.com/o/`;

export const SUPPORTED_VIDEO_EXTENSIONS = ["mp4", "mkv", "webm", "flv", "3gp"];

const size = {
  mobileS: "340px",
  tablet: "1023px",
  laptopS: "1199px",
  laptopM: "1679px",
  laptopL: "2559px",
  laptopXL: "3839px",
};

export const DEVICE_SIZES = {
  mobileS: `max-width: ${size.mobileS}`,
  tablet: `max-width: ${size.tablet}`,
  laptopS: `max-width: ${size.laptopS}`,
  laptopM: `max-width: ${size.laptopM}`,
  laptopL: `max-width: ${size.laptopL}`,
  laptopXL: `max-width: ${size.laptopXL}`,
};
