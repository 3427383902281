import { ReactNode } from "react";
import styled from "styled-components/macro";
import { Modal as AntModal } from "antd";
import { ModalProps as AntModalProps } from "antd/lib/modal";
import { css } from "styled-components/macro";
import logo from "../assets/images/logo.png";
import Avatar, { AvatarProps } from "./Avatar";
import { Link } from "react-router-dom";

const ExtendedAvatar = ({
  isLogo,
  ...rest
}: AvatarProps & { isLogo?: boolean } & { className?: string }) => (
  <Avatar {...rest} />
);

const StyledAvatar = styled(ExtendedAvatar)`
  &&& {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: ${(props) => props.theme.colors.modalAvatarBackground};
    ${(props) =>
      props.isLogo &&
      css`
        &&& img {
          width: 80%;
          height: unset;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      `}

    &&& .ant-avatar-string {
      font-size: 2.7rem;
      text-transform: uppercase;
    }
  }
`;

const StyledModal = styled(AntModal)<{ centeredBody?: boolean }>`
  &&& .ant-modal-header {
    border-bottom: 0px;
  }

  ${(props) =>
    props.centeredBody &&
    css`
      .ant-modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    `}

  .ant-modal-close {
    color: ${(props) => props.theme.colors.textColor};
  }
`;

interface ModalProps extends AntModalProps {
  children: ReactNode;
  avatarUrl?: string;
  avatarAlt?: string;
  logoAsAvatar?: boolean;
  centeredBody?: boolean;
  titleAvatar?: boolean;
  uid?: string;
}
const Modal: React.FC<ModalProps> = (props) => {
  const {
    avatarAlt,
    avatarUrl,
    centeredBody = true,
    titleAvatar = true,
    logoAsAvatar = false,
    uid,
  } = props;
  return (
    <StyledModal
      title={
        titleAvatar ? (
          logoAsAvatar ? (
            <StyledAvatar size={99} isLogo={true} src={logo} alt="logo" />
          ) : uid ? (
            <Link to={`/users/${uid}`}>
              <StyledAvatar size={99} src={avatarUrl} alt={avatarAlt} />
            </Link>
          ) : (
            <StyledAvatar size={99} src={avatarUrl} alt={avatarAlt} />
          )
        ) : null
      }
      footer={null}
      {...props}
      centeredBody={centeredBody}
    >
      {props.children}
    </StyledModal>
  );
};

export default Modal;
