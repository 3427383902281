import Helmet from "react-helmet";
import { useNotificationContext } from "../stores/notification-context";

type MetaTagType = {
  title: string;
  description?: string;
  keywords?: string;
  link?: string;
  imageURL?: string;
  isCustomImage?: boolean;
  noIndex?: boolean;
};
type HelmetCompPropTypes = Omit<MetaTagType, "isCustomImage">;
const HelmetComp = (props: HelmetCompPropTypes) => {
  const { notificationCount } = useNotificationContext();
  const countToDisplay =
    notificationCount > 9
      ? "(9+)"
      : notificationCount < 1
      ? ""
      : `(${notificationCount})`;
  const {
    title,
    description,
    keywords,
    link,
    imageURL,
    noIndex: defaultNoIndex = false,
  } = props;

  const ogImage =
    imageURL ||
    "https://firebasestorage.googleapis.com/v0/b/wmg-prod.appspot.com/o/website-assets%2Ffacebook-share-banner.jpg?alt=media&token=cceb6953-61c3-4077-beec-202b0c644e70";
  const twitterImage =
    imageURL ||
    "https://firebasestorage.googleapis.com/v0/b/wmg-prod.appspot.com/o/website-assets%2Ftwitter-share-banner.jpg?alt=media&token=fddb9645-8375-4ea3-885c-dda863c39113";

  const noIndex = defaultNoIndex || process.env.REACT_APP_BRANCH !== "master";

  return (
    <Helmet>
      <title>{`${countToDisplay} ${title}`}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={link} />

      {noIndex ? (
        <meta name="robots" content="noindex" />
      ) : (
        <meta name="robots" content="index, follow" />
      )}

      <meta property="og:type" content="Website" />
      <meta property="og:url" content={link} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImage} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={twitterImage} />
      <meta property="twitter:creator" content="@wemakegamesapp" />
      <meta property="twitter:site" content="@wemakegamesapp" />
    </Helmet>
  );
};
function MetaTags(props: MetaTagType) {
  const { isCustomImage, imageURL, ...rest } = props;
  return (
    <>
      {/* <HelmetComp> should not be rendered till the custom image url(wip) is ready to render,
      otherwise while sharing link it will pick the default  */}
      {isCustomImage ? (
        imageURL ? (
          <HelmetComp {...rest} imageURL={imageURL} />
        ) : null
      ) : (
        <HelmetComp {...rest} />
      )}
    </>
  );
}

export default MetaTags;
