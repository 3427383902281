import { useState, Ref, useEffect, forwardRef } from "react";
import { Select } from "antd";
import poweredByGoogle from "../assets/images/powered_by_google.png";
import { debounce, uniq } from "lodash";
import { LoadingOutlined } from "@ant-design/icons";

const { Option } = Select;

function LocationAutocomplete(props: any, ref: Ref<any>) {
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [phrase, setPhrase] = useState("");
  const [debouncedPhrase, setDebouncedPhrase] = useState(phrase);

  useEffect(() => {
    const debounced = debounce(() => setDebouncedPhrase(phrase), 500);
    debounced();
  }, [phrase]);

  useEffect(() => {
    async function search() {
      setLoading(true);
      const autocompleteService: any = new (window as any).google.maps.places.AutocompleteService();

      try {
        autocompleteService.getPlacePredictions(
          {
            types: ["(cities)"],
            input: debouncedPhrase,
          },
          (data: any, status: any) => {
            setLoading(false);
            if (status === "OK") {
              const receivedSuggestions = uniq(
                data.map(
                  (item: any) =>
                    `${item.terms[0].value}, ${
                      item.terms[item.terms.length - 1].value
                    }`
                ) as string[]
              );
              setSuggestions(receivedSuggestions);
            } else if (status === "ZERO_RESULTS") {
              setSuggestions([]);
            } else {
              throw new Error(
                `Error ocurred while fetching location from Google Map : Status : ${status}`
              );
            }
          }
        );
      } catch (e) {
        setLoading(false);
        throw new Error(e);
      }
    }

    if (debouncedPhrase) {
      search();
    }
  }, [debouncedPhrase]);

  return (
    <Select
      {...props}
      showSearch
      onSearch={setPhrase}
      notFoundContent={null}
      loading={loading}
      showArrow={false}
      suffixIcon={loading ? <LoadingOutlined /> : null}
      filterOption={false}
      ref={ref}
    >
      {suggestions.length
        ? suggestions
            .map((item) => (
              <Option value={item} key={item}>
                {item}
              </Option>
            ))
            .concat(
              <Option
                value="powered by google logo"
                key="powered by google logo"
                style={{ textAlign: "right" }}
                disabled
              >
                <span>
                  <img
                    width="50%"
                    src={poweredByGoogle}
                    alt="powered by Google"
                  />
                </span>
              </Option>
            )
        : null}
    </Select>
  );
}

export default forwardRef(LocationAutocomplete);
