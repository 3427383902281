import styled from "styled-components/macro";

const ButtonLink = styled.button`
  text-decoration: underline;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export default ButtonLink;
