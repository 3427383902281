import { AutoComplete, Avatar, Input } from "antd";
import firebase from "firebase/app";
import { profileDataType } from "../types";
import debounce from "lodash/debounce";
import Button from "../components/Button";
import styled from "styled-components/macro";
import { Link, useLocation } from "react-router-dom";
import algoliaSearch from "algoliasearch";
import { getConfig } from "../utils";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";

const ActionButton = styled(Button)`
  margin: 0 0 0 10px;
  line-height: 1.5rem;
`;

const {
  algolia: { appId, searchKey },
} = getConfig();

const client = algoliaSearch(appId, searchKey);
const index = client.initIndex("common");

function AddGameAutoComplete({
  onSelect,
  onRemove,
  defaultValue,
}: {
  onSelect: (id: string) => void;
  onRemove: () => void;
  defaultValue?: string;
}) {
  const location = useLocation();
  const [games, setGames] = useState<profileDataType[]>([]);
  const [phrase, setPhrase] = useState("");
  const [searching, setSearching] = useState(false);
  const [debouncedPhrase, setDebouncedPhrase] = useState(phrase);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const currentUser = firebase.auth().currentUser;

  useEffect(() => {
    const debounced = debounce(() => setDebouncedPhrase(phrase), 500);
    debounced();
  }, [phrase]);

  useEffect(() => {
    async function search() {
      if (!currentUser) {
        return;
      }

      setSearching(true);

      const result = await index.search(debouncedPhrase, {
        filters: `type:game AND (createdBy:${currentUser.uid} OR collaborators: ${currentUser.uid})`,
      });

      const games = result.hits.map(({ objectID, name, profilePic }: any) => ({
        id: objectID,
        name,
        profilePic,
      }));

      setGames(games);
      setSearching(false);
      const timer = setTimeout(() => {
        setDropdownOpen(true);
        clearTimeout(timer);
      }, 100);
    }
    search();
  }, [currentUser, debouncedPhrase]);

  function getDataSource(games: profileDataType[]) {
    let dataSource = games.map(({ id, name, profilePic }) => {
      const regEx = new RegExp(debouncedPhrase, "ig");

      return (
        <AutoComplete.Option key={id} value={id}>
          <>
            {profilePic ? (
              <img src={profilePic} height="20" width="20" alt={id} />
            ) : (
              <Avatar
                style={{
                  width: "20px",
                  height: "20px",
                  textTransform: "uppercase",
                  lineHeight: "1.4",
                }}
                shape="square"
              >
                {name.substring(0, 1)}{" "}
              </Avatar>
            )}
            <span
              dangerouslySetInnerHTML={{
                __html:
                  " " +
                  name.replace(regEx, (match) => `<strong>${match}</strong>`),
              }}
            ></span>
          </>
        </AutoComplete.Option>
      );
    });

    if (!searching) {
      dataSource.push(
        <AutoComplete.Option
          key="create-game-autocomplete"
          value="create-game-autocomplete"
          style={{ textAlign: "center" }}
        >
          <Link
            to={{
              pathname: "/create-game",
              state: { backdropLocation: location },
            }}
          >
            Add Game
          </Link>
        </AutoComplete.Option>
      );
    }

    return dataSource;
  }
  return (
    <>
      <AutoComplete
        style={{ width: 200, marginBottom: "10px" }}
        onSearch={(phrase) => setPhrase(phrase)}
        placeholder="Search game"
        onSelect={(value) => onSelect(value.toString())}
        defaultValue={defaultValue}
        dataSource={getDataSource(games)}
        open={dropdownOpen}
      >
        <Input suffix={searching ? <LoadingOutlined /> : <span />} />
      </AutoComplete>

      {defaultValue && (
        <ActionButton onClick={onRemove}>
          <DeleteOutlined />
        </ActionButton>
      )}
    </>
  );
}

export default AddGameAutoComplete;
