import { useHistory } from "react-router-dom";
import H1 from "../components/H1";
import RequestAccessForm from "../components/RequestAccessForm";
import Modal from "../components/Modal";
import { useState } from "react";

const RequestAccessPanel: React.FC = (props) => {
  const history = useHistory();
  const [visible, setVisible] = useState(true);

  return (
    <>
      <Modal
        afterClose={() => history.replace("/")}
        visible={visible}
        onCancel={() => setVisible(false)}
        destroyOnClose
        logoAsAvatar={true}
      >
        <>
          <H1>Request Early Access</H1>
          <p style={{ textAlign: "center" }}>
            If you wish to sign up for the beta, please enter your email & we'll
            share an invite soon.
          </p>

          <RequestAccessForm />
        </>
      </Modal>
    </>
  );
};

export default RequestAccessPanel;
