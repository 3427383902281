import firebase from "firebase/app";
import { useState } from "react";
import { Collection, GameProfile } from "../types";

function useGameHashTag(userId: string) {
  const [searching, setSearching] = useState(false);
  async function getGameDoc(hashTag: string, userId: string) {
    setSearching(true);
    let gameDoc = await firebase
      .firestore()
      .collection(Collection.GAMEPROFILES)
      .where("shortCode", "==", hashTag)
      .where("createdBy", "==", userId)
      .get();

    if (!gameDoc.docs.length) {
      gameDoc = await firebase
        .firestore()
        .collection(Collection.GAMEPROFILES)
        .where("shortCode", "==", hashTag)
        .where("collaborators", "array-contains", userId)
        .get();
    }
    if (!gameDoc.docs.length) {
      gameDoc = await firebase
        .firestore()
        .collection(Collection.GAMEPROFILES)
        .where("name", "==", hashTag)
        .where("createdBy", "==", userId)
        .get();
    }
    if (!gameDoc.docs.length) {
      gameDoc = await firebase
        .firestore()
        .collection(Collection.GAMEPROFILES)
        .where("name", "==", hashTag)
        .where("collaborators", "array-contains", userId)
        .get();
    }
    setSearching(false);
    return gameDoc.docs.length ? await gameDoc.docs[0] : undefined;
  }

  async function getGameInfo(activityText: string) {
    const hashTag = activityText.includes("#")
      ? activityText.split("#")[1]
      : undefined;

    if (hashTag) {
      const gameDoc = await getGameDoc(hashTag, userId);
      if (gameDoc) {
        const gameProfile = gameDoc.data() as GameProfile;
        return {
          gameId: gameDoc.id,
          gameName: gameProfile.name,
          gameProfilePic: gameProfile.profilePic || null,
        };
      }
    }
    return undefined;
  }

  return [searching, getGameInfo] as const;
}

export default useGameHashTag;
