import { Form, Input, message as alertMessage } from "antd";
import { LoadingOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
import firebase from "firebase/app";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import useFirebaseFnCalling from "../hooks/useFirebaseFnCalling";
import { AnalyticsEvent, Collection, UserProfile } from "../types";
import { signIn, logEvents } from "../utils";
import {
  ALink,
  PrimaryButton,
  SecondaryButtonWrapper,
} from "./SignInFlowComponents";
import { CONSTANTS, DEVICE_SIZES } from "../config";
import ModalForm from "./ModalForm";
import ButtonLink from "./ButtonLink";
import Button from "./Button";

const InlineFieldsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  &&& .ant-form-item {
    margin-right: 5px;
    margin-left: 5px;
  }
  @media (${DEVICE_SIZES.tablet}) {
    flex-direction: column;
    &&& .ant-form-item {
      margin-right: 0px;
      margin-left: 0px;
    }
  }
`;

const SecondaryButtonWrappersContainer = styled.div`
  display: none;
  @media (${DEVICE_SIZES.tablet}) {
    display: grid;
    grid-template-columns: 50% 47%;
    width: 100%;
    justify-content: space-between;
  }
`;

const ErrorMessage = styled.span`
  color: ${(props) => props.theme.colors.dangerText};
  line-height: 1.5rem;
`;

type SignInFormPropsType = {
  uid: string;
};

function SignInForm({ uid = "" }: SignInFormPropsType) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation<any>();

  //if email is not verified that will be stored in this var.
  //the same will be used to show email unverified error
  const [
    verificationFailedEmail,
    setVerificationFailedEmail,
  ] = useState<string>();
  const [, callFirebaseFn] = useFirebaseFnCalling();

  async function handleSubmit(values: any) {
    let email: string | undefined = undefined;
    const isEmail = CONSTANTS.EmailRegEx.test(values.id);
    if (!isEmail) {
      const userDoc = await firebase
        .firestore()
        .collection(Collection.USERPROFILES)
        .doc(values.id)
        .get();
      if (userDoc.exists) {
        email = (userDoc.data() as UserProfile).email;
      } else {
        alertMessage.error(
          `Invalid ${isEmail ? `Email` : `Username`} or Password`
        );
      }
    } else {
      email = values.id;
    }

    if (email) {
      setLoading(true);
      callFirebaseFn(
        "isEmailVerified",
        { email },
        async ({ data: { emailVerified, lastSignInTime } }: any) => {
          if (emailVerified && email) {
            try {
              const credentials = await signIn(email, values.password);

              alertMessage.success("Signed in");

              if (!lastSignInTime) {
                logEvents(AnalyticsEvent.user_first_login, email);
                history.push(`/users/${credentials.user?.uid}/edit`);
              }
            } catch (error) {
              if (
                error.code === "auth/wrong-password" ||
                error.code === "auth/user-not-found"
              ) {
                alertMessage.error(
                  `Invalid ${isEmail ? `Email` : `Username`} or Password`
                );
              } else {
                alertMessage.error(error.message);
              }
            }
          } else {
            setVerificationFailedEmail(email);
            form.setFieldsValue({ password: "" });
          }
          setLoading(false);
        },
        (error) => {
          console.log(error.message);
          if (error.message === "auth/user-not-found") {
            alertMessage.error(
              `Invalid ${isEmail ? `Email` : `Username`} or Password`
            );
          } else {
            alertMessage.error("Error occurred while signing in");
          }
          setLoading(false);
        }
      );
    }
  }

  return (
    <ModalForm onFinish={handleSubmit} form={form} initialValues={{ id: uid }}>
      {verificationFailedEmail && (
        <ErrorMessage style={{ color: "red" }}>
          Email is not verified.{" "}
          <ButtonLink
            onClick={() => {
              history.push(
                `/resend-verification-email/${verificationFailedEmail}`
              );
            }}
          >
            Resend
          </ButtonLink>
        </ErrorMessage>
      )}
      <InlineFieldsContainer>
        <Form.Item
          name="id"
          validateFirst
          normalize={(input) => input?.toLowerCase()}
          rules={[
            {
              required: true,
              message: "Please enter a registered username or email ID.",
            },
          ]}
        >
          <Input
            autoFocus
            prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Email/Username"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please type your password." }]}
        >
          <Input
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
      </InlineFieldsContainer>

      <ALink
        to={{
          pathname: "/forgot-password",
          state: {
            backdropLocation: location.state && location.state.backdropLocation,
          },
        }}
      >
        Forgot Password?
      </ALink>
      <PrimaryButton type="primary" htmlType="submit" disabled={loading}>
        {loading ? <LoadingOutlined /> : "Sign In"}
      </PrimaryButton>

      <SecondaryButtonWrappersContainer>
        <SecondaryButtonWrapper
          to={{
            pathname: "/forgot-password",
            state: {
              backdropLocation:
                location.state && location.state.backdropLocation,
            },
          }}
        >
          <Button>Forgot Password?</Button>
        </SecondaryButtonWrapper>
        <SecondaryButtonWrapper
          to={{
            pathname: "/sign-up",
            state: {
              backdropLocation:
                location.state && location.state.backdropLocation,
            },
          }}
        >
          <Button>Sign up here</Button>
        </SecondaryButtonWrapper>
      </SecondaryButtonWrappersContainer>
      <ALink
        to={{
          pathname: "/sign-up",
          state: {
            backdropLocation: location.state && location.state.backdropLocation,
          },
        }}
      >
        Sign Up
      </ALink>
    </ModalForm>
  );
}

export default SignInForm;
