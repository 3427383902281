import { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import { EntityType } from "../types";
import { ENTITY_PATHS } from "../config";

function EntityLink({
  children,
  className,
  entityType,
  id,
}: {
  children: ReactNode;
  className?: string;
  entityType: EntityType;
  id: string;
}) {
  const location = useLocation();

  return (
    <Link
      to={{
        pathname: `/${ENTITY_PATHS[entityType]}/${id}`,
        state: {
          backdropLocation: location,
        },
      }}
      className={className}
    >
      {children}
    </Link>
  );
}

export default EntityLink;
