import DefaultLightbox from "lightbox-react";
import { useState } from "react";
import { parseVideo } from "../utils";

function AddressableLightbox({
  videoUrl,
  imageUrls,
  defaultCurrentIndex,
  onClose,
  onPrevious,
  onNext,
}: {
  videoUrl?: string;
  imageUrls: string[];
  defaultCurrentIndex: number;
  onClose: () => void;
  onNext?: (nextIndex: number) => void;
  onPrevious?: (previousIndex: number) => void;
}) {
  const [currentIndex, setCurrentIndex] = useState(defaultCurrentIndex);

  let sources: any[] = imageUrls.filter((url) => !!url);

  if (videoUrl) {
    sources.splice(0, 0, getVideoSrc(videoUrl));
  }

  const previousIndex = (currentIndex + sources.length - 1) % sources.length;
  const nextIndex = (currentIndex + 1) % sources.length;

  return (
    <DefaultLightbox
      mainSrc={sources[currentIndex]}
      nextSrc={sources.length > 1 ? sources[nextIndex] : undefined}
      prevSrc={sources.length > 1 ? sources[previousIndex] : undefined}
      onCloseRequest={onClose}
      onMovePrevRequest={() => {
        setCurrentIndex(previousIndex);
        if (onPrevious) {
          onPrevious(previousIndex);
        }
      }}
      onMoveNextRequest={() => {
        setCurrentIndex(nextIndex);
        if (onNext) {
          onNext(nextIndex);
        }
      }}
      clickOutsideToClose={true}
    />
  );
}

function getVideoSrc(videoUrl: string) {
  const videoObj = parseVideo(videoUrl);

  return (
    <iframe
      title="cats"
      width="560"
      height="315"
      src={
        videoObj.type === "youtube"
          ? "//www.youtube.com/embed/" + videoObj.id
          : "//player.vimeo.com/video/" + videoObj.id
      }
      style={{
        maxWidth: "97%",
        position: "absolute",
        left: 0,
        right: 0,
        margin: "auto",
        top: "50%",
        transform: "translateY(-50%)",
      }}
      allowFullScreen={true}
    />
  );
}

export default AddressableLightbox;
