import styled from "styled-components/macro";
import Button from "./Button";
import { DEVICE_SIZES } from "../config";
import { Link } from "react-router-dom";

export const ALink = styled(Link)`
  margin-bottom: 5px;
  @media (${DEVICE_SIZES.tablet}) {
    display: none;
  }
`;

export const PrimaryButton = styled(Button)`
  width: 70%;
  margin-bottom: 5px;
  text-transform: uppercase;

  @media (${DEVICE_SIZES.tablet}) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const SecondaryButtonWrapper = styled(Link)`
  display: none;

  ${Button} {
    text-transform: uppercase;
    font-size: 13px;
  }
  @media (${DEVICE_SIZES.tablet}) {
    display: block;
    ${Button} {
      font-size: ${(props) => props.theme.mobileFontSize.XS};
    }
    &&& {
      width: 100%;
      ${Button} {
        width: 100%;

        padding: 0;
      }
    }
  }
`;
