import { Button as AntButton } from "antd";
import styled from "styled-components/macro";

const Button = styled(AntButton)`
  font-size: ${(props) =>
    props.size === "small"
      ? props.theme.fontSizes.button.small
      : props.size === "large"
      ? props.theme.fontSizes.button.large
      : props.theme.fontSizes.button.default};
`;

export default Button;
