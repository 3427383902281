import { useEffect } from "react";
import { Dropdown, Badge, List as AntList } from "antd";
import styled from "styled-components/macro";
import { useUserProfileState } from "../stores/UserProfileProvider";
import Avatar from "./Avatar";
import { badgeOverflowCount } from "../config";
import { NavigateToType } from "../types";
import { Link } from "react-router-dom";
import useNotifications from "../hooks/useNotifications";
import theme from "../theme";
import { NotificationList } from "./NotificationList";
import { NotificationSourceScreenType, Screen } from "../types";
import { useNotificationContext } from "../stores/notification-context";
import ButtonLink from "./ButtonLink";
import { BellOutlined } from "@ant-design/icons";

const NotificationIcon = styled.div`
  display: flex;
  align-items: center;
  text-align: right;
  margin-right: 0.75rem;
  cursor: pointer;

  &&& {
    svg {
      fill: ${(props) => props.theme.colors.mainHeaderText};
      font-size: ${(props) => props.theme.fontSizes.profileMenu};
    }
  }
`;

export default function NotificationPopup() {
  const userProfile = useUserProfileState();
  const {
    notifications,
    getNotificationPath,
    markNotificationAsRead,
    markAllNotificationRead,
    markingAllRead,
  } = useNotifications({ limit: 7, onlyUnread: false });

  const unreadCount = notifications.filter((x: any) => !x.read).length;
  const potentialUnreadCount = markingAllRead ? 0 : unreadCount;

  const { setNotificationCount } = useNotificationContext();

  useEffect(() => {
    setNotificationCount(potentialUnreadCount);
  }, [potentialUnreadCount]);

  function shouldMarkNotificationRead(screen: NotificationSourceScreenType) {
    return ![
      Screen.GOAL,
      Screen.WIP,
      Screen.QUESTIONS,
      Screen.INVITES,
    ].includes(screen);
  }

  if (!userProfile) {
    return null;
  }

  return (
    <>
      <Dropdown
        overlay={
          notifications.length ? (
            <NotificationList
              isPopup={true}
              dataSource={notifications}
              footer={<Link to="/notifications">See all</Link>}
              header={
                potentialUnreadCount ? (
                  <ButtonLink onClick={markAllNotificationRead}>
                    Mark all read
                  </ButtonLink>
                ) : null
              }
              renderItem={(item: any) => (
                <AntList.Item style={{ opacity: item.read ? 0.5 : 1 }}>
                  <Link
                    to={getNotificationPath(
                      item.navigateTo as NavigateToType,
                      item.navigateToId
                    )}
                    onClick={() =>
                      !markingAllRead &&
                      shouldMarkNotificationRead(item.navigateTo) &&
                      markNotificationAsRead(item.id, item.notificationFor)
                    }
                  >
                    <AntList.Item.Meta
                      avatar={
                        <Avatar
                          src={item.notificationTriggeredByProfilePic}
                          alt={item.notificationTriggeredBy}
                          size={theme.layoutSizes.notification.avatarSize}
                        />
                      }
                      title={item.webTitle}
                      description={item.webContent}
                    />
                  </Link>
                </AntList.Item>
              )}
            />
          ) : (
            <NotificationList
              dataSource={[{ text: "No unread messages" }]}
              renderItem={(item: any) => (
                <AntList.Item>{item.text}</AntList.Item>
              )}
              footer={<Link to="/notifications">See all</Link>}
              isPopup={true}
            />
          )
        }
        placement="bottomRight"
        trigger={["click"]}
      >
        <NotificationIcon>
          <Badge
            count={potentialUnreadCount}
            overflowCount={badgeOverflowCount}
            size="small"
          >
            <BellOutlined />
          </Badge>
        </NotificationIcon>
      </Dropdown>
    </>
  );
}
