// import { Form, Input, message } from "antd";
// import { FormComponentProps } from "antd/lib/form";
// import { useEffect } from "react";
// import { useHistory, useLocation } from "react-router-dom";
// import { Loading } from ".";
// import { useFirebaseFnCalling } from "../hooks";
// import { AnalyticsEvents } from "../models/analytics-events";
// import { Button, ModalForm, SignInFlowComponents } from "../styles";
// import { hasErrors } from "../utils";
// import logEvents from "../utils/analytics";

// const { ALink, PrimaryButton, SecondaryButtonWrapper } = SignInFlowComponents;

// const RequestAccessForm: React.FC<FormComponentProps> = (props) => {
//   const {
//     getFieldDecorator,
//     getFieldsError,
//     getFieldError,
//     isFieldTouched,
//   } = props.form;

//   const [loading, callFunction] = useFirebaseFnCalling();
//   const location = useLocation();
//   const history = useHistory();

//   // Only show error after a field is touched.
//   const emailError = isFieldTouched("email") && getFieldError("email");
//   function handleSubmit(e: React.FormEvent<HTMLElement>) {
//     e.preventDefault();
//     props.form.validateFields(async (err, values) => {
//       if (!err) {
//         await callFunction("requestAccess", { email: values.email }, () => {
//           history.replace("/");
//           logEvents(AnalyticsEvents.user_requests);
//           message.success(
//             "Thank you for your interest. You've been added to the queue and shall receive the invite soon.",
//             5
//           );
//         });
//       }
//     });
//   }

//   useEffect(() => {
//     props.form.validateFields();
//   }, []);

//   return (
//     <ModalForm onSubmit={handleSubmit}>
//       <Form.Item
//         validateStatus={emailError ? "error" : ""}
//         help={emailError || ""}
//         style={{ width: "100%" }}
//       >
//         {getFieldDecorator("email", {
//           rules: [
//             { required: true, message: "Please provide your email id." },
//             {
//               type: "email",
//               message: "Please provide correct email id.",
//             },
//           ],
//         })(<Input autoFocus placeholder="email" />)}
//       </Form.Item>

//       <PrimaryButton
//         type="primary"
//         htmlType="submit"
//         disabled={loading || hasErrors(getFieldsError())}
//       >
//         {loading ? <Loading /> : "Submit"}
//       </PrimaryButton>

//       <ALink
//         to={{ pathname: "/verify-code", state: { backdropLocation: location } }}
//       >
//         Have a code? Sign up here
//       </ALink>
//       <SecondaryButtonWrapper
//         to={{ pathname: "/verify-code", state: { backdropLocation: location } }}
//       >
//         <Button>Have a code? Sign up here</Button>
//       </SecondaryButtonWrapper>
//     </ModalForm>
//   );
// };

// export default RequestAccessPanelForm;
export default (props: any) => null;
