import { Form, Input } from "antd";
import { InputProps } from "antd/lib/input";
import { debounce } from "lodash";
import { useState } from "react";
import { usernameLengthLimit } from "../config";
import useFirebaseFnCalling from "../hooks/useFirebaseFnCalling";
import { Rule, FormInstance } from "antd/lib/form";
import { Collection } from "../types";

type UniqueIdControlProps = {
  form: FormInstance<unknown>;
  disabled?: boolean;
  collection: Collection;
  fieldName: string;
  rules?: Rule[];
  validateFirst?: boolean;
  inputProps?: InputProps;
  inputRef?: any;
};

function UniquIdControl({
  form,
  disabled,
  collection,
  fieldName,
  rules,
  validateFirst,
  inputProps,
  inputRef,
}: UniqueIdControlProps) {
  const [validating, setValidating] = useState<
    "error" | "success" | "validating" | undefined
  >();

  const [, callFunction] = useFirebaseFnCalling();
  const message =
    collection === Collection.GAMEPROFILES
      ? "Game code already exists"
      : collection === Collection.USERPROFILES
      ? "Username already exists"
      : "Value already exists";

  const isAvailable = debounce(async (e: any) => {
    const text: string = form.getFieldValue(fieldName);

    if (text) {
      if (
        collection === Collection.USERPROFILES &&
        !(
          text.length >= usernameLengthLimit.lower &&
          text.length <= usernameLengthLimit.upper
        )
      ) {
        form.setFields([
          {
            name: fieldName,
            value: text,
            errors: [
              `No of characters should be between ${usernameLengthLimit.lower}-${usernameLengthLimit.upper}`,
            ],
          },
        ]);
        setValidating("error");
      } else {
        if (!form.getFieldError(fieldName)) {
          setValidating("validating");
          await callFunction(
            `searchId`,
            {
              term: text,
              collection: collection,
            },
            (response) => {
              if (response.data.isAllowed) {
                setValidating("success");
              } else {
                form.setFields([
                  {
                    name: fieldName,
                    value: text,
                    errors: [message],
                  },
                ]);
                setValidating("error");
              }
            },
            (e) => {
              console.log(e);
              setValidating(undefined);
            }
          );
        }
      }
    } else {
      setValidating(undefined);
    }
  }, 1500);

  return (
    <Form.Item
      name={fieldName}
      rules={rules}
      validateFirst={validateFirst}
      hasFeedback={!disabled}
      validateStatus={validating}
      normalize={(input) => input?.toLowerCase()}
    >
      <Input
        ref={inputRef}
        disabled={disabled}
        onChange={isAvailable}
        {...inputProps}
      />
    </Form.Item>
  );
}

export default UniquIdControl;
