import styled from "styled-components/macro";
import { DEVICE_SIZES } from "../config";

const PageContainer = styled.div`
  text-align: center;
  @media (${DEVICE_SIZES.tablet}) {
    text-align: left;
    padding: ${(props) =>
      props.theme.layoutSizes.spaceBetweenComponents.mobile};
  }
`;

const FieldContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .ant-form-item {
    width: 40%;
    margin-right: 10px;
    margin-left: 10px;
    @media (${DEVICE_SIZES.tablet}) {
      width: 100%;
      margin-right: 0px;
      margin-left: 0px;
    }
  }
`;

export { PageContainer, FieldContainer };
