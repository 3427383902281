import OptionsLinkContainer from "./OptionsLinkContainer";
import Popconfirm from "antd/lib/popconfirm";
import ButtonLink from "./ButtonLink";

function MobileViewFileEditDelete({
  onFileDelete,
}: {
  onFileDelete: () => void;
}) {
  return (
    <>
      <OptionsLinkContainer>
        <ButtonLink className="mobile-only">
          <label htmlFor="upload-profile-pic"> Edit Image</label>
        </ButtonLink>
      </OptionsLinkContainer>

      <Popconfirm
        className="mobile-only"
        onConfirm={onFileDelete}
        title="Are you sure to delete the picture?"
        placement="right"
      >
        <OptionsLinkContainer>
          <ButtonLink className="mobile-only">
            <label> Delete Image</label>
          </ButtonLink>
        </OptionsLinkContainer>
      </Popconfirm>
    </>
  );
}

export default MobileViewFileEditDelete;
