import { RouteComponentProps } from "react-router-dom";
import AddressableLightbox from "../components/AddressableLightbox";
import MetaTags from "../components/MetaTags";
import useEntitySnapshot from "../hooks/useEntitySnapshot";
import { Fragment } from "react";
import NotFound from "../components/NotFound";

function WipAttachment(props: RouteComponentProps<{ id: string }, {}, any>) {
  const [entity, loading] = useEntitySnapshot("wip", props.match.params.id);
  const backdropLocation =
    props.location.state && props.location.state.backdropLocation;

  if (loading) {
    return null;
  }

  if (!entity) {
    return <NotFound />;
  }

  let metaTitle = `${entity.createdBy} on WeMake.Games - ${entity.text}`;
  let metaLink = `https://wemake.games/wips/${entity.id}/attachment`;
  let metaDescription = `${entity.createdBy} on WeMake.Games - ${entity.text}`;

  if (entity.gameId) {
    metaTitle = `${entity.gameId} on WeMake.Games - ${entity.text}`;
  }

  return (
    <Fragment>
      <MetaTags
        title={metaTitle}
        link={metaLink}
        description={metaDescription}
        imageURL={entity.mediaUrl}
        isCustomImage={true}
      />
      <AddressableLightbox
        imageUrls={[entity.mediaUrl]}
        defaultCurrentIndex={0}
        onClose={() => {
          if (props.location.pathname === backdropLocation.pathname) {
            props.history.push("/");
          } else {
            props.history.push(backdropLocation.pathname);
          }
        }}
      />
    </Fragment>
  );
}

export default WipAttachment;
