import firebase from "firebase/app";
import { ReactNode } from "react";

export enum Collection {
  GOALS = "goals",
  WIPS = "wips",
  QUESTIONS = "questions",
  COMMENTS = "comments",
  INVITATIONREQUEST = "invitation-requests",
  USERPROFILES = "user-profiles",
  GAMEPROFILES = "game-profiles",
  STATS = "stats",
  NOTIFICATIONS = "notifications",
  PARTICIPANTS = "participants",
}

export enum AnalyticsEvent {
  user_first_login = "user_first_login",
  user_signups = "user_signups",
  user_onboarded = "user_onboarded",
  user_requests = "user_requests",
  user_logins = "user_logins",
  user_invites = "user_invites",
  user_streaks = "user_streaks",
  user_favourites = "user_favourites",
  user_goals = "user_goals",
  user_goal_hearts = "user_goal_hearts",
  user_goal_comments = "user_goal_comments",
  user_wips = "user_wips",
  user_wip_hearts = "user_wip_hearts",
  user_wip_comments = "user_wip_comments",
  user_questions = "user_questions",
  user_question_hearts = "user_question_hearts",
  user_question_comments = "user_question_comments",
  user_goalscomplete = "user_goalscomplete",
  user_games = "user_games",
  user_countries = "user_countries",
  user_designations = "user_designations",
  games_creation = "games_creation",
  games_count = "games_count",
  game_goals = "game_goals",
  game_wips = "game_wips",
  game_withcollaborator = "game_withcollaborator",
  game_allinfo = "game_allinfo",
  game_genres = "game_genres",
  game_platforms = "game_platforms",
  game_countries = "game_countries",
  game_releases = "game_releases",
  game_favourites = "game_favourites",
  goals_count = "goals_count",
  goals_completed = "goals_completed",
  goals_hearts = "goals_hearts",
  goals_comments = "goals_comments",
  goal_hearts = "goal_hearts",
  goal_comments = "goal_comments",
  goal_favourites = "goal_favourites",
  wips_count = "wips_count",
  wips_hearts = "wips_hearts",
  wips_comments = "wips_comments",
  wip_hearts = "wip_hearts",
  wip_comments = "wip_comments",
  wip_favourites = "wip_favourites",
  questions_count = "questions_count",
  questions_hearts = "questions_hearts",
  questions_comments = "questions_comments",
  question_hearts = "question_hearts",
  question_comments = "question_comments",
  question_favourites = "question_favourites",
  like = "like",
  unlike = "unlike",
  comment = "comments",
  delete = "delete",
  goals = "goals",
  wips = "wips",
  questions = "questions",
  logout = "logout",
}

export interface Comment extends Activity {
  mentions: Array<string>;
}

export interface GameProfile extends AuditWithoutIdentity {
  name: string;
  shortCode: string;
  profilePic?: string;
  studioName?: string;
  location?: string;
  country?: string;
  oneLinePitch?: string;
  longDescription?: string;
  genre?: string[];
  releaseDate?: string | null | Date;
  platform: string[];
  collaborators: string[];
  stakeholders: string[];
  thumbs: string[];

  websiteURL?: string;
  facebookURL?: string;
  youTubeURL?: string;
  twitterURL?: string;
  videoUrl?: string;
  thumbnailUrl?: string;

  imageUrls: Array<ImageURLType>;
  tags: string[];
  profileViewCount: number;
  dayActivityCount: number;
  weekActivityCount: number;
  monthActivityCount: number;
  yearActivityCount: number;
  lastSevenDaysActivityCount: number;
  lastThirtyDaysActivityCount: number;
  goalDayActivityCount: number;
  goalWeekActivityCount: number;
  goalMonthActivityCount: number;
  goalTotalActivityCount: number;
  wipDayActivityCount: number;
  wipWeekActivityCount: number;
  wipMonthActivityCount: number;
  wipTotalActivityCount: number;
}

export interface Game extends Audit {
  name: string;
  icon: string;
  description: string;
  creator: {
    id: string;
    name: string;
  };
  genre: string;
  release: string;
  platform: string;
  mp: string;
  engine: string;
  socialOne: string;
  socialTwo: string;
  socialThree: string;
  socialFour: string;
  info: string;
  media: [string];
}

export interface Goal extends GameActivity, LastDurationActivityCounts {
  done: boolean;
  completedOn: firebase.firestore.FieldValue | "";
}

export interface AuditWithoutIdentity {
  createdOn?: firebase.firestore.FieldValue;
  modifiedOn?: firebase.firestore.FieldValue;
  createdBy?: string;
  modifiedBy?: string;
}

export interface Audit extends AuditWithoutIdentity {
  id: string;
}

export type UserBasicInfo = {
  authorUid: string;
  authorName?: string;
  authorProfilePic?: string;
  authorDesignation?: string;
};

export type GameBasicInfo = {
  gameId: string | null;
  gameName: string | null;
  gameProfilePic: string | null;
};

interface ActivityContent {
  text: string;
  isEdited: boolean;
  isMuted: boolean | undefined;
  likes: number;
  likesBy: string[];
  commentCount: number;
  commentsBy: Array<string>;
  lastCommentOn: null;
  yearMonthCode: number;
  gameId: string | null;
}
export interface LastDurationActivityCounts {
  lastSevenDaysLikes: number;
  lastThirtyDaysLikes: number;
  lastSevenDaysComments: number;
  lastThirtyDaysComments: number;
}
export interface GameActivity
  extends UserBasicInfo,
    GameBasicInfo,
    ActivityContent,
    Audit {}

export interface Activity extends ActivityContent, UserBasicInfo, Audit {}

export type InvitationStatusType = "requested" | "invited" | "signedUp";

interface InvitationRequest {
  email: string;
  createdOn: firebase.firestore.FieldValue | string;
  status: InvitationStatusType;
  code?: number;
  invitedOn?: firebase.firestore.FieldValue | string;
  invitedBy?: string;
}
export default InvitationRequest;

export type NavigateToType =
  | "GOAL"
  | "WIP"
  | "QUESTIONS"
  | "INVITES"
  | "USER"
  | "GAME"
  | "HOME";

export type NotificationType =
  | "New invite request"
  | "New user signup"
  | "New game added"
  | "Goal liked"
  | "Goal commented"
  | "Goal comment liked"
  | "WiP liked"
  | "WiP commented"
  | "WiP comment liked"
  | "Discussion liked"
  | "Discussion commented"
  | "Discussion comment liked"
  | "Collaborator added"
  | "Among top 5 streaks in week"
  | "No goals added 2nd day + n day";

export interface CommonNotificationProps {
  activityText?: string;
  parentActivityText?: string; //in case of comments activity this will be goal/wip/question
  notificationTriggeredBy: string; //user who liked or commented
  notificationTriggeredByProfilePic?: string; //user's DP who liked or commented
  notificationFor: string; //user for whom the notification is generated
  contentId?: string;
  createdOn: Date;
  isDeleted: boolean;
  navigateToId?: string;
  read?: boolean;
}
export interface Notification extends CommonNotificationProps {
  webTitle: string;
  webContent: string;
  emailTitle: string;
  emailContent: string;
  navigateTo: NavigateToType;
  notificationType: NotificationType;
}

export type NotificationWithId = Notification & { id: string };

export interface TotalStats {
  todayGoals: number;
  weekGoals: number;
  monthGoals: number;
  totalGoals: number;

  todayWiPs: number;
  weekWiPs: number;
  monthWiPs: number;
  totalWiPs: number;

  todayQuestions: number;
  weekQuestions: number;
  monthQuestions: number;
  totalQuestions: number;

  goalLastAdded: firebase.firestore.FieldValue;
  wipLastAdded: firebase.firestore.FieldValue;
  questionLastAdded: firebase.firestore.FieldValue;

  totalGames: number;
  totalUsers: number;
}

export interface Participants {
  commentActiveUsers: string[];
  commentMutedUsers: string[];
}

export interface Question extends Activity, LastDurationActivityCounts {
  tags: string[];
  answeredBy: Array<string>;
}

export type ProfileDataType = {
  profilePic?: string;
  shortText?: string;
  longText?: string;
  description?: string;
  subTextOne?: string;
  subTextTwo?: string;
  thumbs: string[];
};

export type SocialLinksType = {
  twitterURL?: string;
  facebookURL?: string;
  youTubeURL?: string;
  websiteURL?: string;
  githubURL?: string;
  linkedInURL?: string;
};

export type IconType =
  | "facebook"
  | "genre"
  | "github"
  | "goals"
  | "linkedin"
  | "questions"
  | "twitter"
  | "release"
  | "website"
  | "wips"
  | "youtube"
  | "games"
  | "platform";

type IconValueType = {
  icon: IconType;
  value: string | ReactNode;
  name?: string | ReactNode;
};
export type StatsType = {
  one: IconValueType;
  two: IconValueType;
  three: IconValueType;
  four: IconValueType;
  five?: IconValueType;
};

export interface UserProfile extends AuditWithoutIdentity {
  name?: string;
  username: string;
  email: string;
  profilePic?: string;
  studioName?: string;
  location?: string;
  designation?: string;
  twitterURL?: string;
  thumbs: string[];

  githubURL?: string;
  linkedInURL?: string;
  websiteURL?: string;
  tags: string[];
  goalDayActivityCount: number;
  goalWeekActivityCount: number;
  goalMonthActivityCount: number;
  goalTotalActivityCount: number;
  goalTotalCreatedCount: number;
  wipDayCreatedCount: number;
  wipWeekCreatedCount: number;
  wipMonthCreatedCount: number;
  wipTotalCreatedCount: number;
  questionDayAnsweredCount: number;
  questionWeekAnsweredCount: number;
  questionMonthAnsweredCount: number;
  questionTotalAnsweredCount: number;
  questionTotalCreatedCount: number;
  totalGamesCreated: number;

  currentStreak: number;
  weekStreak: number;
  currentStreakStartDate?: Date;
  weekStreakStartDate?: Date;

  //Highest streaks are not being handled properly for delete
  highestStreak: number;
  weekHighestStreak: number;
  highestStreakStartDate?: Date;
  weekHighestStreakStartDate?: Date;
  notificationCounts: number | firebase.firestore.FieldValue;

  isAdmin: boolean;
}

export interface WiP extends GameActivity, LastDurationActivityCounts {
  mediaUrl: string;
  mediaName: string | null;
  thumbs: string[];
}

export interface EntityExtendedType extends Activity {
  [key: string]: any;
}

export type EntityAvatarType = "user" | "checkbox" | "none";
export type EntityType = "goal" | "wip" | "question";
export type TabType = EntityType | "answered";
// export enum LastVisitedTimestamp {
//   goal = "lastVisitedGoalsAt",
//   wip = "lastVisitedWiPsAt",
//   question = "lastVisitedQuestionsAt"
// }
export type PageType = "game" | "user";

export type StorageFolder = "game-icon" | "user-dp" | "game-showcase" | "wips";

//Same exist in backend, should be one
export type profileDataType = { name: string; id: string; profilePic: string };

export type TimelineType = "Week" | "Month" | "Year" | "All"; //"Today" | "Yesterday" |
export type UserActivityListType = "Recent" | "Trending" | "Answered";

export type NameURL = { name: string; url: string };
export type ImageURLType = string | NameURL;

export enum Screen {
  GOAL = "GOAL",
  WIP = "WIP",
  QUESTIONS = "QUESTIONS",
  INVITES = "INVITES",
  USER = "USER",
  GAME = "GAME",
  HOME = "HOME",
}
export type NotificationSourceScreenType =
  | Screen.GOAL
  | Screen.WIP
  | Screen.QUESTIONS
  | Screen.INVITES
  | Screen.USER
  | Screen.GAME
  | Screen.HOME;
export type MarkNotificationReadRequestData = {
  token: string;
  screen: NotificationSourceScreenType;
  id?: string;
};

export type EntityItemType = {
  actions: ReactNode[];
  avatar?: ReactNode;
  content: ReactNode;
  author?: ReactNode;
  id: string;
};

export type DivType = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export type MediaType =
  | "instagram"
  | "twitter"
  | "facebook"
  | "reddit"
  | "discord";

export type Media = {
  type: MediaType;
  link: string;
  desktopOnly?: boolean;
};
