import { List as AntList, message } from "antd";
import firebase from "firebase/app";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import CommentAction from "./CommentAction";
import EntityAvatar from "./EntityAvatar";
import EntityContent from "./EntityContent";
import EntityDelete from "./EntityDelete";
import GameTag from "./GameTag";
import LikeAction from "./LikeAction";
import TimeDurationDisplay from "./TimeDurationDisplay";
import { EditContentProvider } from "../stores/edit-content-context";
import useFileUpload from "../hooks/useFileUpload";
import useWindowSize, { WindowSize } from "../hooks/useWindowSize";
import {
  AnalyticsEvent,
  WiP,
  EntityAvatarType,
  EntityExtendedType,
  EntityType,
  EntityItemType,
  Collection,
} from "../types";
import { ENTITY_PATHS, DEVICE_SIZES } from "../config";
import EntityItem from "./EntityItem";
import H2 from "./H2";
import { logEvents } from "../utils";
import EntityEdit from "./EntityEdit";
import styled from "styled-components/macro";
import { LoadingOutlined } from "@ant-design/icons";

const WrapperLink = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1;
`;

const Item = ({
  item,
  avatar,
}: {
  item: EntityItemType;
  avatar: EntityAvatarType;
}) => (
  <EntityItem
    noAvatar={avatar === "none"}
    actions={item.actions}
    avatar={item.avatar}
    content={item.content}
    author={item.author}
  />
);

function List<T extends EntityExtendedType>(props: {
  listData: T[];
  heading?: string | ReactNode;
  handleChange?: (e: any) => void;
  displayActions?: boolean;
  loading?: boolean;
  avatar?: EntityAvatarType;
  pathPrefix: string;
  dateField: keyof T;
  hideEditDelete?: boolean;
  displayComment?: boolean;
  displayAuthor?: boolean;
  displayTime?: boolean;
  fullWidthMainText?: boolean;
  entityType?: EntityType; //for comments it will be undefined as it is not a primary entity
  noGameDetails?: boolean;
  linkableMainText?: boolean;
  onDelete?: (id: string) => void;
  noDataText?: string;
}) {
  const {
    listData,
    heading,
    handleChange,
    displayActions = true,
    loading,
    avatar = "user",
    pathPrefix,
    dateField,
    hideEditDelete = false,
    displayComment = true,
    displayAuthor,
    displayTime = true,
    entityType,
    noGameDetails = false,
    linkableMainText = true,
    fullWidthMainText = false,
    onDelete,
    noDataText,
  } = props;

  const currentUser = firebase.auth().currentUser;

  const [, , removeFiles] = useFileUpload();

  const windowSize = useWindowSize();
  const isSmallScreen = windowSize <= WindowSize.md;
  async function handleDelete(id: string) {
    if (currentUser) {
      try {
        if (entityType) {
          //only for activities not for comments
          const comments = await firebase
            .firestore()
            .doc(`${pathPrefix}/${id}`)
            .collection(Collection.COMMENTS)
            .get();
          for (const comment of comments.docs) {
            comment.ref.delete();
          }
        }
        if (entityType === "wip") {
          const wipDoc = await firebase
            .firestore()
            .doc(`${pathPrefix}/${id}`)
            .get();
          if (wipDoc.exists) {
            const wip = wipDoc.data() as WiP;
            if (wip && wip.mediaName) {
              removeFiles({
                folder: "wips",
                userId: currentUser.uid,
                fileName: wip.mediaName,
                successCallback: () => {},
                removeErrorCallback: (e) => {
                  throw new Error(e);
                },
              });
            }
          }
        }
        await firebase.firestore().doc(`${pathPrefix}/${id}`).delete();
        logEvents(AnalyticsEvent.delete, entityType || "comments");
        onDelete && onDelete(id);
      } catch (e) {
        console.log(e);
        message.error(`Unable to delete the ${entityType || "comments"}`);
      }
    }
  }

  const dataSource: EntityItemType[] = [];

  listData.forEach((entity) => {
    let data: EntityItemType = {
      id: entity.id,
      actions: [],
      avatar: (
        <EntityAvatar
          type={avatar}
          entity={entity}
          handleChange={handleChange}
          linkTo={`/users/${entity.authorUid}`}
        />
      ),
      content: (
        <EntityContent
          entity={entity}
          entityType={entityType}
          pathPrefix={pathPrefix}
          noGameDetails={noGameDetails}
          displayDelete={!displayActions}
          handleDelete={handleDelete}
          fullWidthMainText={fullWidthMainText}
          linkableMainText={linkableMainText}
        />
      ),
    };

    if (displayActions) {
      const buttonPositions = isSmallScreen
        ? ["like", "comment", "tag", "edit", "delete"]
        : ["like", "comment", "tag", "edit", "delete"];

      const { authorUid, gameId } = entity;

      //same as isOwner
      const shouldDisplayEditDelete =
        !hideEditDelete && currentUser && currentUser.uid === authorUid;

      const shouldDisplayGameTag =
        (shouldDisplayEditDelete || gameId) && !noGameDetails;

      const gameTagElement = (
        <GameTag
          entity={entity}
          entityType={entityType}
          currentUser={currentUser}
          noGameDetails={noGameDetails}
          pathPrefix={pathPrefix}
        />
      );

      if (shouldDisplayEditDelete) {
        data.actions[buttonPositions.indexOf("edit")] = (
          <EntityEdit type="text" entity={entity} />
        );
        data.actions[buttonPositions.indexOf("delete")] = (
          <EntityDelete
            type="text"
            entity={entity}
            handleDelete={handleDelete}
            entityType={entityType || "comment"}
          />
        );
        data.actions[buttonPositions.indexOf("tag")] = gameTagElement;
      } else if (shouldDisplayGameTag) {
        data.actions[buttonPositions.indexOf("edit")] = null;
        data.actions[buttonPositions.indexOf("delete")] = null;
        data.actions[buttonPositions.indexOf("tag")] = gameTagElement;
      }

      data.actions[buttonPositions.indexOf("like")] = (
        <LikeAction
          entity={entity}
          docPath={`${pathPrefix}/${entity.id}`}
          entityType={entityType}
        />
      );

      if (displayComment) {
        data.actions[buttonPositions.indexOf("comment")] = (
          <CommentAction
            entity={{ ...((entity as unknown) as T) }}
            entityType={entityType}
          />
        );
      }

      /// handled weirdly to achieve all cases of grid design effective in mobile layout
      // if there is game tag & edit delete, grid should be
      //    like comment      tag
      //    edit delete         time

      // if there is game tag but NO edit delete, grid should be
      //    like comment      tag
      //    null null         time

      // if there is no game tag but edit delete, grid should be
      //    like comment      null
      //    edit delete       time

      // if there is neither game tag nor edit delete, grid should be
      //    like comment      time

      if ((entity[dateField] as unknown) as firebase.firestore.FieldValue) {
        if (displayTime) {
          data.actions.push(
            <TimeDurationDisplay entity={entity} dateField={dateField} />
          );
        } else {
          data.actions.push(<span></span>);
        }
      }
    }

    if (displayAuthor) {
      data.author = (
        <Link to={`/users/${entity.authorUid}`} className="author-name">
          {entity.authorName || entity.authorUid}
        </Link>
      );
    }
    dataSource.push(data);
  });

  return (
    <>
      {heading ? <H2>{heading}</H2> : null}
      {loading ? (
        <LoadingOutlined />
      ) : (
        <>
          {listData.length ? (
            <AntList
              css={`
                &&& {
                  .ant-list-items > li:last-child .ant-comment-inner {
                    @media (${DEVICE_SIZES.tablet}) {
                      padding-bottom: 0;
                    }
                  }
                }
              `}
              className="comment-list"
              itemLayout="horizontal"
              dataSource={dataSource}
              loading={loading}
              renderItem={(item, index) => (
                <li
                  style={
                    index === listData.length - 1
                      ? { borderBottom: "0px" }
                      : { borderBottom: "1px solid #e8e8e8" }
                  }
                >
                  <EditContentProvider>
                    {entityType && isSmallScreen && linkableMainText ? (
                      <div style={{ position: "relative" }}>
                        <WrapperLink
                          to={`/${ENTITY_PATHS[entityType]}/${item.id}`}
                        ></WrapperLink>
                        <Item item={item} avatar={avatar} />
                      </div>
                    ) : (
                      <Item item={item} avatar={avatar} />
                    )}
                  </EditContentProvider>
                </li>
              )}
            />
          ) : (
            <span>
              {noDataText
                ? noDataText
                : ` Nothing ${
                    heading ? `for ${heading.toString()}` : `to display`
                  }`}
            </span>
          )}
        </>
      )}
    </>
  );
}

export default List;
