import { useState, useRef } from "react";
import { Avatar, Modal, Popconfirm } from "antd";
import { RcFile } from "antd/lib/upload";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { validateImageFile } from "../utils";
import styled from "styled-components/macro";
import { DEVICE_SIZES } from "../config";
import useWindowSize, { WindowSize } from "../hooks/useWindowSize";
import { DeleteOutlined, EditOutlined, UserOutlined } from "@ant-design/icons";

interface FileUploadProps {
  imageUrl?: string;
  onFileSelectSuccess: (file: RcFile) => void;
  onFileSelectError: (error: string) => void;
  onFileDelete: () => void;
  error?: string;
}

const Image = styled.img`
  border-radius: 50%;
  width: 100px;
  height: 100px;

  @media (${DEVICE_SIZES.tablet}) {
    height: 65px;
    width: 65px;
    margin-right: 10px;
  }
`;

const ImageContainer = styled.div`
  margin: 6px;
  display: flex;
  flex-direction: column;

  @media (${DEVICE_SIZES.tablet}) {
    margin: 0 0 10px 0;
  }
`;
function FileUpload(props: FileUploadProps) {
  const {
    imageUrl,
    onFileSelectSuccess,
    onFileSelectError,
    error,
    onFileDelete,
  } = props;
  const [src, setSrc] = useState<string | ArrayBuffer | null>();
  const [crop, setCrop] = useState<ReactCrop.Crop>({
    unit: "%",
    width: 30,
    aspect: 1,
    x: 35,
    y: 35,
  });
  const [imageRef, setImageRef] = useState<HTMLImageElement>();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const windowSize = useWindowSize();

  function onSelect(e: React.ChangeEvent<HTMLInputElement>) {
    const files = e.target.files;
    if (files && files.length > 0) {
      validateImageFile(
        files[0] as RcFile,
        () => {
          const reader = new FileReader();
          reader.addEventListener("load", () => {
            if (files[0].type === "image/gif") {
              onFileSelectSuccess(files[0] as RcFile);
            } else {
              setSrc(reader.result);
            }
          });
          reader.readAsDataURL(files[0]);
        },
        (e) => {
          onFileSelectError(e);
        }
      );
    }
  }

  function onImageLoaded(img: HTMLImageElement) {
    setImageRef(img);
  }
  async function makeClientCrop(crop: ReactCrop.Crop) {
    if (imageRef && crop.width && crop.height) {
      const croppedImage = await getCroppedImg(imageRef, crop, "newFile.jpeg");
      //setCroppedImageUrl(croppedImage);
      onFileSelectSuccess(croppedImage as RcFile);
      setSrc(undefined);
    }
  }

  function getCroppedImg(image: HTMLImageElement, crop: any, fileName: string) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = crop.width || 0;
    canvas.height = crop.height || 0;
    const ctx = canvas.getContext("2d");

    const { name } = image as any;

    if (ctx) {
      if (crop !== undefined) {
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );

        return new Promise((resolve, reject) => {
          canvas.toBlob((blob) => {
            if (!blob) {
              //reject(new Error('Canvas is empty'));
              console.error("Canvas is empty");
              return;
            } else {
              const croppedFile = new File([blob], name, {
                type: blob.type,
                lastModified: Date.now(),
              });

              resolve(croppedFile);
            }
          }, "image/jpeg");
        });
      }
    }
  }
  return (
    <ImageContainer>
      {!!src && (
        <Modal
          visible={true}
          destroyOnClose
          onOk={() => makeClientCrop(crop)}
          onCancel={() => setSrc(undefined)}
        >
          <ReactCrop
            src={src as string}
            crop={crop}
            onImageLoaded={onImageLoaded}
            //onComplete={crop => set(crop)}
            onChange={(crop) => setCrop(crop)}
          />
        </Modal>
      )}
      <input
        type="file"
        id="upload-profile-pic"
        onChange={onSelect}
        style={{ display: "none" }}
        ref={fileInputRef}
      />
      <div style={{ position: "relative" }}>
        {!!imageUrl ? (
          <>
            <Image src={imageUrl} />
            <Popconfirm
              className="desktop-only"
              onConfirm={() => {
                if (fileInputRef.current) {
                  fileInputRef.current.value = "";
                }
                onFileDelete();
              }}
              title="Are you sure to delete the picture?"
              placement="right"
            >
              <label
                style={{ position: "absolute", cursor: "pointer", bottom: 0 }}
              >
                <DeleteOutlined />
              </label>
            </Popconfirm>
          </>
        ) : (
          <label htmlFor="upload-profile-pic" style={{ cursor: "pointer" }}>
            <Avatar
              size={windowSize <= WindowSize.md ? 65 : 100}
              icon={<UserOutlined />}
            />
          </label>
        )}
        <label
          className="desktop-only"
          htmlFor="upload-profile-pic"
          style={{ position: "absolute", cursor: "pointer" }}
        >
          <EditOutlined />
        </label>
      </div>

      {error && <span style={{ color: "red" }}>{error}</span>}
    </ImageContainer>
  );
}
export default FileUpload;
