import firebase from "firebase/app";
import { useEffect, Fragment, lazy } from "react";
import ReactPixel from "react-facebook-pixel";
import TwitterTracking from "react-twitter-conversion-tracker-plus";
import { useAuthState } from "react-firebase-hooks/auth";
import ReactGA from "react-ga";
import {
  matchPath,
  Redirect,
  Route,
  RouteProps,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import useWindowSize, { WindowSize } from "../hooks/useWindowSize";
import { useUserProfileState } from "../stores/UserProfileProvider";
import MetaTags from "./MetaTags";
import NotFound from "./NotFound";

import ResendVerificationEmail from "../pages/resend-verification-email";
import CreateGame from "../pages/create-game";
import WipAttachment from "../pages/wip-attachment";
import Goal from "../pages/goal";
import Wip from "../pages/wip";
import Discussion from "../pages/discussion";
import SignIn from "../pages/sign-in";
import VerifyCode from "../pages/verify-code";
import ForgotPassword from "../pages/forgot-password";
import RequestAccess from "../pages/request-access";
import SignUp from "../pages/sign-up";
import { isTrackingAllowed } from "../utils";

type CustomRouteType = RouteProps & {
  scope: Array<"ADMIN" | "MODAL" | "PUBLIC_ONLY" | "PROTECTED">;
  defaultBackdrop?: string;
};

function CustomRoute(props: CustomRouteType) {
  return (
    <Fragment key={props.path as string}>
      {props.scope.includes("ADMIN") ? (
        <AdminRoute {...props} />
      ) : props.scope.includes("PROTECTED") ? (
        <ProtectedRoute {...props} />
      ) : props.scope.includes("PUBLIC_ONLY") ? (
        <PublicOnlyRoute {...props} />
      ) : (
        <Route {...props} />
      )}
    </Fragment>
  );
}

const ROUTES: Array<CustomRouteType> = [
  {
    path: "/",
    scope: [],
    component: lazy(() => import("../pages/home")),
    exact: true,
  },
  {
    path: "/notifications",
    scope: ["PROTECTED"],
    component: lazy(() => import("../pages/notifications")),
  },
  {
    path: "/terms-of-use",
    scope: [],
    component: lazy(() => import("../pages/terms-of-use")),
  },
  {
    path: "/community-guidelines",
    scope: [],
    component: lazy(() => import("../pages/community-guidelines")),
  },
  {
    path: "/privacy-policy",
    scope: [],
    component: lazy(() => import("../pages/privacy-policy")),
  },
  {
    path: "/learn-more",
    scope: [],
    component: lazy(() => import("../pages/learn-more")),
  },
  {
    path: "/reset-password/:code",
    scope: [],
    component: lazy(() => import("../pages/reset-password")),
  },
  {
    path: "/resend-verification-email/:email",
    scope: ["MODAL"],
    component: ResendVerificationEmail,
    defaultBackdrop: "/",
  },
  {
    path: "/verify-email",
    scope: [],
    component: lazy(() => import("../pages/verify-email")),
  },
  {
    path: "/goals",
    scope: [],
    component: lazy(() => import("../pages/goals")),
    exact: true,
  },
  {
    path: "/wips",
    scope: [],
    component: lazy(() => import("../pages/wips")),
    exact: true,
  },
  {
    path: "/discussions",
    scope: [],
    component: lazy(() => import("../pages/discussions")),
    exact: true,
  },
  {
    path: "/games/:shortCode",
    scope: [],
    component: lazy(() => import("../pages/game")),
  },
  {
    path: "/users/:uid/games",
    scope: [],
    component: lazy(() => import("../pages/games")),
  },
  {
    path: "/games",
    scope: [],
    component: lazy(() => import("../pages/games")),
    exact: true,
  },
  {
    path: "/users/:uid",
    scope: [],
    component: lazy(() => import("../pages/user")),
  },
  {
    path: "/manage-requests",
    scope: ["ADMIN"],
    component: lazy(() => import("../pages/manage-requests")),
  },
  {
    path: "/manage-invites",
    scope: ["ADMIN"],
    component: lazy(() => import("../pages/manage-invites")),
  },
  {
    path: "/create-game",
    scope: ["MODAL", "PROTECTED"],
    component: CreateGame,
    defaultBackdrop: "/",
  },
  {
    path: "/wips/:id/attachment",
    scope: ["MODAL"],
    component: WipAttachment,
    defaultBackdrop: "/wips",
  },
  {
    path: "/goals/:id",
    scope: ["MODAL"],
    component: Goal,
    defaultBackdrop: "/goals",
  },
  {
    path: "/wips/:id",
    scope: ["MODAL"],
    component: Wip,
    defaultBackdrop: "/wips",
  },
  {
    path: "/discussions/:id",
    scope: ["MODAL"],
    component: Discussion,
    defaultBackdrop: "/discussions",
  },
  {
    path: "/sign-in",
    scope: ["PUBLIC_ONLY", "MODAL"],
    component: SignIn,
    defaultBackdrop: "/",
  },
  {
    path: "/verify-code",
    scope: ["PUBLIC_ONLY", "MODAL"],
    component: VerifyCode,
    defaultBackdrop: "/",
  },
  {
    path: "/forgot-password",
    scope: ["PUBLIC_ONLY", "MODAL"],
    component: ForgotPassword,
    defaultBackdrop: "/",
  },
  {
    path: "/request-access",
    scope: ["PUBLIC_ONLY", "MODAL"],
    component: RequestAccess,
    defaultBackdrop: "/",
  },
  {
    path: "/sign-up",
    scope: ["PUBLIC_ONLY", "MODAL"],
    component: SignUp,
    defaultBackdrop: "/",
  },
];

const PUBLIC_ONLY_PATHS = ROUTES.filter((route) =>
  route.scope.includes("PUBLIC_ONLY")
).map((route) => route.path);

function PublicOnlyRoute(props: RouteProps) {
  const [user] = useAuthState(firebase.auth());
  const location = useLocation();

  if (PUBLIC_ONLY_PATHS.includes(location.pathname) && user) {
    return <Redirect to="/" />;
  }

  return <Route {...props} />;
}

function AdminRoute(props: RouteProps) {
  const userProfile = useUserProfileState();
  const isAdmin: boolean = Boolean(userProfile && userProfile.isAdmin);

  if (isAdmin) {
    return <Route {...props} />;
  }

  return <Route component={NotFound} />;
}

function ProtectedRoute(props: RouteProps) {
  const [user, loading] = useAuthState(firebase.auth());

  if (loading) {
    return <></>;
  }

  if (!user) {
    return <Redirect to="/sign-in" />;
  }

  return <Route {...props} />;
}

const MODAL_ROUTES = ROUTES.filter((route) => route.scope.includes("MODAL"));
const BACKDROP_ROUTES = ROUTES.filter(
  (route) => !route.scope.includes("MODAL")
);

function DesktopRoutes() {
  const location = useLocation<any>();
  let backdropLocation = location.state && location.state.backdropLocation;
  let modalLocation = location;

  if (!backdropLocation) {
    MODAL_ROUTES.forEach((route) => {
      const match = matchPath(location.pathname, { path: route.path });

      if (match) {
        backdropLocation = { pathname: route.defaultBackdrop };
        modalLocation.state = {
          ...modalLocation.state,
          backdropLocation,
        };
      }
    });
  }

  return (
    <Fragment>
      {/* Backdrop Switch */}
      <Switch location={backdropLocation || location}>
        {BACKDROP_ROUTES.map((route) => (
          <CustomRoute key={route.path as string} {...route} />
        ))}
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>

      {/* Modal Switch */}
      {modalLocation.state && modalLocation.state.backdropLocation && (
        <Switch location={modalLocation}>
          {MODAL_ROUTES.map((route) => (
            <CustomRoute key={route.path as string} {...route} />
          ))}
        </Switch>
      )}
    </Fragment>
  );
}

function MobileRoutes() {
  return (
    <Switch>
      {ROUTES.map((route) => (
        <CustomRoute key={route.path as string} {...route} />
      ))}
    </Switch>
  );
}

async function recordPageView(location: any) {
  if (isTrackingAllowed()) {
    ReactGA.pageview(location.pathname);
    ReactPixel.pageView();
    TwitterTracking.pageView();
  }

  const gameMatch = matchPath<{ shortCode: string }>(location.pathname, {
    path: "/games/:shortCode",
  });

  if (gameMatch) {
    const increaseGameProfileView = firebase
      .functions()
      .httpsCallable("increaseGameProfileView");
    increaseGameProfileView({ gameShortCode: gameMatch.params.shortCode });
  }
}

function Routes() {
  const windowSize = useWindowSize();
  const isSmallScreen = windowSize <= WindowSize.md;
  const history = useHistory();

  useEffect(() => {
    recordPageView(history.location);
    return history.listen(recordPageView);
  }, [history]);

  return (
    <Fragment>
      <MetaTags
        title="WeMake.Games | Productivity Tool for Indie Game Devs"
        description="A community for indie game developers to help them stay accountable and motivated. Join over 1000 gamedevs, share your daily goals, showcase your work, participate in discussion and a lot more. Join today for free!"
        link={process.env.REACT_APP_FRONTEND_URL}
        keywords="gamedev community,indie game dev community,game development community,indie games community,game dev tool,game development,make a game,gamedev forums,gamedev software,indie game developers community"
      />
      {isSmallScreen ? <MobileRoutes /> : <DesktopRoutes />}
    </Fragment>
  );
}

export default Routes;
