import { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { EntityType, EntityExtendedType } from "../types";
import EntityDetails from "./EntityDetails";
import Modal from "./Modal";

type EntityDetailsModalProps = RouteComponentProps<
  {
    id: string;
  },
  {},
  any
> & {
  entityType: EntityType;
  entity: EntityExtendedType;
};

function EntityDetailsModal({
  entity,
  entityType,
  history,
  location,
}: EntityDetailsModalProps) {
  const [visible, setVisible] = useState(true);
  const backdropLocation = location.state?.backdropLocation;

  return (
    <Modal
      afterClose={() => {
        if (backdropLocation) {
          history.push(backdropLocation.pathname);
        } else {
          history.push("/");
        }
      }}
      visible={visible}
      onCancel={() => setVisible(false)}
      avatarUrl={entity ? entity.authorProfilePic : undefined}
      avatarAlt={entity ? entity.authorName || entity.authorUid : undefined}
      uid={entity ? entity.authorUid : undefined}
      centeredBody={false}
      width="38%"
    >
      {entity && <EntityDetails entityType={entityType} entity={entity} />}
    </Modal>
  );
}

export default EntityDetailsModal;
