import styled from "styled-components/macro";
import { DEVICE_SIZES } from "../config";

const WiPMediaWrapper = styled.div`
  width: 285px;
  height: 171px;

  @media (${DEVICE_SIZES.tablet}) {
    width: 100%;
  }
`;

export default WiPMediaWrapper;
