import CreateEditGameProfileForm from "./CreateEditGameProfileForm";
import Modal from "./Modal";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useState } from "react";

type ParamsType = {
  shortCode: string;
};

function CreateEditGame({ type }: { type: "create" | "edit" }) {
  const [visible, setVisible] = useState(true);
  const history = useHistory();
  const params = useParams<ParamsType>();
  const location = useLocation<any>();
  const backdropLocation = location.state?.backdropLocation;

  return (
    <Modal
      afterClose={() => {
        if (params.shortCode) {
          history.push(`/games/${params.shortCode}`);
        } else {
          history.push(backdropLocation?.pathname || "/");
        }
      }}
      visible={visible}
      onCancel={() => setVisible(false)}
      titleAvatar={false}
    >
      <CreateEditGameProfileForm type={type} />
    </Modal>
  );
}

export default CreateEditGame;
