import { Dropdown, Menu } from "antd";
import firebase from "firebase/app";
import ReactPixel from "react-facebook-pixel";
import TwitterTracking from "react-twitter-conversion-tracker-plus";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import { EntityType, AnalyticsEvent } from "../types";
import styled from "styled-components/macro";
import { getThumbnailStorageUrl, isTrackingAllowed, logEvents } from "../utils";
import Avatar from "./Avatar";
import { useUserProfileState } from "../stores/UserProfileProvider";
import ButtonLink from "./ButtonLink";
import { UserOutlined } from "@ant-design/icons";

const ProfileIconContainer = styled.div`
  text-align: right;
  &&& i {
    color: ${(props) => props.theme.colors.mainHeaderText};
    font-size: ${(props) => props.theme.fontSizes.profileMenu};
  }
  cursor: pointer;
`;

export default function ProfileMenu() {
  const userProfile = useUserProfileState();

  const menu = userProfile ? (
    <Menu style={{ textAlign: "center", minWidth: "150px" }}>
      <Menu.Item key="1">
        <Link to={`/users/${userProfile.username}`}>
          {(firebase.auth().currentUser as any).uid}
        </Link>
      </Menu.Item>

      <Menu.Item key="3">
        <Link to={`/users/${userProfile.username}/games`}>My Games</Link>
      </Menu.Item>

      {userProfile && userProfile.isAdmin && (
        <Menu.Item key="manage-requests">
          <Link to="/manage-requests">Manage Requests</Link>
        </Menu.Item>
      )}

      {userProfile && userProfile.isAdmin && (
        <Menu.Item key="manage-invites">
          <Link to="/manage-invites">Manage Invites</Link>
        </Menu.Item>
      )}

      <Menu.Item
        key="6"
        onClick={async () => {
          const entities: EntityType[] = ["goal", "wip", "question"];

          entities.forEach((entity) => {
            localStorage.removeItem(entity);
          });

          logEvents(AnalyticsEvent.logout);

          if (isTrackingAllowed()) {
            ReactPixel.trackCustom("user", null);
            TwitterTracking.track("user", null);
            ReactGA.set({ userId: null });
          }

          await firebase.auth().signOut();
          window.location.href = "/";
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  ) : null;
  return (
    <>
      <Dropdown
        overlay={menu as React.ReactElement}
        placement="bottomRight"
        trigger={["click"]}
      >
        <ProfileIconContainer>
          {userProfile ? (
            userProfile.thumbs.find((thumb) => thumb.includes("32x32")) ? (
              <Avatar
                size={32}
                src={getThumbnailStorageUrl(userProfile.profilePic, 32)}
              />
            ) : (
              <Avatar
                size={32}
                alt={userProfile.name || userProfile.username}
              />
            )
          ) : (
            <ButtonLink>
              <UserOutlined />
            </ButtonLink>
          )}
        </ProfileIconContainer>
      </Dropdown>
    </>
  );
}
