import firebase from "firebase/app";
import {
  AnalyticsEvent,
  Collection,
  EntityType,
  GameProfile,
  UserProfile,
  StatsType,
  Comment,
  ImageURLType,
  NameURL,
  GameBasicInfo,
  Goal,
  Question,
  WiP,
} from "./types";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import TwitterTracking from "react-twitter-conversion-tracker-plus";
import {
  BASE_URL,
  FIREBASE_DEV,
  FIREBASE_PROD,
  ALGOLIA_PROD,
  ALGOLIA_DEV,
  SUPPORTED_VIDEO_EXTENSIONS,
  CONSTANTS,
  DESIGNATIONS,
  GENRES,
  PLATFORMS,
} from "./config";
import { format, formatDistanceToNow } from "date-fns";
import { RcFile } from "antd/lib/upload";
import { getCookieConsentValue } from "react-cookie-consent";

export function getActionCategory(action: AnalyticsEvent) {
  switch (action) {
    case "user_invites":
    case "user_signups":
    case "user_onboarded":
    case "user_requests":
      return "User Onboarding";

    case "goals_completed":
    case "goals":
    case "goal_hearts":
      return "Goal Actions";

    case "wip_hearts":
      return "WiP Actions";

    case "questions":
    case "question_hearts":
      return "Question Actions";

    case "user_logins":
      return "User Login";

    case "unlike":
    case "like":
      return "Likes";
    case "delete":
      return "Deletes";

    case "comments":
      return "Comments";

    case "logout":
      return "Logout";

    default:
      return undefined;
  }
}

export function logEvents(
  event: AnalyticsEvent,
  data?:
    | {
        [key: string]: string;
      }
    | string
) {
  const category = getActionCategory(event);

  if (isTrackingAllowed()) {
    const currentUser = firebase.auth().currentUser as firebase.User;
    let user = {};

    if (currentUser) {
      user = {
        userId: currentUser.uid,
        email: currentUser.email,
      };
    }

    ReactPixel.trackCustom(event, JSON.stringify({ data, user }));
    TwitterTracking.track(event, JSON.stringify({ data, user }));

    ReactGA.event({
      category: category || "Uncategorized",
      action: event,
      label:
        typeof data === "string" || typeof data === "undefined"
          ? data
          : JSON.stringify(data),
    });
  }
}

export function getConfig() {
  if (process.env.REACT_APP_BRANCH === "master") {
    return {
      firebase: FIREBASE_PROD,
      algolia: ALGOLIA_PROD,
    };
  } else {
    return {
      firebase: FIREBASE_DEV,
      algolia: ALGOLIA_DEV,
    };
  }
}

export function generateStorageUrl(imagePath: string) {
  if (imagePath) {
    return `${BASE_URL}${encodeURIComponent(imagePath)}?alt=media`;
  }
}

export function parseVideo(url: string) {
  // - Supported YouTube URL formats:
  //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
  //   - http://youtu.be/My2FRPA3Gf8
  //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
  // - Supported Vimeo URL formats:
  //   - http://vimeo.com/25451551
  //   - http://player.vimeo.com/video/25451551
  // - Also supports relative URLs:
  //   - //player.vimeo.com/video/25451551

  const res = url.match(
    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\S+)?/
  );

  let type: "youtube" | "vimeo" | "direct-link" | undefined = undefined;
  if (res) {
    if (res[3].indexOf("youtu") > -1) {
      type = "youtube";
    } else if (res[3].indexOf("vimeo") > -1) {
      type = "vimeo";
    }
  } else if (
    SUPPORTED_VIDEO_EXTENSIONS.find((extension) =>
      url.toLowerCase().endsWith(extension)
    )
  ) {
    type = "direct-link";
  }

  return {
    type: type,
    id: res && res[6],
  };
}

export async function getVideoThumbnail(url: string) {
  // Obtains the video's thumbnail and passed it back to a callback function.
  var videoObj = parseVideo(url);
  if (videoObj.type === "youtube") {
    return "//img.youtube.com/vi/" + videoObj.id + "/maxresdefault.jpg";
  } else if (videoObj.type === "vimeo") {
    try {
      let data: any = fetch(
        "http://vimeo.com/api/v2/video/" + videoObj.id + ".json"
      );
      return data.length && data[0] && (data[0].thumbnail_large as string);
    } catch (e) {
      console.log(e);
      throw new Error(e);
    }
  } else {
    return undefined;
  }
}

export function getEntityCollection(entityType: EntityType) {
  switch (entityType) {
    case "goal":
      return Collection.GOALS;
    case "wip":
      return Collection.WIPS;
    case "question":
      return Collection.QUESTIONS;
  }
}

export function getEntityDocPath(entityType: EntityType, docId: string) {
  switch (entityType) {
    case "wip":
      return `${Collection.WIPS}/${docId}`;
    case "question":
      return `${Collection.QUESTIONS}/${docId}`;

    default:
      return `${Collection.GOALS}/${docId}`;
  }
}

export function getEntityObjFromAny<T>(
  entityType: EntityType,
  doc: firebase.firestore.DocumentSnapshot
) {
  switch (entityType) {
    case "goal":
      return {
        ...((getGoalObjFromAny(doc.id, doc.data()) as unknown) as T),
      };
    case "wip":
      return {
        ...((getWiPObjFromAny(doc.data()) as unknown) as T),
      };
    case "question":
      return {
        ...((getQuestionObjFromAny(doc.data()) as unknown) as T),
      };
  }
}

export function getFirstLineOfText(text: string) {
  return text.split("\n")[0];
}

export function getGameProfileData(gameProfile: GameProfile) {
  return {
    longText: gameProfile.name,
    description: gameProfile.oneLinePitch,
    subTextOne: gameProfile.studioName,
    subTextTwo: gameProfile.location,
    profilePic: gameProfile.profilePic,
    thumbs: gameProfile.thumbs,
  };
}

export function isTrackingAllowed() {
  if (process.env.REACT_APP_BRANCH === "master" && getCookieConsentValue()) {
    return true;
  }

  return false;
}

export function getGameStats(gameProfile: GameProfile): StatsType {
  return {
    one: {
      icon: "goals",
      name: "Goals",
      value: `${gameProfile.goalTotalActivityCount}`,
    },
    two: {
      icon: "wips",
      name: "WiPs",
      value: `${gameProfile.wipTotalActivityCount}`,
    },

    three: {
      icon: "release",
      name: "Release",
      value:
        gameProfile.releaseDate && (gameProfile.releaseDate as any).toMillis
          ? format(
              new Date((gameProfile.releaseDate as any).toMillis()),
              CONSTANTS.DateFormat
            )
          : `TBD`,
    },
    four: {
      icon: "genre",
      name: "Genres",
      value: (
        <ul
          style={{
            listStyleType: "none",
            textAlign: "center",
            paddingInlineStart: "0px",
            marginBottom: "0px",
          }}
        >
          {gameProfile.genre
            ? gameProfile.genre
                .map((genre) => {
                  const found = GENRES.find((g) => g.value === genre);
                  if (found) {
                    return found.name;
                  } else {
                    return "";
                  }
                })
                .filter((genre) => !!genre)
                .map((genre, index, list) => (
                  <li key={genre} className="truncate">
                    {genre}
                  </li>
                ))
            : null}
        </ul>
      ),
    },
    five: {
      icon: "platform",
      name: (
        <ul
          style={{
            listStyleType: "none",
            textAlign: "center",
            paddingInlineStart: "0px",
            marginBottom: "0px",
          }}
        >
          {gameProfile.platform
            ? gameProfile.platform
                .map((platform) => {
                  const found = PLATFORMS.find((p) => p.value === platform);
                  if (found) {
                    return found.name;
                  } else {
                    return "";
                  }
                })
                .filter((platform, index) => !!platform)
                .map((platform, index, list) => (
                  <li key={platform} className="truncate">
                    {platform}
                  </li>
                ))
            : null}
        </ul>
      ),
      value: (
        <ul
          style={{
            listStyleType: "none",
            textAlign: "center",
            paddingInlineStart: "0px",
            marginBottom: "0px",
          }}
        >
          {gameProfile.platform
            ? gameProfile.platform
                .map((platform) => {
                  const found = PLATFORMS.find((p) => p.value === platform);
                  if (found) {
                    return found.name;
                  } else {
                    return "";
                  }
                })
                .filter((platform, index) => !!platform && index < 2)
                .map((platform, index, list) => (
                  <li key={platform} className="truncate">
                    {platform}{" "}
                    {gameProfile.platform.length > 2 && index === 1 && `...`}
                  </li>
                ))
            : null}
        </ul>
      ),
    },
  };
}

export function getGameSocialLinks(gameProfile: GameProfile) {
  return {
    twitterURL: gameProfile.twitterURL,
    facebookURL: gameProfile.facebookURL,
    youTubeURL: gameProfile.youTubeURL,
    websiteURL: gameProfile.websiteURL,
  };
}

export function getReleaseDate(date: any) {
  return date && date.toMillis
    ? format(new Date(date.toMillis()), CONSTANTS.DateFormat)
    : `TBD`;
}

export function getUserProfileData(userProfile: UserProfile) {
  return {
    shortText: `@${userProfile.username}`,
    longText: userProfile.name,
    description: userProfile.designation,
    subTextOne: userProfile.studioName,
    subTextTwo: userProfile.location,
    profilePic: userProfile.profilePic,
    thumbs: userProfile.thumbs,
  };
}

export function getUserStats(userProfile: UserProfile): StatsType {
  return {
    one: {
      icon: "goals",
      name: "Goals",
      value: `${userProfile.goalTotalActivityCount}`,
    },

    two: {
      icon: "games",
      name: "Games Added",
      value: `${userProfile.totalGamesCreated}`,
    },
    three: {
      icon: "wips",
      name: "WiPs Shared",
      value: `${userProfile.wipTotalCreatedCount}`,
    },
    four: {
      icon: "questions",
      name: "Discussions Answered",
      value: `${userProfile.questionTotalAnsweredCount}`,
    },
  };
}

export function getUserSocialLinks(userProfile: UserProfile) {
  return {
    twitterURL: userProfile.twitterURL,
    githubURL: userProfile.githubURL,
    linkedInURL: userProfile.linkedInURL,
    websiteURL: userProfile.websiteURL,
  };
}

export function getThumbnailStorageUrl(
  storageUrl: string | undefined,
  size = 32 | 100 | 250
) {
  if (!storageUrl) {
    return "";
  }

  const decodedUrl = new URL(decodeURI(storageUrl));

  if (
    decodedUrl.host === "firebasestorage.googleapis.com" &&
    decodedUrl.search.includes("token=")
  ) {
    return `${decodedUrl.origin}${decodedUrl.pathname}_${size}x${size}?alt=media`;
  }

  return storageUrl;
}

export function getTimeDistance(datetime: firebase.firestore.FieldValue) {
  return (datetime as any).toMillis
    ? formatDistanceToNow(new Date((datetime as any).toMillis()), {
        includeSeconds: false,
        addSuffix: true,
      })
    : "";
}

export async function signIn(email: string, password: string) {
  const credentials = await firebase
    .auth()
    .signInWithEmailAndPassword(email, password);

  logEvents(AnalyticsEvent.user_logins);

  return credentials;
}

// When you update this, make sure to update storage.rules as well
const LIMIT = 2;

export function validateImageFile(
  file: RcFile,
  successCallback: () => void,
  errorCallback: (error: string) => void
): void {
  const isWithinLimit = file.size / 1024 / 1024 < LIMIT;

  if (!isWithinLimit) {
    return errorCallback(`Image size must be less than ${LIMIT}MB!`);
  }
  const isJPG = file.type === "image/jpeg";
  const isPNG = file.type === "image/png";
  const isGIF = file.type === "image/gif";
  if (!isJPG && !isPNG && !isGIF) {
    return errorCallback("You can only upload JPG, PNG or GIF file!");
  }

  var reader = new FileReader();

  //Read the contents of Image File.
  reader.readAsDataURL(file);
  reader.onload = function (e) {
    //Initiate the JavaScript Image object.
    var image = new Image();

    //Set the Base64 string return from FileReader as source.
    image.src = (e as any).target.result;

    image.onerror = function (e) {
      return errorCallback("The file is not a valid image.");
    };

    if ((isJPG || isPNG || isGIF) && isWithinLimit) {
      return successCallback();
    } else {
      return errorCallback("Invalid file");
    }
  };
}

export function validateImageLink(
  link: string,
  onValidation: (isValid: boolean) => void
) {
  if (link) {
    let image = new Image();
    image.src = link;
    image.onload = function () {
      onValidation(true);
    };
    image.onerror = function (e) {
      onValidation(false);
    };
  } else {
    onValidation(false);
  }
}

export function createComment(
  text: string,
  id: string,
  user: UserProfile
): Comment {
  if (!user.username) {
    throw new Error(`user.username can't be falsy`);
  }
  return {
    id: id,
    createdOn: firebase.firestore.FieldValue.serverTimestamp(),
    modifiedOn: firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: user.username,
    modifiedBy: user.username,
    text: text,
    isEdited: false,
    isMuted: false,
    likes: 0,
    likesBy: [],
    commentCount: 0,
    authorUid: user.username,
    authorName: user.name || "",
    authorProfilePic: user.profilePic || "",
    yearMonthCode: 0,
    commentsBy: [],
    lastCommentOn: null,
    mentions: [],
    gameId: null,
  };
}
export function getCommentObjFromAny(comment: any): Comment {
  return {
    id: comment.id || "",
    createdOn:
      comment.createdOn || firebase.firestore.FieldValue.serverTimestamp(),
    modifiedOn:
      comment.modifiedOn || firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: comment.createdBy || "",
    modifiedBy: comment.modifiedBy || "",
    text: comment.text || "",
    isEdited: comment.isEdited || false,
    isMuted: comment.isMuted,
    likes: comment.likes || 0,
    likesBy: comment.likesBy || [],
    commentCount: comment.commentCount || 0,
    authorUid: comment.authorUid || "",
    authorName: comment.authorName || "",
    authorProfilePic: comment.authorProfilePic || "",
    yearMonthCode: comment.yearMonthCode,
    commentsBy: comment.commentsBy || [],
    lastCommentOn:
      comment.lastCommentOn || firebase.firestore.FieldValue.serverTimestamp(),
    mentions: comment.mentions || [],
    gameId: comment.gameId || null,
  };
}

export function getCommentDocPath(goalId: string, commentId: string) {
  return `${Collection.GOALS}/${goalId}/${Collection.COMMENTS}/${commentId}`;
}

export function getDownloadableUrl(media: ImageURLType) {
  return typeof media === "string" ? (media as string) : (media as NameURL).url;
}

export function createGameProfile(
  game: Partial<GameProfile>,
  uid: string
): GameProfile {
  if (!game.shortCode) {
    throw new Error(`username can't be falsy`);
  }

  return {
    name: game.name || "",
    shortCode: game.shortCode.toLowerCase() || "",
    profilePic: game.profilePic || "",
    studioName: game.studioName || "",
    location: game.location || "",
    country: game.country || "",
    oneLinePitch: game.oneLinePitch || "",
    longDescription: game.longDescription || "",
    genre: game.genre || [],
    releaseDate: game.releaseDate || null, //null will symbolize TBD
    platform: game.platform || [],
    collaborators: game.collaborators || [],
    stakeholders: game.stakeholders || [],
    websiteURL: game.websiteURL || "",
    facebookURL: game.facebookURL || "",
    youTubeURL: game.youTubeURL || "",
    twitterURL: game.twitterURL || "",
    thumbs: [],

    createdOn: firebase.firestore.FieldValue.serverTimestamp(),
    modifiedOn: firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: uid,
    modifiedBy: uid,
    tags: [],
    videoUrl: game.videoUrl || "",
    thumbnailUrl: game.thumbnailUrl || "",

    imageUrls: game.imageUrls || [],
    profileViewCount: game.profileViewCount || 0,
    dayActivityCount: game.dayActivityCount || 0,
    weekActivityCount: game.weekActivityCount || 0,
    monthActivityCount: game.monthActivityCount || 0,
    yearActivityCount: game.yearActivityCount || 0,
    lastSevenDaysActivityCount: game.yearActivityCount || 0,
    lastThirtyDaysActivityCount: game.yearActivityCount || 0,
    goalDayActivityCount: game.goalDayActivityCount || 0,
    goalWeekActivityCount: game.goalWeekActivityCount || 0,
    goalMonthActivityCount: game.goalMonthActivityCount || 0,
    goalTotalActivityCount: game.goalTotalActivityCount || 0,
    wipDayActivityCount: game.wipDayActivityCount || 0,
    wipWeekActivityCount: game.wipWeekActivityCount || 0,
    wipMonthActivityCount: game.wipMonthActivityCount || 0,
    wipTotalActivityCount: game.wipTotalActivityCount || 0,
  };
}

export function getGameObjFromAny(game: any): GameProfile {
  return {
    name: game.name || "",
    shortCode: game.shortCode || "",
    profilePic: game.profilePic || "",
    studioName: game.studioName || "",
    location: game.location || "",
    country: game.country || "",
    oneLinePitch: game.oneLinePitch || "",
    longDescription: game.longDescription || "",
    genre: game.genre || [],
    releaseDate: game.releaseDate || "",
    platform: game.platform || [],
    collaborators: game.collaborators || [],
    stakeholders: game.stakeholders || [],
    websiteURL: game.websiteURL || "",
    facebookURL: game.facebookURL || "",
    youTubeURL: game.youTubeURL || "",
    twitterURL: game.twitterURL || "",
    thumbs: game.thumbs || [],

    createdOn: game.createdOn,
    modifiedOn: game.modifiedOn,
    createdBy: game.createdBy,
    modifiedBy: game.modifiedBy,
    tags: game.tags || [],
    videoUrl: game.videoUrl || "",
    thumbnailUrl: game.thumbnailUrl || "",
    imageUrls: game.imageUrls || [],
    profileViewCount: game.profileViewCount || 0,
    dayActivityCount: game.dayActivityCount || 0,
    weekActivityCount: game.weekActivityCount || 0,
    monthActivityCount: game.monthActivityCount || 0,
    yearActivityCount: game.yearActivityCount || 0,
    lastSevenDaysActivityCount: game.yearActivityCount || 0,
    lastThirtyDaysActivityCount: game.yearActivityCount || 0,
    goalDayActivityCount: game.goalDayActivityCount || 0,
    goalWeekActivityCount: game.goalWeekActivityCount || 0,
    goalMonthActivityCount: game.goalMonthActivityCount || 0,
    goalTotalActivityCount: game.goalTotalActivityCount || 0,
    wipDayActivityCount: game.wipDayActivityCount || 0,
    wipWeekActivityCount: game.wipWeekActivityCount || 0,
    wipMonthActivityCount: game.wipMonthActivityCount || 0,
    wipTotalActivityCount: game.wipTotalActivityCount || 0,
  };
}

export function getGameDocPath(shortCode: string) {
  return `${Collection.GAMEPROFILES}/${shortCode}`;
}

export function createGoal(
  text: string,
  id: string,
  user: UserProfile,
  game?: GameBasicInfo
): Goal {
  if (!user.username) {
    throw new Error(`username can't be falsy`);
  }
  return {
    id: id,
    createdOn: firebase.firestore.FieldValue.serverTimestamp(),
    modifiedOn: firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: user.username,
    modifiedBy: user.username,
    text: text,
    isEdited: false,
    isMuted: false,
    likes: 0,
    likesBy: [],
    commentCount: 0,
    lastSevenDaysLikes: 0,
    lastThirtyDaysLikes: 0,
    lastSevenDaysComments: 0,
    lastThirtyDaysComments: 0,
    authorUid: user.username,
    authorName: user.name || "",
    authorProfilePic: user.profilePic || "",
    gameId: game ? game.gameId : "",
    gameName: game ? game.gameName : "",
    gameProfilePic: game ? game.gameProfilePic : "",
    done: false,
    completedOn: "",
    yearMonthCode: 0,
    commentsBy: [],
    lastCommentOn: null,
  };
}
/**
 *
 * @param id Shouldn't be necessary. Keeping for now as many docs in DB don't have id
 * @param goal goal object
 *
 * return a proper goal object out of any
 */
export function getGoalObjFromAny(id: string, goal: any): Goal {
  return {
    id: id || "",
    createdOn:
      goal.createdOn || firebase.firestore.FieldValue.serverTimestamp(),
    modifiedOn:
      goal.modifiedOn || firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: goal.createdBy || "",
    modifiedBy: goal.modifiedBy || "",
    text: goal.text || "",
    isEdited: goal.isEdited || false,
    isMuted: goal.isMuted,
    likes: goal.likes || 0,
    likesBy: goal.likesBy || [],
    commentCount: goal.commentCount || 0,
    lastSevenDaysLikes: goal.lastSevenDaysLikes || 0,
    lastThirtyDaysLikes: goal.lastThirtyDaysLikes || 0,
    lastSevenDaysComments: goal.lastSevenDaysComments || 0,
    lastThirtyDaysComments: goal.lastThirtyDaysComments || 0,
    authorUid: goal.authorUid || "",
    authorName: goal.authorName || "",
    authorProfilePic: goal.authorProfilePic || "",
    gameId: goal.gameId || "",
    gameName: goal.gameName || "",
    gameProfilePic: goal.gameProfilePic || "",
    done: goal.done || false,
    completedOn: goal.completedOn || "",
    yearMonthCode: goal.yearMonthCode,
    commentsBy: goal.commentsBy || [],
    lastCommentOn:
      goal.lastCommentOn || firebase.firestore.FieldValue.serverTimestamp(),
  };
}

export function getGoalDocPath(goalId: string) {
  return `${Collection.GOALS}/${goalId}`;
}

export function createQuestion(
  id: string,
  text: string,
  user: UserProfile
): Question {
  if (!user.username) {
    throw new Error(`username can't be falsy`);
  }
  return {
    id: id,
    createdOn: firebase.firestore.FieldValue.serverTimestamp(),
    modifiedOn: firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: user.username,
    modifiedBy: user.username,
    text: text,
    isEdited: false,
    isMuted: false,
    tags: [], //will be handled as trigged in backend
    likes: 0,
    likesBy: [],
    commentCount: 0,
    lastSevenDaysLikes: 0,
    lastThirtyDaysLikes: 0,
    lastSevenDaysComments: 0,
    lastThirtyDaysComments: 0,
    authorUid: user.username,
    authorName: user.name || "",
    authorProfilePic: user.profilePic || "",
    yearMonthCode: 0,
    commentsBy: [],
    answeredBy: [],
    lastCommentOn: null,
    gameId: null,
  };
}

export function getQuestionObjFromAny(question: any): Question {
  return {
    id: question.id || "",
    createdOn:
      question.createdOn || firebase.firestore.FieldValue.serverTimestamp(),
    modifiedOn:
      question.modifiedOn || firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: question.createdBy || "",
    modifiedBy: question.modifiedBy || "",
    text: question.text || "",
    isEdited: question.isEdited || false,
    isMuted: question.isMuted,
    tags: question.tags || [],
    likes: question.likes || 0,
    likesBy: question.likesBy || [],
    commentCount: question.commentCount || 0,
    lastSevenDaysLikes: question.lastSevenDaysLikes || 0,
    lastThirtyDaysLikes: question.lastThirtyDaysLikes || 0,
    lastSevenDaysComments: question.lastSevenDaysComments || 0,
    lastThirtyDaysComments: question.lastThirtyDaysComments || 0,
    authorUid: question.authorUid || "",
    authorName: question.authorName || "",
    authorProfilePic: question.authorProfilePic || "",
    yearMonthCode: question.yearMonthCode,
    commentsBy: question.commentsBy || [],
    answeredBy: question.answeredBy || [],
    lastCommentOn:
      question.lastCommentOn || firebase.firestore.FieldValue.serverTimestamp(),
    gameId: question.gameId || null,
  };
}

export function getQuestionDocPath(questionId: string) {
  return `${Collection.QUESTIONS}/${questionId}`;
}

export function createWip(
  id: string,
  text: string,
  mediaUrl: string,
  user: UserProfile,
  gameInfo?: GameBasicInfo,
  mediaName?: string
): WiP {
  if (!user.username) {
    throw new Error(`username can't be falsy`);
  }
  return {
    id: id,
    createdOn: firebase.firestore.FieldValue.serverTimestamp(),
    modifiedOn: firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: user.username,
    modifiedBy: user.username,
    text: text,
    isEdited: false,
    isMuted: false,
    likes: 0,
    likesBy: [],
    commentCount: 0,
    lastSevenDaysLikes: 0,
    lastThirtyDaysLikes: 0,
    lastSevenDaysComments: 0,
    lastThirtyDaysComments: 0,
    authorUid: user.username,
    authorName: user.name || "",
    authorProfilePic: user.profilePic || "",
    gameId: gameInfo ? gameInfo.gameId : "",
    gameName: gameInfo ? gameInfo.gameName : "",
    gameProfilePic: gameInfo ? gameInfo.gameProfilePic : "",
    mediaUrl: mediaUrl,
    mediaName: mediaName || null,
    yearMonthCode: 0,
    thumbs: [],
    commentsBy: [],
    lastCommentOn: null,
  };
}

export function getWiPObjFromAny(wip: any): WiP {
  return {
    id: wip.id || "",
    createdOn: wip.createdOn || firebase.firestore.FieldValue.serverTimestamp(),
    modifiedOn:
      wip.modifiedOn || firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: wip.createdBy || "",
    modifiedBy: wip.modifiedBy || "",
    text: wip.text || "",
    isEdited: wip.isEdited || false,
    isMuted: wip.isMuted,
    likes: wip.likes || 0,
    likesBy: wip.likesBy || [],
    commentCount: wip.commentCount || 0,
    lastSevenDaysLikes: wip.lastSevenDaysLikes || 0,
    lastThirtyDaysLikes: wip.lastThirtyDaysLikes || 0,
    lastSevenDaysComments: wip.lastSevenDaysComments || 0,
    lastThirtyDaysComments: wip.lastThirtyDaysComments || 0,
    authorUid: wip.authorUid || "",
    authorName: wip.authorName || "",
    authorProfilePic: wip.authorProfilePic || "",
    gameId: wip.gameId || "",
    gameName: wip.gameName || "",
    gameProfilePic: wip.gameProfilePic || "",
    mediaUrl: wip.mediaUrl || "",
    mediaName: wip.mediaName || null,
    yearMonthCode: wip.yearMonthCode,
    thumbs: wip.thumbs || [],
    commentsBy: wip.commentsBy || [],
    lastCommentOn:
      wip.lastCommentOn || firebase.firestore.FieldValue.serverTimestamp(),
  };
}

export function getWiPDocPath(wipId: string) {
  return `${Collection.WIPS}/${wipId}`;
}

//should not be used to format the edited data, it will clear out non form values
export function getUserProfileFromAny(userProfile: any): UserProfile {
  const designation = DESIGNATIONS.find(
    (des) => des.value === userProfile.designation
  );
  return {
    name: userProfile.name || "",
    username: userProfile.username || "",
    email: userProfile.email || "",
    profilePic: userProfile.profilePic || "",
    studioName: userProfile.studioName || "",
    location: userProfile.location || "",
    designation: designation ? designation.name || "" : "",
    twitterURL: userProfile.twitterURL || "",
    thumbs: userProfile.thumbs || [],

    githubURL: userProfile.githubURL || "",
    linkedInURL: userProfile.linkedInURL || "",
    websiteURL: userProfile.websiteURL || "",
    createdOn: userProfile.createdOn || "",
    modifiedOn: userProfile.modifiedOn || "",
    createdBy: userProfile.createdBy || "",
    modifiedBy: userProfile.modifiedBy || "",
    tags: userProfile.tags || [],
    goalDayActivityCount: userProfile.goalDayActivityCount || 0,
    goalWeekActivityCount: userProfile.goalWeekActivityCount || 0,
    goalMonthActivityCount: userProfile.goalMonthActivityCount || 0,
    goalTotalActivityCount: userProfile.goalTotalActivityCount || 0,
    goalTotalCreatedCount: userProfile.goalTotalCreatedCount || 0,
    wipDayCreatedCount: userProfile.wipDayCreatedCount || 0,
    wipWeekCreatedCount: userProfile.wipWeekCreatedCount || 0,
    wipMonthCreatedCount: userProfile.wipMonthCreatedCount || 0,
    wipTotalCreatedCount: userProfile.wipTotalCreatedCount || 0,
    questionDayAnsweredCount: userProfile.questionDayAnsweredCount || 0,
    questionWeekAnsweredCount: userProfile.questionWeekAnsweredCount || 0,
    questionMonthAnsweredCount: userProfile.questionMonthAnsweredCount || 0,
    questionTotalAnsweredCount: userProfile.questionTotalAnsweredCount || 0,
    questionTotalCreatedCount: userProfile.questionTotalCreatedCount || 0,
    totalGamesCreated: userProfile.totalGamesCreated || 0,

    currentStreak: userProfile.currentStreak || 0,
    highestStreak: userProfile.highestStreak || 0,
    weekStreak: userProfile.weekStreak || 0,
    weekHighestStreak: userProfile.weekHighestStreak || 0,
    notificationCounts: userProfile.notificationCounts || 0,
    isAdmin: userProfile.isAdmin || false,
  };
}
