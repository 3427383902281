import { createContext, ReactElement, useContext } from "react";
import useUserProfile from "../hooks/useUserProfile";
import { UserProfile } from "../types";

const UserProfileContext = createContext<UserProfile | undefined>(undefined);

export default function UserProfileProvider(props: {
  children: ReactElement;
  uid: string | undefined;
}) {
  const [userProfile] = useUserProfile(props.uid);

  return (
    <UserProfileContext.Provider value={userProfile}>
      {props.children}
    </UserProfileContext.Provider>
  );
}

function useUserProfileState() {
  const context = useContext(UserProfileContext);
  return context;
}

export { UserProfileProvider, useUserProfileState };
