import { useState } from "react";
import { LoadingOutlined, UserOutlined } from "@ant-design/icons";
import { message, Form, Input } from "antd";
import firebase from "firebase/app";
import { debounce } from "lodash";
import { useHistory } from "react-router-dom";
import useFirebaseFnCalling from "../hooks/useFirebaseFnCalling";
import Modal from "../components/Modal";
import H1 from "../components/H1";
import { Collection } from "../types";
import ModalForm from "../components/ModalForm";
import { CONSTANTS } from "../config";
import { PrimaryButton } from "../components/SignInFlowComponents";

function ResendVerificationEmail() {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(true);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [, callFirebaseFn] = useFirebaseFnCalling();

  async function handleSubmit(values: any) {
    setLoading(true);
    const userDoc = await firebase
      .firestore()
      .collection(Collection.USERPROFILES)
      .where("email", "==", values.email)
      .get();
    if (userDoc.docs.length) {
      callFirebaseFn(
        "resendVerificationEmail",
        { email: values.email, uid: userDoc.docs[0].id },
        () => {
          setVisible(false);
          setLoading(false);
          message.success(
            `A verification email link has been sent to the email provided & it will expire in 24 hours.`,
            5
          );
        },
        (e) => {
          setLoading(false);
          message.error(`Unable to send the email, please try again.`);
        }
      );
    } else {
      setLoading(false);
      message.error(`We didn't find any user registered with this email.`);
    }
  }

  return (
    <Modal
      afterClose={() => history.push("/")}
      visible={visible}
      centeredBody={true}
      destroyOnClose
      onCancel={() => setVisible(false)}
      logoAsAvatar={true}
    >
      <H1>Resend verification email</H1>
      <ModalForm onFinish={handleSubmit} form={form}>
        <Form.Item
          style={{ width: "100%" }}
          name="email"
          rules={[
            {
              required: true,
              message: "Please provide your email id.",
            },
            {
              validator: debounce((rule, value, callback) => {
                if (CONSTANTS.EmailRegEx.test(value)) {
                  callback();
                } else {
                  callback("Please provide a valid email id.");
                }
              }, 1000),
            },
          ]}
          validateFirst
        >
          <Input
            prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Email"
          />
        </Form.Item>

        <PrimaryButton type="primary" htmlType="submit" disabled={loading}>
          {loading ? <LoadingOutlined /> : "Resend"}
        </PrimaryButton>
      </ModalForm>
    </Modal>
  );
}

export default ResendVerificationEmail;
