import firebase from "firebase/app";
import { useState, useEffect } from "react";
import {
  NotificationWithId,
  Notification,
  NavigateToType,
  Collection,
  UserProfile,
} from "../types";

function useNotification({
  limit,
  onlyUnread,
}: {
  limit: number;
  onlyUnread: boolean;
}) {
  const currentUser = firebase.auth().currentUser as firebase.User;
  const [loading, setLoading] = useState(false);
  const [markingAllRead, setMarkingAllRead] = useState(false);
  const [notifications, setNotifications] = useState<NotificationWithId[]>([]);

  useEffect(() => {
    let query = firebase
      .firestore()
      .collection(
        `${Collection.USERPROFILES}/${currentUser.uid}/${Collection.NOTIFICATIONS}`
      ) as firebase.firestore.Query;

    if (onlyUnread) {
      query = query.where("read", "==", false);
    }

    setLoading(true);

    const unsubscribe = query
      .where("isDeleted", "==", false)
      .orderBy("createdOn", "desc")
      .limit(limit)
      .onSnapshot((snapshot) => {
        setNotifications(
          snapshot.docs.map((doc) => ({
            ...(doc.data() as Notification),
            id: doc.id,
          }))
        );
        setLoading(false);
      });

    return () => unsubscribe();
  }, [currentUser.uid, limit, onlyUnread]);

  async function markAllNotificationRead() {
    setMarkingAllRead(true);

    try {
      await firebase.functions().httpsCallable("markAllNotificationsRead")();
    } catch (error) {
      console.error(error);
    } finally {
      setMarkingAllRead(false);
    }
  }

  async function markNotificationAsRead(notificationId: string, uid: string) {
    const existingDoc = await firebase
      .firestore()
      .doc(
        `${Collection.USERPROFILES}/${uid}/${Collection.NOTIFICATIONS}/${notificationId}`
      )
      .get();
    const data = existingDoc.data() as Notification | undefined;
    if (data && !data.read) {
      await existingDoc.ref.update({
        read: true,
      } as Partial<Notification>);
      await firebase
        .firestore()
        .doc(`${Collection.USERPROFILES}/${uid}`)
        .update({
          notificationCounts: firebase.firestore.FieldValue.increment(-1),
        } as Partial<UserProfile>);
    }
  }

  function getNotificationPath(
    navigateTo: NavigateToType,
    id?: string
  ): string {
    if (id) {
      switch (navigateTo) {
        case "GAME":
          return `/games/${id}`;
        case "GOAL":
          return `/goals/${id}`;
        case "WIP":
          return `/wips/${id}`;
        case "QUESTIONS":
          return `/discussions/${id}`;
        case "USER":
          return `/users/${id}`;
      }
      return `#`;
    } else if (navigateTo === "INVITES") {
      return `/manage-requests`;
    } else {
      return `#`;
    }
  }

  return {
    loading,
    notifications,
    getNotificationPath,
    markNotificationAsRead,
    markingAllRead,
    markAllNotificationRead,
  };
}

export default useNotification;
