import { parseVideo } from "../utils";

function Video(props: { url: string; width: string; height: string }) {
  const videoObj = parseVideo(props.url);

  return (
    <iframe
      title={videoObj.id as string}
      style={{
        width: props.width,
        height: props.height,
        // have to uplift to make the rest of the card clickable
        position: "relative",
        zIndex: 1,
      }}
      frameBorder={"0"}
      src={
        videoObj.type === "youtube"
          ? "//www.youtube.com/embed/" + videoObj.id
          : "//player.vimeo.com/video/" + videoObj.id
      }
      allowFullScreen={true}
    />
  );
}

export default Video;
