import { ReactNode, useState } from "react";
import firebase from "firebase/app";
import {
  GameBasicInfo,
  GameProfile,
  EntityExtendedType,
  EntityType,
  Collection,
} from "../types";
import { Popover } from "antd";
import AddGameAutoComplete from "./AddGameAutoComplete";
import { Link } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";

function GameTagText({
  onClick,
  children,
  className,
}: {
  onClick?: (visible: boolean) => void;
  children: ReactNode;
  className: string;
}) {
  return (
    <span
      className={`game-tag ${className}`}
      onClick={() => onClick && onClick(true)}
    >
      {children}
    </span>
  );
}

function GameTag<T extends EntityExtendedType>({
  entity,
  entityType,
  noGameDetails,
  currentUser,
  pathPrefix,
}: {
  entity: T;
  entityType?: EntityType;
  noGameDetails: boolean;
  currentUser?: firebase.User | null;
  pathPrefix: string;
}) {
  const [tagSearchVisibility, setTagSearchVisibility] = useState<boolean>();

  //can be undefined
  const { gameId, gameName } = (entity as unknown) as GameBasicInfo;

  async function assignGame(entityId: string, gameId: string) {
    try {
      const doc = await firebase
        .firestore()
        .collection(`${Collection.GAMEPROFILES}`)
        .doc(`${gameId}`)
        .get();
      if (doc.exists) {
        const gameData = doc.data() as GameProfile;
        await firebase
          .firestore()
          .collection(`${pathPrefix}`)
          .doc(`${entityId}`)
          .update({
            gameId: gameId,
            gameName: gameData.name,
            gameProfilePic: gameData.profilePic,
          } as GameBasicInfo);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function removeGame(entityId: string) {
    await firebase
      .firestore()
      .collection(`${pathPrefix}`)
      .doc(`${entityId}`)
      .update({
        gameId: null,
        gameName: null,
        gameProfilePic: null,
      } as GameBasicInfo);
  }
  function getGameDisplay(gameId: string, isOwner: boolean) {
    return gameId ? (
      isOwner ? (
        <span title={gameId}>{`#${gameId}`}</span>
      ) : (
        <Link to={`/games/${gameId}`} title={gameId}>
          <span>{`#${gameId}`}</span>
        </Link>
      )
    ) : null;
  }
  return (
    <>
      {entityType && !noGameDetails ? (
        currentUser && currentUser.uid === entity.authorUid ? (
          <Popover
            title={
              <div>
                <span>Tag Game</span>
                <span style={{ float: "right", cursor: "pointer" }}>
                  <CloseOutlined
                    onClick={() => setTagSearchVisibility(false)}
                  />
                </span>
              </div>
            }
            visible={tagSearchVisibility}
            content={
              <AddGameAutoComplete
                onSelect={(gameId: string) => {
                  setTagSearchVisibility(false);
                  assignGame(entity.id, gameId);
                }}
                onRemove={() => {
                  removeGame(entity.id);
                  setTagSearchVisibility(false);
                }}
                defaultValue={gameName || gameId || undefined}
              />
            }
            destroyTooltipOnHide={true}
          >
            {gameId ? (
              <GameTagText
                className="truncate"
                onClick={() => setTagSearchVisibility(true)}
              >
                {getGameDisplay(gameId, true)}
              </GameTagText>
            ) : (
              <GameTagText
                className="truncate"
                onClick={() => setTagSearchVisibility(true)}
              >
                tag game
              </GameTagText>
            )}
          </Popover>
        ) : entityType && gameId ? (
          <GameTagText className="truncate">
            {" "}
            {getGameDisplay(gameId, false)}
          </GameTagText>
        ) : null
      ) : null}
    </>
  );
}

export default GameTag;
