import algoliasearch from "algoliasearch";
import firebase from "firebase/app";
import { uniq } from "lodash";
import { useState, useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Mention, MentionsInput, SuggestionDataItem } from "react-mentions";
import { useHistory } from "react-router-dom";
import EntityContext from "../contexts/EntityContext";
import useWindowSize, { WindowSize } from "../hooks/useWindowSize";
import { getConfig, getThumbnailStorageUrl } from "../utils";
import Avatar from "./Avatar";
import classNames from "./CommentForm.module.css";
import ModalForm from "./ModalForm";

const {
  algolia: { appId, searchKey },
} = getConfig();

const client = algoliasearch(appId, searchKey);
const index = client.initIndex("common");

type CommentFormPropsType = {
  onSubmit: (
    data: { comment: string; mentions: Array<string> },
    onSuccess: () => void
  ) => void;
  initialValue?: string;
};

function CommentForm({ initialValue = "", onSubmit }: CommentFormPropsType) {
  const windowSize = useWindowSize();
  const isSmallScreen = windowSize <= WindowSize.md;
  const history = useHistory();
  const [, setSearching] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [comment, setComment] = useState<string>(initialValue);
  const [mentions, setMentions] = useState<Array<string>>([]);
  const [user] = useAuthState(firebase.auth());
  const entity: any = useContext(EntityContext);

  function search(
    query: string,
    callback: (data: SuggestionDataItem[]) => void
  ) {
    const involvedUsers = uniq([
      entity.createdBy,
      ...entity?.likesBy,
      ...entity?.commentsBy,
    ]).filter((x) => x !== user?.uid);

    if (!query.length && !involvedUsers.length) {
      return;
    }

    setSearching(true);

    let filters = `type:user AND NOT objectID:${user?.uid}`;

    if (!query.length) {
      let involvedUsersFilters = "";

      involvedUsers.forEach((user, i) => {
        if (i + 1 === involvedUsers.length) {
          involvedUsersFilters = `${involvedUsersFilters} objectID:${user}`;
        } else {
          involvedUsersFilters = `${involvedUsersFilters} objectID:${user} OR`;
        }
      });

      if (involvedUsersFilters) {
        filters = `${filters} AND (${involvedUsersFilters})`;
      }
    }

    index
      .search(query, {
        filters,
        hitsPerPage: 10,
      })
      .then((result) => {
        setSearching(false);
        return result.hits
          .map((hit: any) => ({
            id: hit.objectID as any,
            name: hit.name as string,
            display: hit.username as string,
            profilePic: hit.profilePic as string,
          }))
          .sort((x, y) =>
            x.id === entity.createdBy ? -1 : y.id === entity.createdBy ? 1 : 0
          );
      })
      .then(callback);
  }

  async function handleKeyDown(event: any) {
    if (event.key === "Escape") {
      event.stopPropagation();
    } else if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      event.stopPropagation();

      if (comment.trim()) {
        setSubmitting(true);
        onSubmit({ comment, mentions }, () => {
          setComment("");
          setSubmitting(false);
        });
      }
    }
  }

  return (
    <ModalForm style={{ width: "100%" }}>
      <MentionsInput
        value={comment}
        autoFocus={Boolean(firebase.auth().currentUser && !isSmallScreen)}
        onChange={(event, _, __, mentions) => {
          setComment(event.target.value);
          setMentions(mentions.map((mention) => mention.id));
        }}
        placeholder="Type your comment and press enter"
        onKeyDown={handleKeyDown}
        classNames={classNames}
        onFocus={() => {
          !firebase.auth().currentUser && history.push("/sign-in");
        }}
        disabled={submitting}
        style={{
          suggestions: {
            zIndex: 10,
          },
        }}
      >
        <Mention
          trigger="@"
          data={search}
          appendSpaceOnAdd
          className={classNames.mentions__mention}
          displayTransform={(mention) => `@${mention}`}
          renderSuggestion={(suggestion: any, search, highlightedDisplay) => (
            <div>
              <Avatar
                size={16}
                src={getThumbnailStorageUrl(suggestion.profilePic, 32)}
                alt={suggestion.name || suggestion.display}
              />
              <span style={{ marginLeft: 5 }}>{highlightedDisplay}</span>
            </div>
          )}
        />
      </MentionsInput>
    </ModalForm>
  );
}

export default CommentForm;
