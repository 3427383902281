import firebase from "firebase/app";
import { useUserProfileState } from "../stores/UserProfileProvider";
import { useEditContentContext } from "../stores/edit-content-context";
import { message } from "antd";
import CommentForm from "./CommentForm";
import { Comment } from "../types";

function EditCommentForm({ pathPrefix }: { pathPrefix: string }) {
  const userProfile = useUserProfileState();

  const editContext = useEditContentContext();

  async function handleSubmit({
    comment,
    mentions,
  }: {
    comment: string;
    mentions: Array<string>;
  }) {
    if (!userProfile) {
      throw new Error(`userProfile cant be falsy`);
    }

    if (
      editContext &&
      editContext.editStatusObj.isEdit &&
      editContext.editStatusObj.id &&
      pathPrefix
    ) {
      try {
        editContext.setEditObjStatus({ isEdit: false });
        await firebase
          .firestore()
          .collection(pathPrefix)
          .doc(editContext.editStatusObj.id)
          .update({
            text: comment,
            mentions,
            modifiedBy: userProfile.username,
            modifiedOn: firebase.firestore.FieldValue.serverTimestamp(),
            isEdited: true,
          } as Comment);
      } catch (e) {
        console.log(e);
        message.error(`Unable to save your comment, please try again.`);
        throw e;
      }
    }
  }

  return (
    <>
      {editContext && editContext.editStatusObj.isEdit ? (
        <CommentForm
          initialValue={editContext.editStatusObj.text || ""}
          onSubmit={handleSubmit}
        />
      ) : null}
    </>
  );
}
export default EditCommentForm;
