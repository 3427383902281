import H1 from "../components/H1";
import Modal from "../components/Modal";
import SignInForm from "../components/SignInForm";
import { useHistory, useLocation } from "react-router-dom";
import { useState } from "react";

function SignIn() {
  const history = useHistory();
  const location = useLocation<any>();
  const [visible, setVisible] = useState(true);
  const backdropLocation = location.state?.backdropLocation;
  const uid = location.state?.uid;

  return (
    <Modal
      afterClose={() => {
        if (backdropLocation) {
          history.push(backdropLocation.pathname);
        } else {
          history.push("/");
        }
      }}
      logoAsAvatar={true}
      visible={visible}
      onCancel={() => setVisible(false)}
      destroyOnClose
    >
      <H1>Welcome back!</H1>
      <SignInForm uid={uid} />
    </Modal>
  );
}

export default SignIn;
