import { ReactNode, useState, createContext, useContext } from "react";

type NotificationValue =
  | undefined
  | {
      notificationCount: number;
      setNotificationCount: React.Dispatch<React.SetStateAction<number>>;
    };

const NotificationContext = createContext<NotificationValue>(undefined);

function NotificationProvider({ children }: { children: ReactNode }) {
  const [notificationCount, setNotificationCount] = useState<number>(0);

  return (
    <NotificationContext.Provider
      value={{ notificationCount, setNotificationCount }}
    >
      {children}
    </NotificationContext.Provider>
  );
}

function useNotificationContext() {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      `useNotificationContext must be use inside NotificationProvider`
    );
  }
  return context;
}

export { NotificationProvider, useNotificationContext };
