import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import useWindowSize, { WindowSize } from "../hooks/useWindowSize";

function FooterNav(props: { className?: string }) {
  const { pathname } = useLocation();
  const windowSize = useWindowSize();
  const privacyMenuText =
    windowSize && windowSize <= WindowSize.md ? "Privacy" : "Privacy Policy";
  const Menus = () => (
    <Menu {...props} theme="dark" mode="horizontal">
      <Menu.Item
        key="privacy"
        className={pathname === "/privacy-policy" ? "ant-menu-item-active" : ""}
      >
        <Link to="/privacy-policy">{privacyMenuText}</Link>
      </Menu.Item>
      <Menu.Item
        key="terms-of-use"
        className={pathname === "/terms-of-use" ? "ant-menu-item-active" : ""}
      >
        <Link to="/terms-of-use">Terms of Use</Link>
      </Menu.Item>

      <Menu.Item
        key="guidelines"
        className={
          pathname === "/community-guidelines" ? "ant-menu-item-active" : ""
        }
      >
        <Link to="/community-guidelines">Guidelines</Link>
      </Menu.Item>
      <Menu.Item key="presskit">
        <a
          href="https://lucidlabs.in/press/wemake.games/index.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Press Kit
        </a>
      </Menu.Item>
    </Menu>
  );

  return <Menus />;
}

export default FooterNav;
