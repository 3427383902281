import Modal from "../components/Modal";
import { Link, useHistory, useLocation } from "react-router-dom";
import H1 from "../components/H1";
import { useState } from "react";
import { CONSTANTS, DEVICE_SIZES, minPasswordLength } from "../config";
import { AnalyticsEvent, Collection } from "../types";
import { MailOutlined, UserOutlined, LockOutlined } from "@ant-design/icons";
import { message, Input, Form } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import styled from "styled-components/macro";
import ModalForm from "../components/ModalForm";
import { PrimaryButton } from "../components/SignInFlowComponents";
import UniqueIdControl from "../components/UniqueIdControl";
import useWindowSize, { WindowSize } from "../hooks/useWindowSize";
import { logEvents } from "../utils";
import firebase from "firebase/app";

const StyledItem = styled(Form.Item)`
  &&& .ant-form-item-control {
    line-height: 14px;
  }
`;

const FieldsContainer = styled.div`
  width: 70%;
  @media (${DEVICE_SIZES.tablet}) {
    width: 100%;
  }
`;

function SignUp() {
  const history = useHistory();
  const location = useLocation<any>();
  const [visible, setVisible] = useState(true);
  const backdropLocation = location.state?.backdropLocation;
  const [form] = Form.useForm();
  const windowSize = useWindowSize();
  const isSmallScreen = windowSize <= WindowSize.md;
  const [submitting, setSubmitting] = useState(false);

  async function handleSubmit(values: any) {
    const signUp = firebase.functions().httpsCallable("signUp");

    setSubmitting(true);

    try {
      await signUp({
        email: values.email,
        username: values.username.toLowerCase(),
        password: values.password,
      });

      history.push("/");
      message.success(
        "We have sent you an email with a verification link. Please verify your account to get started.",
        5
      );
      logEvents(AnalyticsEvent.user_signups, values.email);
    } catch (error) {
      if (error.message === "auth/uid-already-exists") {
        form.setFields([
          {
            name: "username",
            value: values.username,
            errors: ["User already exists with this username, try signing in."],
          },
        ]);
      } else if (error.message === "auth/email-already-exists") {
        form.setFields([
          {
            name: "email",
            value: values.email,
            errors: ["User already exists with this email address."],
          },
        ]);
      } else {
        message.error("Unable to sign up, please try again.");
      }
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Modal
      afterClose={() => {
        if (backdropLocation) {
          history.push(backdropLocation.pathname);
        } else {
          history.push("/");
        }
      }}
      visible={visible}
      onCancel={() => setVisible(false)}
      destroyOnClose
      logoAsAvatar={true}
    >
      <>
        <H1>Join WMG</H1>
        <p style={{ textAlign: "center" }}>
          A community of game makers who build together.
        </p>

        <ModalForm
          form={form}
          onFinish={handleSubmit}
          style={{ width: "100%" }}
        >
          <FieldsContainer>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please provide your email id.",
                },
                {
                  validator: (_, value) =>
                    CONSTANTS.EmailRegEx.test(value)
                      ? Promise.resolve()
                      : Promise.reject("Please provide a valid email id."),
                },
              ]}
              validateFirst
            >
              <Input
                prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="Email"
                autoFocus={!isSmallScreen}
              />
            </Form.Item>
            <UniqueIdControl
              form={form}
              collection={Collection.USERPROFILES}
              fieldName="username"
              rules={[
                {
                  required: true,
                  message: "Please provide your username.",
                },
                {
                  pattern: CONSTANTS.UsernameRegEx,
                  message: "Only alphanumeric and _ allowed",
                },
              ]}
              validateFirst={true}
              inputProps={{
                prefix: <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />,
                placeholder: "Username",
              }}
            />

            <Form.Item
              name="password"
              rules={[
                {
                  min: minPasswordLength,
                  message: `Password should be of min ${minPasswordLength} characters!`,
                },
                { required: true, message: "Please provide your password." },
              ]}
            >
              <Input
                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>

            <StyledItem style={{ padding: "10px", textAlign: "center" }}>
              <Form.Item
                noStyle
                name="acceptTerms"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject("Please accept the terms of service."),
                  },
                ]}
                valuePropName="checked"
              >
                <Checkbox style={{ marginRight: 8 }} />
              </Form.Item>
              <span>
                By signing up you agree to our{" "}
                <Link
                  to="/terms-of-use"
                  target="_blank"
                  style={{ textDecoration: "underline" }}
                >
                  Terms of Use
                </Link>{" "}
                {`&`}{" "}
                <Link
                  to="/privacy-policy"
                  target="_blank"
                  style={{ textDecoration: "underline" }}
                >
                  Privacy Policy
                </Link>
              </span>
            </StyledItem>
          </FieldsContainer>
          <PrimaryButton
            type="primary"
            htmlType="submit"
            disabled={submitting}
            loading={submitting}
          >
            Sign Up
          </PrimaryButton>
        </ModalForm>
      </>
    </Modal>
  );
}

export default SignUp;
