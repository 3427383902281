import { Avatar as AntAvatar } from "antd";
import styled from "styled-components/macro";

const ShadowedAvatar = styled(AntAvatar)`
  box-shadow: ${(props) => props.theme.colors.componentShadow};

  img {
    height: 100%;
    width: 100%;
  }
`;

export type AvatarProps = {
  src?: string;
  alt?: string;
  size?: number;
  className?: string;
};

function Avatar({ src, alt, size, className = "" }: AvatarProps) {
  return src ? (
    <ShadowedAvatar className={className} src={src} alt={alt} size={size} />
  ) : alt ? (
    <ShadowedAvatar className={className} size={size}>
      {alt.slice(0, 1).toUpperCase()}
    </ShadowedAvatar>
  ) : (
    <ShadowedAvatar className={className} size={size} />
  );
}
export default Avatar;
