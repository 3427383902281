import { useEffect, useState } from "react";
import styled from "styled-components/macro";
import {
  Collection,
  EntityType,
  EntityExtendedType,
  Participants,
} from "../types";
import { getEntityCollection } from "../utils";
import { CONSTANTS } from "../config";
import firebase from "firebase/app";
import useFirebaseFnCalling from "../hooks/useFirebaseFnCalling";
import ButtonLink from "../components/ButtonLink";

type SubscribePayload = {
  mute: boolean;
  token: string;
  entityType: EntityType;
  entityId: string;
};
const MuterContainer = styled.span`
  button,
  span {
    font-size: ${(props) => props.theme.fontSizes.timelineItem.action};
  }
  button {
    text-decoration: underline;
  }
`;

function MuteUpdates({
  entityType,
  entityId,
  owner,

  isMuted,
}: {
  entityType: EntityType;
  entityId: string;
  owner: string;

  isMuted?: boolean;
}) {
  const currentUser = firebase.auth().currentUser;

  async function subscribeOwner(subscribe: boolean) {
    if (entityType) {
      const collection = getEntityCollection(entityType);
      await firebase
        .firestore()
        .doc(`${collection}/${entityId}`)
        .update({
          isMuted: !subscribe,
        } as Partial<EntityExtendedType>);
    }
  }

  return (
    <MuterContainer>
      {currentUser ? (
        owner === currentUser.uid ? (
          isMuted ? (
            <ButtonLink onClick={() => subscribeOwner(true)}>
              subscribe
            </ButtonLink>
          ) : (
            <ButtonLink onClick={() => subscribeOwner(false)}>
              unsubscribe
            </ButtonLink>
          )
        ) : (
          <NonOwnerMuteActions
            entityType={entityType}
            entityId={entityId}
            currentUser={currentUser}
          />
        )
      ) : null}
    </MuterContainer>
  );
}

function NonOwnerMuteActions({
  entityType,
  entityId,
  currentUser,
}: {
  entityType: EntityType;
  entityId: string;
  currentUser: firebase.User;
}) {
  const [isMuted, setMuted] = useState<boolean>(); //undefined is users haven't made any comment
  const [processing, callFirebaseFn] = useFirebaseFnCalling();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function checkMuteStatus() {
      if (entityType && currentUser) {
        const collection = getEntityCollection(entityType);
        setLoading(true);
        try {
          const commenterDoc = await firebase
            .firestore()
            .doc(
              `${collection}/${entityId}/${Collection.PARTICIPANTS}/${CONSTANTS.CommentersDoc}`
            )
            .get();
          setLoading(false);
          if (commenterDoc.exists) {
            const data = commenterDoc.data() as undefined | Participants;
            if (data) {
              //checking both as we only want the subscribe unsubscribe for users who have commented
              const isActive = (data.commentActiveUsers || []).includes(
                currentUser.uid
              );
              const isMutedUsers = (data.commentMutedUsers || []).includes(
                currentUser.uid
              );
              if (isActive || isMutedUsers) {
                if (isActive) {
                  setMuted(false);
                }
                if (isMutedUsers) {
                  setMuted(true);
                }
              }
            }
          }
        } catch (e) {
          setLoading(false);
          throw e;
        }
      }
    }
    checkMuteStatus();
  }, [entityType, entityId]);

  async function muteUser(mute: boolean) {
    if (currentUser) {
      const token = await currentUser.getIdToken();
      const requestData: SubscribePayload = {
        entityId,
        entityType,
        token,
        mute,
      };
      callFirebaseFn(
        `muteUnmuteNotification`,
        requestData,
        () => {
          setMuted(mute);
        },
        () => {
          console.log(`Error ocurred while updating subscription`);
        }
      );
    }
  }

  return (
    <>
      {isMuted === undefined ? null : processing ? (
        <span> updating</span>
      ) : isMuted === true ? (
        <ButtonLink onClick={() => (!loading ? muteUser(false) : false)}>
          subscribe
        </ButtonLink>
      ) : (
        <ButtonLink onClick={() => (!loading ? muteUser(true) : false)}>
          unsubscribe
        </ButtonLink>
      )}
    </>
  );
}
export default MuteUpdates;
