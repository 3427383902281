import firebase from "firebase/app";
import { EntityExtendedType } from "../types";
import { useEditContentContext } from "../stores/edit-content-context";
import { ActionText } from "./EditDeleteActionStyles";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";

function EntityEdit<T extends EntityExtendedType>({
  type,
  entity,
}: {
  type: "icon" | "text";
  entity: T;
}) {
  const currentUser = firebase.auth().currentUser;
  const editContext = useEditContentContext();
  return (
    <div className="edit-action" style={{ cursor: "pointer" }}>
      {currentUser && currentUser.uid === entity.authorUid && editContext ? (
        <>
          {!editContext.editStatusObj.isEdit ? (
            <>
              {type === "icon" ? (
                <EditOutlined
                  onClick={() => {
                    editContext.setEditObjStatus({
                      isEdit: true,
                      id: entity.id,
                      text: entity.text,
                    });
                  }}
                />
              ) : (
                <ActionText
                  onClick={() => {
                    editContext.setEditObjStatus({
                      isEdit: true,
                      id: entity.id,
                      text: entity.text,
                    });
                  }}
                >
                  Edit
                </ActionText>
              )}
            </>
          ) : (
            <>
              {type === "icon" ? (
                <CloseOutlined
                  onClick={() => {
                    editContext.setEditObjStatus({ isEdit: false });
                  }}
                />
              ) : (
                <ActionText
                  onClick={() => {
                    editContext.setEditObjStatus({ isEdit: false });
                  }}
                >
                  Cancel
                </ActionText>
              )}
            </>
          )}
        </>
      ) : null}
    </div>
  );
}

export default EntityEdit;
