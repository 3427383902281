import styled from "styled-components/macro";
import { EntityExtendedType, EntityType, Goal } from "../types";

const Text = styled.span`
  line-height: 1.7;
  font-size: ${(props) => props.theme.fontSizes.timelineItem.tag};
  color: ${(props) => props.theme.colors.tertiaryTextColor};
  margin: 0 2px;
`;
function EditText() {
  return <Text className="edited">(edited)</Text>;
}

function EditTextBadge<T extends EntityExtendedType>({
  entity,
  entityType,
}: {
  entity: T;
  entityType?: EntityType;
}) {
  return (
    <>
      {entity.isEdited ? (
        <span className="edited-label">
          {entity.isEdited &&
          //Pending goals are still in draft state, so not required to show (edited) if edited
          (entityType !== "goal" || ((entity as unknown) as Goal).done) ? (
            <EditText />
          ) : null}
        </span>
      ) : null}
    </>
  );
}

export default EditTextBadge;
