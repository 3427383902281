import { Popconfirm } from "antd";
import truncate from "lodash/truncate";
import { useState } from "react";
import Video from "./Video";
import { getDownloadableUrl } from "../utils";
import styled from "styled-components/macro";
import { ImageURLType } from "../types";
import useWindowSize, { WindowSize } from "../hooks/useWindowSize";
import { DeleteOutlined, PlayCircleOutlined } from "@ant-design/icons";

export type MediaDisplayType = "image" | "video" | "thumbnail";

const MediaContainer = styled.div`
  position: relative;

  /* have to uplift to make the rest of the card clickable */
  z-index: 1;

  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.imageBackdrop};
  cursor: pointer;

  &:hover .delete-icon,
  &:hover .preview {
    display: block;
  }
  .anticon {
    position: absolute;
    color: ${(props) => props.theme.colors.mediaContainerButtonText};
    cursor: pointer;
  }
  .delete-icon {
    right: 3px;
    top: 3px;
    font-size: 14px;
  }

  .centered-icon {
    top: 50%;
    left: 50%;
    font-size: 20px;
    transform: translate(-50%, -50%);
  }

  .preview {
    display: none;
  }
`;

const ImageComp = ({ alt, media }: { alt: string; media: ImageURLType }) => {
  const [errorImage, setErrorImage] = useState<string>();
  let src = errorImage || getDownloadableUrl(media);

  return (
    <img
      style={{ objectFit: "contain", height: "100%", width: "100%" }}
      src={src}
      alt={truncate(alt)}
      onError={() => {
        setErrorImage(
          "https://dummyimage.com/650x400/000/82828a&text=Invalid+Image"
        );
      }}
    />
  );
};

const DeleteIcon = ({
  onDelete,
  type,
}: {
  onDelete?: () => void;
  type: MediaDisplayType;
}) => {
  const [isVisible, setVisible] = useState(false);
  function onDeleteIconClick(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    e.stopPropagation();
    setVisible(true);
  }
  return (
    <Popconfirm
      title={`Are you sure you want to delete the ${
        type === "image" ? "image" : "video"
      }?`}
      visible={isVisible}
      onConfirm={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
        onDelete && onDelete();
      }}
      onCancel={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setVisible(false);
      }}
      destroyTooltipOnHide
    >
      <DeleteOutlined
        className="delete-icon preview"
        onClick={(e) => onDeleteIconClick(e)}
      />
    </Popconfirm>
  );
};

function MediaViewer({
  media,
  type,
  alt,
  onClick,
  allowDelete = false,
  onDelete,
  hasThumbnail = true,
  videoType,
}: {
  media: ImageURLType;
  type: MediaDisplayType;
  alt: string;
  onClick?: (e: any) => void;
  allowDelete?: boolean;
  onDelete?: () => void;
  hasThumbnail?: boolean;
  videoType?: string;
}) {
  const windowSize = useWindowSize();
  const isSmallScreen = windowSize <= WindowSize.md;

  if (type === "video" && videoType !== "direct-link") {
    return <Video url={media as string} height="100%" width="100%" />;
  } else if (type === "video" && videoType === "direct-link") {
    return (
      <video
        style={{ background: "black" }}
        controls
        width="100%"
        height="100%"
        preload="metadata"
      >
        <source src={media as string}></source>
      </video>
    );
  }

  return (
    <MediaContainer
      css={`
        .preview {
          display: ${isSmallScreen ? "block" : "none"};
        }
      `}
      onClick={(e) => onClick && onClick(e)}
    >
      <ImageComp alt={alt} media={media} />
      {allowDelete && <DeleteIcon onDelete={onDelete} type={type} />}
      {type === "thumbnail" && <PlayCircleOutlined className="centered-icon" />}
    </MediaContainer>
  );
}

export default MediaViewer;
