import { createGlobalStyle } from "styled-components/macro";
import { DEVICE_SIZES } from "../config";
import "lightbox-react/style.css";
import "../assets/fonts/style.css";
import "../App.less";

const GlobalStyle = createGlobalStyle`
  html,
  body {
    margin: 0;
    padding: 0;
    font-family: "Calibri";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  }

  ::selection {
    color: ${(props) => props.theme.colors.textColor};
    background: ${(props) => props.theme.colors.textSelectBackground};
  }
  .truncate{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .break-word{
    word-break: break-word;
  }
  &&& .remove-bottom-margin{
    margin-bottom:0px !important;
  }

  .linkified{
    text-decoration: underline;
  }

  .header{
        display: flex;
        align-items: center;
        line-height: 0;
        padding: 0 ${(props) => props.theme.layoutSizes.horizontalMargin};
  }

  .mobile-only{
    display:none  !important;
  }

  @media (${DEVICE_SIZES.tablet}) {
    .desktop-only{
      display:none !important;
    }
    .mobile-only{
        display:unset !important;
    }
  }
`;

export default GlobalStyle;
