import { RouteComponentProps } from "react-router-dom";
import EntityDetails from "../components/EntityDetails";
import EntityDetailsModal from "../components/EntityDetailsModal";
import NotFound from "../components/NotFound";
import useEntitySnapshot from "../hooks/useEntitySnapshot";
import useWindowSize, { WindowSize } from "../hooks/useWindowSize";

type QuestionProps = RouteComponentProps<{ id: string }>;

function Question(props: QuestionProps) {
  const windowSize = useWindowSize();
  const isSmallScreen = windowSize <= WindowSize.md;
  const [entity, loading] = useEntitySnapshot(
    "question",
    props.match.params.id
  );

  if (loading) {
    return null;
  }

  if (isSmallScreen) {
    if (!entity) {
      return <NotFound />;
    }

    return <EntityDetails entityType="question" entity={entity} {...props} />;
  }

  if (!entity) {
    return null;
  }

  return (
    <EntityDetailsModal entityType="question" entity={entity} {...props} />
  );
}

export default Question;
