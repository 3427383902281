import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Modal from "../components/Modal";
import H1 from "../components/H1";
import { Input, message, Form } from "antd";
import ModalForm from "../components/ModalForm";
import { PrimaryButton } from "../components/SignInFlowComponents";
import useFirebaseFnCalling from "../hooks/useFirebaseFnCalling";
import firebase from "firebase/app";
import { Collection } from "../types";
import { LoadingOutlined, UserOutlined } from "@ant-design/icons";

function ForgotPassword() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(true);
  const history = useHistory();
  const location = useLocation<any>();
  const backdropLocation = location.state?.backdropLocation;
  const [, callFirebaseFn] = useFirebaseFnCalling();

  async function handleSubmit(values: any) {
    setLoading(true);

    const userDoc = await firebase
      .firestore()
      .collection(Collection.USERPROFILES)
      .where("email", "==", values.email)
      .get();

    if (userDoc.docs.length) {
      callFirebaseFn(
        "resetPassword",
        { email: values.email },
        () => {
          setVisible(false);
          setLoading(false);
          message.success(
            `A reset link has been sent to the email provided & it will expire in 24 hours.`
          );
        },
        (e) => {
          setLoading(false);
          message.error(`Unable to send the email, please try again.`);
        }
      );
    }
  }

  return (
    <Modal
      afterClose={() => {
        if (backdropLocation) {
          history.push(backdropLocation.pathname);
        } else {
          history.push("/");
        }
      }}
      visible={visible}
      centeredBody={true}
      destroyOnClose
      onCancel={() => setVisible(false)}
      logoAsAvatar={true}
    >
      <H1>Forgot Password</H1>
      <ModalForm onFinish={handleSubmit} form={form}>
        <Form.Item
          name="email"
          style={{ width: "100%" }}
          validateFirst
          rules={[
            {
              required: true,
              message: "Please provide your email id.",
            },
            {
              type: "email",
              message: "Please provide a valid email id.",
            },
          ]}
        >
          <Input
            autoFocus
            prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Email"
          />
        </Form.Item>

        <PrimaryButton type="primary" htmlType="submit" disabled={loading}>
          {loading ? <LoadingOutlined /> : "Send Reset Link"}
        </PrimaryButton>
      </ModalForm>
    </Modal>
  );
}

export default ForgotPassword;
