import { useState, CSSProperties } from "react";
import { DatePicker, Tooltip, Checkbox, Form } from "antd";
import { Rule, FormInstance } from "antd/lib/form";

type ReleaseDateProps = {
  form: FormInstance<unknown>;
  style?: CSSProperties;
  rules?: Rule[];
};

function ReleaseDate(props: ReleaseDateProps) {
  const { validateFields } = props.form;
  const { style } = props;
  const [tba, setTba] = useState<boolean>();

  return (
    <div style={style}>
      <Form.Item
        style={{ width: "65%", marginLeft: "0px", marginRight: "0px" }}
        name="releaseDate"
        rules={[
          {
            validator: (rule, value, callback) => {
              if (!value && !tba) {
                callback("Please enter a release date or choose TBA.");
              } else {
                callback();
              }
            },
          },
        ]}
      >
        <DatePicker
          style={{ width: "100%" }}
          placeholder="Release date"
          disabled={tba}
          {...props.form}
          format="DD-MM-YYYY"
        />
      </Form.Item>
      <Form.Item
        style={{ marginLeft: "10px", width: "30%", marginRight: "0px" }}
      >
        <Tooltip title="To be announced">
          <Checkbox
            checked={tba}
            onChange={(e) => {
              setTba(e.target.checked);
              setTimeout(() => {
                validateFields(["releaseDate"]);
              }, 500);
            }}
          >
            TBA
          </Checkbox>
        </Tooltip>
      </Form.Item>
    </div>
  );
}

export default ReleaseDate;
