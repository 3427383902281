enum Colors {
  PRIMARY = "#000000",
  SECONDARY = "#FFFFFF",
  LIGHTER_GRAY = "#F3F3F3",
  GRAY = "#CCCCCC",
  DARK_GRAY = "#6F6F6F",
  DANGER = "#F5222D",
  SUCCESS = "#00FF00",
  BLACK = "#000000",
  LIGHT_GRAY = "#80808024",
  LIGHTEST_GRAY = "#8080801a",
  SELECTION_BACKGROUND = "#FCFC30",
}

const colors = {
  //Also part of config-overrides.js
  layoutBodyBackground: Colors.LIGHTER_GRAY, //Main Background
  headingColor: Colors.PRIMARY, // Headline text
  textColor: Colors.PRIMARY, // Paragraph color
  linkColor: Colors.PRIMARY, //Link default color
  layoutHeaderBackground: Colors.PRIMARY, // Main Header background
  layoutFooterBackground: Colors.PRIMARY, //Main Footer Background
  btnPrimaryColor: Colors.LIGHTER_GRAY, // Primary button text
  btnPrimaryBg: Colors.PRIMARY, // Primary button background
  btnDefaultColor: Colors.PRIMARY, //Secondary/default button text color
  btnDefaultBg: Colors.LIGHTER_GRAY, //Secondary/default Button background
  inputBorderColor: Colors.PRIMARY, // Input Border Color
  inputColor: Colors.PRIMARY, //Input text color
  tertiaryTextColor: Colors.DARK_GRAY,
  promoBannerBackground: Colors.SECONDARY,

  //Not part of config-overrides.js
  linkHighlight: Colors.PRIMARY,
  linkVisited: Colors.PRIMARY,
  componentBackground: Colors.SECONDARY,
  mainHeaderText: Colors.LIGHTER_GRAY,
  mainFooterText: Colors.LIGHTER_GRAY,

  //additional
  aboutPageSectionBorder: Colors.LIGHTEST_GRAY,
  innerComponentBackground: Colors.LIGHTER_GRAY, // components like Activity Stats widget, Notification Popup
  innerComponentBorder: Colors.PRIMARY,
  dangerText: Colors.DANGER,
  successText: Colors.SUCCESS,
  //deleteText: Colors.GRAY, commenting in favour of tertiary text color
  imageBackdrop: Colors.BLACK,
  mediaContainerButtonBackground: Colors.BLACK,
  mediaContainerButtonText: Colors.LIGHTER_GRAY,
  modalAvatarBackground: Colors.PRIMARY,
  avatarBackground: Colors.GRAY,
  footerButtonBackground: Colors.SECONDARY,
  footerButtonText: Colors.PRIMARY,
  readNotificationRow: Colors.LIGHT_GRAY,
  mobileSelectedNavMenuBg: Colors.LIGHTER_GRAY,
  textSelectBackground: Colors.SELECTION_BACKGROUND,
  componentShadow: "0px 2px 5px rgb(0,0,0,0.07)", // Component and avatar shadow
};
export const mobileFontSize = {
  XS: "12px",
  S: "14px",
  M: "16px",
  L: "18px",
  XL: "20px",
  XXL: "24px",
};
const fontSizes = {
  //numbers displayed in the 'stats per timeline widget' (Accomplished/WiP shared/Question Answered widget)
  activityStats: { desktop: "20px", mobile: mobileFontSize.XL },

  gameShowcaseHeading: { desktop: "20px", mobile: mobileFontSize.L },
  //Games item in Game Showcase and in the Games page
  gameProfile: {
    name: { desktop: "16px", mobile: mobileFontSize.M },
    description: { desktop: "14px", mobile: mobileFontSize.S },
  },

  //The spinner loading icon used for widget content load, page load or when button is processing
  loadingIcon: {
    small: "1rem",
    large: "1.5rem",
  },

  //On User page the profile section
  profileDisplay: {
    primary: { desktop: "18px", mobile: mobileFontSize.L },
    secondary: { desktop: "18px", mobile: mobileFontSize.S },
    description: { desktop: "15px", mobile: mobileFontSize.S },
    otherInfo: { desktop: "14px", mobile: mobileFontSize.S },
  },

  //the long description displayed on the game profile screen
  gameDescription: { desktop: "14px", mobile: mobileFontSize.S },

  //stats text on profile
  stats: { desktop: "15px", mobile: mobileFontSize.S },

  //Used for heading on modal
  h1: { desktop: "24px", mobile: mobileFontSize.XXL },

  //Used for timeline heading
  h2: { desktop: "18px", mobile: mobileFontSize.L },

  //The right top Profile menu
  profileMenu: "1.2rem",

  //Texts on Button
  button: {
    small: "0.8rem", //used for WiP form
    default: "1rem", //All other place
    large: "1.2rem", // not in use as of now
  },

  timelineItem: {
    main: { desktop: "15px", mobile: mobileFontSize.M }, //main text
    edited: { desktop: "12px", mobile: mobileFontSize.XS }, //main text
    action: { desktop: "12.5px", mobile: mobileFontSize.XS }, //like, comment, delete
    tag: { desktop: "12.5px", mobile: mobileFontSize.XS }, //Add Game / selected game text
    time: { desktop: "12px", mobile: mobileFontSize.XS }, //Time
    authorName: { desktop: "14px", mobile: mobileFontSize.S },
  },

  detailsOnModal: {
    main: "18px",
    lineHeight: "22px",
  },

  //Menu items on header
  header: "1rem",

  //Top Streak, Shares and Helpers widget
  topActivities: {
    mainText: "15px",
    mainTextLineHeight: "23px",
  },

  //Trending Games, Releasing Soon, My Games
  gameWidget: {
    mainText: "15px",
    mainTextLineHeight: "23px", //ideally font-size + 8
    otherText: "12px",
    otherTextLineHeight: "20px",
  },

  notification: {
    title: { fontSize: "16px", lineHeight: "18px" },
    content: { fontSize: "13px", lineHeight: "16px" },
  },
};

const layoutSizes = {
  //overall website layout margin
  horizontalMargin: { S: "9%", M: "18%", L: "22%", XL: "26%", XXL: "30%" },

  //space between avatar and text
  //desktop & mobile : on games screen: vertical space between avatar and game title
  //mobile : on top activities(streak/wip/answerer): vertical space between avatar and stat count
  //mobile : on total activity count stats: vertical space between avatar and stats count
  avatarTitleSpace: { desktop: "7px", mobile: "5px" },

  spaceBetweenComponents: { desktop: "15px", mobile: "10px" },

  //Ideally below two should sum up to 100%
  //timeline layout
  timelineWidth: "65%",
  //Widget layout width
  widgetWidth: "35%",

  //Height of the skeleton lines
  skeletonLineHeight: "10px",

  //Avatar Size of Active Members
  activeMemberAvatarSize: 45, //px

  //Game Screenshot thumbnail/placeholder size
  gameScreenshotSize: {
    width: { desktop: "18%", mobile: "90px" },
    height: { desktop: "66px", mobile: "50.62px" },
  },

  //Top Streak, Shares and Helpers widget
  topActivitiesTopBottomPadding: "2px",

  //Trending Games, Releasing Soon, My Games
  gameWidgetTopBottomPadding: "5px",

  //Activities timeline (goals, wips, questions, trending questions)
  activitiesTopBottomPadding: "15px",

  notification: {
    avatarSize: 35, //in px
    maxPopupHeight: "450px",
    maxPopupWidth: "325px",
    paddingOfListItems: "10px",
  },

  //space between content and edges for timeline and widgets
  componentPadding: {
    desktop: { leftRight: "25px", topBottom: "15px" },
    mobile: { leftRight: "15px", topBottom: "15px" },
  },

  //Space between content and secondary text of timeline
  spaceBetweenContentAndSecondaryText: {
    desktop: "10px",
    mobile: "10px",
  },
};

export interface StyleClosetTheme {
  //   breakpoints: string[];
  fontSizes: typeof fontSizes;
  layoutSizes: typeof layoutSizes;
  mobileFontSize: typeof mobileFontSize;
  colors: { [key in keyof typeof colors]: string };
}

const theme: StyleClosetTheme = {
  colors: colors,
  fontSizes: fontSizes,
  layoutSizes: layoutSizes,
  mobileFontSize,
};

export default theme;
