import { ReactNode } from "react";
import firebase from "firebase/app";
import { EntityExtendedType, EntityType, WiP } from "../types";
import {
  getFirstLineOfText,
  getThumbnailStorageUrl,
  parseVideo,
} from "../utils";
import MediaViewer from "./MediaViewer";
import EditTextBadge from "./EditTextBadge";
import EditCommentForm from "./EditCommentForm";
import EditWiPText from "./EditWiPText";
import GameTag from "./GameTag";
import EntityLink from "./EntityLink";
import WipMediaWrapper from "./WipMediaWrapper";
import WipMediaContainer from "./WipMediaContainer";
import EntityDelete from "./EntityDelete";
import styled, { css } from "styled-components/macro";
import GoalForm from "./GoalForm";
import { useEditContentContext } from "../stores/edit-content-context";
import QuestionForm from "./QuestionForm";
import { DEVICE_SIZES, ENTITY_PATHS } from "../config";
import Linkify from "linkifyjs/react";
import EntityEdit from "./EntityEdit";
import { useHistory, useLocation, Link } from "react-router-dom";

const MainTextContainer = styled.div`
  display: flex;
  min-width: 0;
  align-items: center;

  @media (${DEVICE_SIZES.tablet}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const MainText = styled.div<{
  displayDelete: boolean;
  fullWidthMainText: boolean;
}>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.displayDelete
      ? "calc(100% - 110px) 110px" //options at right side of pending goal text is of 110px `OtherOptions`
      : props.fullWidthMainText
      ? "1fr"
      : "7fr 2fr"};
  @media (${DEVICE_SIZES.tablet}) {
    grid-template-columns: 1fr;
  }
`;

const OtherOptions = styled.div<{
  linkableMainText: boolean;
  displayDelete: boolean;
}>`
  margin-left: auto;

  .edit-action,
  .delete-action {
    justify-self: center;
  }

  ${(props) =>
    props.linkableMainText
      ? css`
          display: block;
        `
      : css`
          /* Pending goals */
          display: grid;
          grid-template-columns: 70px 20px 20px;
          & > *:not(:last-child) {
            margin-right: 5px;
          }
          align-items: center;
        `}
  @media (${DEVICE_SIZES.tablet}) {
    ${(props) =>
      props.linkableMainText
        ? css`
            display: none;
          `
        : css`
            /* Pending goals */
            display: grid;
            & > *:not(:last-child) {
              margin-right: 5px;
            }
            grid-template-columns: ${props.displayDelete
              ? "70px 30px 30px"
              : "1fr"};
            align-items: center;
          `}
  }
`;

function getEditForm(entityType?: EntityType, pathPrefix?: string): ReactNode {
  switch (entityType) {
    case "goal":
      return <GoalForm />;
    case "question":
      return <QuestionForm />;
    case "wip":
      return <EditWiPText />;
    case undefined:
      return pathPrefix ? <EditCommentForm pathPrefix={pathPrefix} /> : null;
  }
}

type MentionsOutputPropsType = {
  text: string;
};

const CommentContainer = styled.div`
  white-space: "pre-line";

  .mention {
    background-color: #eff4fc;
  }
`;

const REGEX = /(\n\r?)|(@\[\w+\]\(\w+\))/gi;
const USERNAME_GROUPED_REGEX = /@\[(\w+)\]\(\w+\)/gi;
const LINE_BREAK_REGEX = /\n\r?/;

function MentionsOutput({ text }: MentionsOutputPropsType) {
  const elements = text
    .split(REGEX)
    .filter((x) => x)
    .map((item, i) => {
      if (LINE_BREAK_REGEX.test(item)) {
        return <br key={i} />;
      }

      if (USERNAME_GROUPED_REGEX.test(item)) {
        const username = item.replace(USERNAME_GROUPED_REGEX, `$1`);
        const linkName = `@${username}`;
        return (
          <Link key={i} to={`/users/${username}`} className="mention">
            {linkName}
          </Link>
        );
      }

      return item;
    });

  return <Linkify>{elements}</Linkify>;
}

function EntityContent<T extends EntityExtendedType>(props: {
  entity: T;
  pathPrefix: string;
  entityType?: EntityType;
  noGameDetails?: boolean;
  displayDelete?: boolean;
  linkableMainText?: boolean;
  fullWidthMainText?: boolean;
  handleDelete?: (id: string) => void;
}) {
  const location = useLocation();
  const history = useHistory();
  const currentUser = firebase.auth().currentUser;
  const {
    entity,
    pathPrefix,
    entityType,
    noGameDetails = false,
    displayDelete = false,
    linkableMainText = true,
    handleDelete,
    fullWidthMainText = false,
  } = props;
  let mediaUrl =
    entityType === "wip" ? ((entity as unknown) as WiP).mediaUrl : "";
  let mediaThumbnail = "";
  let hasThumbnail = false;
  let hasExternalUrl = mediaUrl && !entity.mediaName;

  const videoType = parseVideo(mediaUrl).type;
  const isMediaVideo = Boolean(videoType);

  if (mediaUrl && !isMediaVideo) {
    mediaThumbnail = getThumbnailStorageUrl(mediaUrl, 250);
    hasThumbnail = entity.thumbs.includes(`${entity.mediaName}_250x250`);
  }

  const editContext = useEditContentContext();

  return (
    <>
      <MainText
        fullWidthMainText={fullWidthMainText}
        displayDelete={displayDelete}
      >
        {editContext && editContext.editStatusObj.isEdit ? (
          getEditForm(entityType, pathPrefix)
        ) : linkableMainText && entityType ? (
          <MainTextContainer>
            <EntityLink
              className="main-text truncate"
              entityType={entityType}
              id={entity.id}
            >
              {getFirstLineOfText(entity.text)}
            </EntityLink>
            {entity.isEdited ? (
              <EditTextBadge entity={entity} entityType={entityType} />
            ) : null}
          </MainTextContainer>
        ) : (
          <MainTextContainer>
            <span
              className={`main-text ${
                fullWidthMainText ? "break-word" : "truncate"
              }`}
            >
              {!entityType ? (
                <CommentContainer>
                  <MentionsOutput text={entity.text} />
                </CommentContainer>
              ) : (
                <>{getFirstLineOfText(entity.text)} </>
              )}

              {fullWidthMainText ? (
                <EditTextBadge entity={entity} entityType={entityType} />
              ) : null}
            </span>
            {!fullWidthMainText ? (
              <EditTextBadge entity={entity} entityType={entityType} />
            ) : null}
          </MainTextContainer>
        )}
        <OtherOptions
          linkableMainText={linkableMainText}
          displayDelete={displayDelete}
        >
          <GameTag
            entity={entity}
            entityType={entityType}
            currentUser={currentUser}
            noGameDetails={noGameDetails}
            pathPrefix={pathPrefix}
          />
          {displayDelete && handleDelete && (
            <>
              <EntityEdit type="icon" entity={entity} />
              <EntityDelete
                type="icon"
                entity={entity}
                handleDelete={handleDelete}
                entityType={entityType || "comment"}
              />
            </>
          )}
        </OtherOptions>
      </MainText>
      {mediaUrl && (
        <WipMediaContainer>
          <WipMediaWrapper>
            <MediaViewer
              media={
                isMediaVideo
                  ? mediaUrl
                  : hasThumbnail
                  ? mediaThumbnail
                  : mediaUrl
              }
              type={isMediaVideo ? "video" : "image"}
              videoType={videoType}
              alt={getFirstLineOfText(entity.text)}
              onClick={() =>
                entityType &&
                history.push({
                  pathname: `/${ENTITY_PATHS[entityType]}/${entity.id}/attachment`,
                  state: { backdropLocation: location },
                })
              }
              hasThumbnail={hasExternalUrl || hasThumbnail}
            />
          </WipMediaWrapper>
        </WipMediaContainer>
      )}
    </>
  );
}
export default EntityContent;
