import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom";
import App from "./App";
import GlobalStyle from "./components/GlobalStyle";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components/macro";
import theme from "./theme";
import firebase from "firebase/app";
import { getConfig } from "./utils";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as _ from "styled-components/cssprop";
import "firebase/functions";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

Sentry.init({
  dsn: "https://be9ce99e2b2e4c6d9dada2a8d81ca337@sentry.io/2435037",
  blacklistUrls: [/localhost/, /127.0.0.0/],
  ignoreErrors: ["ResizeObserver loop limit exceeded"],
});

firebase.initializeApp(getConfig().firebase);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Sentry.ErrorBoundary>
      <BrowserRouter>
        <GlobalStyle />
        <App />
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  </ThemeProvider>,
  document.getElementById("root")
);
