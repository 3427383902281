import { Form, message } from "antd";
import firebase from "firebase/app";
import { useEffect } from "react";
import { questionMaxLength } from "../config";
import useWindowSize, { WindowSize } from "../hooks/useWindowSize";
import { useEditContentContext } from "../stores/edit-content-context";
import { useUserProfileState } from "../stores/UserProfileProvider";
import { AnalyticsEvent, Collection, Question } from "../types";
import { createQuestion, logEvents } from "../utils";
import ActivityTextInput from "./ActivityTextInput";
import ModalForm from "./ModalForm";

function QuestionForm() {
  const [form] = Form.useForm();
  const userProfile = useUserProfileState();
  const context = useEditContentContext();
  const windowSize = useWindowSize();
  const isSmallScreen = windowSize <= WindowSize.md;

  useEffect(() => {
    if (context && context.editStatusObj.text) {
      form.setFieldsValue({
        question: context.editStatusObj.text,
      });
    }
  }, [context, form]);

  async function handleSubmit() {
    if (!userProfile) {
      throw new Error(`userProfile cant be falsy`);
    }
    if (!context) {
      const query = firebase.firestore().collection(Collection.QUESTIONS).doc();
      const question: Question = createQuestion(
        query.id,
        form.getFieldValue("question"),
        userProfile
      );
      query.set({ ...question }).then(
        () => {
          form.setFieldsValue({ question: "" });
          logEvents(AnalyticsEvent.questions, {
            user_id: userProfile.username,
          });
        },
        (e) => {
          console.log(e);
          message.error(`Unable to save the post, please try again.`);
          throw e;
        }
      );
    } else if (context.editStatusObj.isEdit && context.editStatusObj.id) {
      try {
        context.setEditObjStatus({ isEdit: false });
        await firebase
          .firestore()
          .collection(Collection.QUESTIONS)
          .doc(context.editStatusObj.id)
          .update({
            text: form.getFieldValue("question"),
            modifiedBy: userProfile.username,
            modifiedOn: firebase.firestore.FieldValue.serverTimestamp(),
            isEdited: true,
          } as Question);
      } catch (e) {
        console.log(e);
        message.error(`Unable to save the post, please try again.`);
        throw e;
      }
    }
  }

  return (
    <ModalForm form={form} style={{ width: "100%" }}>
      <Form.Item
        style={{ width: "100%" }}
        name="question"
        rules={[
          {
            required: true,
            message: "Please type something to start a discussion.",
          },
          { max: questionMaxLength, message: "Max 120 characters allowed." },
        ]}
        className="remove-bottom-margin"
      >
        <ActivityTextInput
          autoFocus={!isSmallScreen}
          placeholder="Type something here and press Enter to submit"
          onSubmit={handleSubmit}
        />
      </Form.Item>
    </ModalForm>
  );
}

export default QuestionForm;
