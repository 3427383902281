import styled, { css } from "styled-components/macro";
import { Comment } from "antd";
import { DEVICE_SIZES } from "../config";
import { CommentProps } from "antd/lib/comment";

const ExtendedComment = ({
  noAvatar = false,
  ...others
}: { noAvatar: boolean } & CommentProps) => <Comment {...others} />;

const EntityItem = styled(ExtendedComment)`
  &&& .ant-comment-inner {
    padding: ${(props) =>
      `${props.theme.layoutSizes.activitiesTopBottomPadding} 0`};
  }

  &&& .ant-comment-content {
    align-self: center;
  }

  &&& .ant-comment-content-detail {
    font-size: ${(props) => props.theme.fontSizes.timelineItem.main.desktop};
    img {
      font-size: ${(props) =>
        props.theme.fontSizes.timelineItem.action.desktop};
    }
    @media (${DEVICE_SIZES.tablet}) {
      font-size: ${(props) => props.theme.fontSizes.timelineItem.main.mobile};
      img {
        font-size: ${(props) =>
          props.theme.fontSizes.timelineItem.action.mobile};
      }
    }
  }
  &&& .ant-comment-content-author-name h3 {
    color: ${(props) => props.theme.colors.textColor};
  }

  &&& .ant-comment-content-author {
    margin: 0;
  }

  &&& .ant-comment-actions > li a,
  &&& .ant-comment-actions > li span {
    font-size: ${(props) => props.theme.fontSizes.timelineItem.action.desktop};
    @media (${DEVICE_SIZES.tablet}) {
      font-size: ${(props) => props.theme.fontSizes.timelineItem.action.mobile};
    }

    color: ${(props) => props.theme.colors.tertiaryTextColor};
  }

  &&& .ant-comment-actions li:last-child {
    margin-left: auto;
  }

  &&& .ant-comment-actions li:last-child * {
    padding-right: 0;
  }

  &&& .ant-comment-actions li:first-child:not(:last-child) {
    /* overriding if there is only one child */
    float: left;
  }
  &&& .ant-comment-content-detail .main-text {
    /* applied to grid created in EntityContent */
    justify-self: start;
    font-size: ${(props) => props.theme.fontSizes.timelineItem.main.desktop};
    @media (${DEVICE_SIZES.tablet}) {
      width: 100%;
      font-size: ${(props) => props.theme.fontSizes.timelineItem.main.mobile};
    }
  }

  &&& .ant-comment-content-detail .edited-label {
    font-size: ${(props) => props.theme.fontSizes.timelineItem.edited.desktop};
    @media (${DEVICE_SIZES.tablet}) {
      font-size: ${(props) => props.theme.fontSizes.timelineItem.edited.mobile};
    }
  }

  &&& .ant-comment-actions .game-tag {
    display: none;
    @media (${DEVICE_SIZES.tablet}) {
      display: unset;
    }
  }
  &&& .game-tag {
    /* font-size: 1.2rem; */
    cursor: pointer;

    color: ${(props) => props.theme.colors.tertiaryTextColor};
    /* width: 18%; */
    text-decoration: underline;
    text-align: right;
    line-height: 1.5;
    font-size: ${(props) => props.theme.fontSizes.timelineItem.tag.desktop};
    @media (${DEVICE_SIZES.tablet}) {
      line-height: 12px;
      font-size: ${(props) => props.theme.fontSizes.timelineItem.tag.mobile};
    }
  }

  &&& .author-name {
    font-size: ${(props) =>
      props.theme.fontSizes.timelineItem.authorName.desktop};

    @media (${DEVICE_SIZES.tablet}) {
      font-size: ${(props) =>
        props.theme.fontSizes.timelineItem.authorName.mobile};
    }

    color: ${(props) => props.theme.colors.tertiaryTextColor};
  }
  &&& .ant-comment-actions {
    display: flex;
    align-items: flex-end;
    width: 100%;
    margin-top: ${(props) =>
      props.theme.layoutSizes.spaceBetweenContentAndSecondaryText.desktop};
    position: relative;
    /* have to uplift to make the rest of the card clickable */
    z-index: 1;

    .like-action,
    .comment-action,
    .edit-action {
      margin-right: 8px;
    }

    & > li > span {
      margin-right: 0; /**reset ant's style */
    }

    @media (${DEVICE_SIZES.tablet}) {
      display: grid;
      grid-template-columns: max-content max-content auto;
      margin-top: ${(props) =>
        props.theme.layoutSizes.spaceBetweenContentAndSecondaryText.mobile};
      gap: 5px;

      .like-action,
      .comment-action,
      .edit-action {
        margin-right: 0;
      }

      text-align: center;

      & > li > span {
        padding-right: 0; /**reset ant's style */
      }

      & > li:nth-child(3),
      & > li:nth-child(6) {
        justify-self: right;
      }
    }
  }

  &&& .ant-comment-avatar {
    .ant-checkbox {
      top: -0.5em;
    }
    ${(props) =>
      props.noAvatar
        ? css`
            display: none;
          `
        : css`
            /* have to uplift to make the rest of the card clickable */
            z-index: 1;
          `}
  }

  input {
    position: relative;
    z-index: 1;
  }
`;

export default EntityItem;
