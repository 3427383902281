import { RouteComponentProps } from "react-router-dom";
import CreateEditGame from "../components/CreateEditGame";
import CreateEditGameProfileForm from "../components/CreateEditGameProfileForm";
import useWindowSize, { WindowSize } from "../hooks/useWindowSize";

function CreateGame(props: RouteComponentProps) {
  const windowSize = useWindowSize();
  const isSmallScreen = windowSize <= WindowSize.md;

  if (isSmallScreen) {
    return <CreateEditGameProfileForm type="create" {...props} />;
  }

  return <CreateEditGame type="create" {...props} />;
}

export default CreateGame;
