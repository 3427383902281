import { useState } from "react";
import { Form, Input } from "antd";
import { RcFile } from "antd/lib/upload";
import styled from "styled-components/macro";
import { parseVideo, validateImageFile, validateImageLink } from "../utils";
import Button from "./Button";
import ButtonLink from "./ButtonLink";

export type MediaContentType = "image" | "video";
export type MediaSelectionType = "url" | "file";

const SaveButton = styled(Button)`
  margin: 10px 0;
`;

const SwitchLink = styled.div`
  display: block;
  a {
    text-decoration: underline;
  }
`;

const ErrorMessage = styled.div`
  color: ${(props) => props.theme.colors.dangerText};
`;

type MediaUrlProps = {
  type?: MediaContentType;
  onMediaSelect: (type: MediaSelectionType, media: string | RcFile) => void;
};

function MediaUrlForm({ type, onMediaSelect }: MediaUrlProps) {
  const [form] = Form.useForm();
  const [selectedFile, setSelectedFile] = useState<{
    file: RcFile;
    src: any;
  }>();
  const [fileSelectError, setFileSelectError] = useState<string>();
  function handleSubmit(values: any) {
    if (values.mediaUrl) {
      onMediaSelect("url", values.mediaUrl);
    }
  }

  function onSelect(e: React.ChangeEvent<HTMLInputElement>) {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0] as RcFile;
      validateImageFile(
        file,
        () => {
          const reader = new FileReader();
          reader.addEventListener("load", () =>
            setSelectedFile({ file: file, src: reader.result })
          );
          reader.readAsDataURL(file);
        },
        (e) => {
          setFileSelectError(e);
        }
      );
    }
  }

  const typeInMessage = type
    ? type === "image"
      ? `Image`
      : "Video"
    : "Image or Video";
  return (
    <>
      {selectedFile && selectedFile.src && selectedFile.file ? (
        <>
          <img
            src={selectedFile.src}
            alt="selected file"
            style={{
              maxWidth: "100px",
              maxHeight: "100px",
              display: "block",
              margin: "auto",
            }}
          />
          <SaveButton
            type="primary"
            onClick={() => {
              if (selectedFile) {
                onMediaSelect("file", selectedFile.file);
              } else {
                setFileSelectError("Please select a valid file");
                return false;
              }
            }}
          >
            Save
          </SaveButton>
        </>
      ) : (
        <Form onFinish={handleSubmit} form={form}>
          <Form.Item
            style={{ marginBottom: "0px" }}
            name="mediaUrl"
            rules={[
              {
                required: true,
                message: `Please input ${typeInMessage} Url`,
              },
              {
                type: "url",
                message: `Please input a valid ${typeInMessage} Url`,
              },
              {
                validator: (rule, value, callback) => {
                  if (type) {
                    if (type === "video") {
                      if (parseVideo(value).type) {
                        callback();
                      } else {
                        callback("Only Youtube or Vimeo URL allowed");
                      }
                    } else {
                      validateImageLink(value, (isValid) => {
                        if (isValid) {
                          callback();
                        } else {
                          callback(
                            "The URL doesn't seem contain a valid Image"
                          );
                        }
                      });
                    }
                  } else {
                    if (parseVideo(value).type) {
                      callback();
                    } else {
                      validateImageLink(value, (isValid) => {
                        if (isValid) {
                          callback();
                        } else {
                          callback(
                            "The URL doesn't seem to contain a valid image or video, please try again."
                          );
                        }
                      });
                    }
                  }
                },
              },
            ]}
            validateFirst
          >
            <Input placeholder="Paste URL" />
          </Form.Item>
          {type !== "video" && (
            <>
              <label htmlFor="upload-profile-pic">
                <SwitchLink>
                  {" "}
                  Or Select <ButtonLink as="span">a local file</ButtonLink>{" "}
                  {!type ? `(image only)` : ``}
                </SwitchLink>
              </label>
              <input
                style={{ display: "none" }}
                type="file"
                id="upload-profile-pic"
                onChange={onSelect}
              />
              {fileSelectError && (
                <ErrorMessage>{fileSelectError}</ErrorMessage>
              )}
            </>
          )}
          <SaveButton type="primary" htmlType="submit">
            Save
          </SaveButton>
        </Form>
      )}
    </>
  );
}

export default MediaUrlForm;
