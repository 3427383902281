import Button from "./Button";
import styled from "styled-components/macro";
import { DEVICE_SIZES } from "../config";
import { LoadingOutlined } from "@ant-design/icons";

const Wrapper = styled.div`
  text-align: center;
  margin-bottom: ${(props) =>
    props.theme.layoutSizes.spaceBetweenComponents.desktop};

  @media (${DEVICE_SIZES.tablet}) {
    margin-bottom: ${(props) =>
      props.theme.layoutSizes.spaceBetweenComponents.mobile};
  }
`;

const StyledButton = styled(Button)`
  &&& {
    @media (${DEVICE_SIZES.tablet}) {
      font-size: ${(props) => props.theme.mobileFontSize.XS};
    }
  }
`;

function LoadMoreButton({
  onPaginate,
  loading = false,
}: {
  onPaginate: () => void;
  loading: boolean;
}) {
  return (
    <Wrapper>
      <StyledButton size="small" onClick={() => onPaginate()}>
        {loading ? <LoadingOutlined /> : `Load More`}
      </StyledButton>
    </Wrapper>
  );
}

export default LoadMoreButton;
