import { LoadingOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import useFirebaseFnCalling from "../hooks/useFirebaseFnCalling";
import { AnalyticsEvent } from "../types";
import { logEvents } from "../utils";
import Button from "./Button";
import ModalForm from "./ModalForm";
import {
  PrimaryButton,
  ALink,
  SecondaryButtonWrapper,
} from "./SignInFlowComponents";

function InviteCodePanelForm() {
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();

  const [loading, callFunction] = useFirebaseFnCalling();

  async function handleSubmit(values: any) {
    await callFunction("checkCode", { code: values.code }, (response) => {
      logEvents(AnalyticsEvent.user_invites);
      history.replace({
        pathname: "/sign-up",
        state: {
          signUpData: {
            code: values.code,
            email: response.data.email,
          },
        },
      });
    });
  }

  return (
    <ModalForm onFinish={handleSubmit}>
      <Form.Item
        rules={[
          { required: true, message: "Please provide your invite code." },
        ]}
        style={{ width: "100%" }}
      >
        <Input autoFocus placeholder="code" />
      </Form.Item>

      <PrimaryButton type="primary" htmlType="submit" disabled={loading}>
        {loading ? <LoadingOutlined /> : "Check My Code"}
      </PrimaryButton>

      <ALink
        to={{
          pathname: "/request-access",
          state: { backdropLocation: location },
        }}
      >
        Request Access
      </ALink>
      <SecondaryButtonWrapper
        to={{
          pathname: "/request-access",
          state: { backdropLocation: location },
        }}
      >
        <Button>Request Access</Button>
      </SecondaryButtonWrapper>
    </ModalForm>
  );
}

export default InviteCodePanelForm;
