import { useState } from "react";
import firebase from "firebase/app";
import { message } from "antd";

function useFirebaseFnCalling() {
  const [loading, setLoading] = useState<boolean>(false);

  async function callFunction<T = any>(
    functionName: string,
    data: T,
    successCallback?: (response: any) => void,
    errorCallback?: (error: any) => void
  ) {
    setLoading(true);
    try {
      const func = firebase.functions().httpsCallable(functionName);
      const response = await func(data);
      successCallback && successCallback(response);
    } catch (e) {
      if (errorCallback) {
        errorCallback(e);
      } else {
        message.error(e.message);
      }
    } finally {
      setLoading(false);
    }
  }

  return [loading, callFunction] as const;
}
export default useFirebaseFnCalling;
