import { Suspense, useEffect, useState } from "react";
import { Layout } from "antd";
import { Link, useLocation } from "react-router-dom";
import Button from "./components/Button";
import ProfileMenu from "./components/ProfileMenu";
import NavMenu from "./components/NavMenu";
import Routes from "./components/Routes";
import Logo from "./components/Logo";
import FooterNav from "./components/FooterNav";
import SocialLinks from "./components/SocialLinks";
import NotificationPopup from "./components/NotificationPopup";
import styled from "styled-components/macro";
import UserProfileProvider from "./stores/UserProfileProvider";
import { NotificationProvider } from "./stores/notification-context";
import { DEVICE_SIZES } from "./config";
import firebase from "firebase/app";
import { useAuthState } from "react-firebase-hooks/auth";
import { ArrowUpOutlined, LoadingOutlined } from "@ant-design/icons";
import CookieConsent from "react-cookie-consent";
import { isTrackingAllowed } from "./utils";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import TwitterTracking from "react-twitter-conversion-tracker-plus";

const BackToTop = styled.div`
  width: 70px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colors.footerButtonBackground};
  cursor: pointer;
  margin-left: auto;
  &&& i {
    color: ${(props) => props.theme.colors.footerButtonText};
    font-size: 24px;
  }
`;

const StyledHeader = styled(Layout.Header)`
  display: grid;
  align-items: center;
  line-height: 0;

  grid-template-columns: 152px auto 150px 100px;
  padding: 0 ${(props) => props.theme.layoutSizes.horizontalMargin};
  .social-links {
    display: flex;
  }

  .profile {
    display: flex;
    justify-content: flex-end;
    min-width: 11%;
    margin-right: 10px;
  }
  padding: ${(props) => `0 ${props.theme.layoutSizes.horizontalMargin.XXL}`};
  @media (${DEVICE_SIZES.laptopXL}) {
    padding: ${(props) => `0 ${props.theme.layoutSizes.horizontalMargin.XL}`};
  }
  @media (${DEVICE_SIZES.laptopL}) {
    padding: ${(props) => `0 ${props.theme.layoutSizes.horizontalMargin.L}`};
  }
  @media (${DEVICE_SIZES.laptopM}) {
    padding: ${(props) => `0 ${props.theme.layoutSizes.horizontalMargin.M}`};
  }
  @media (${DEVICE_SIZES.laptopS}) {
    padding: ${(props) => `0 ${props.theme.layoutSizes.horizontalMargin.S}`};
  }
  @media (${DEVICE_SIZES.tablet}) {
    grid-template-columns: 100px auto 100px;
    padding: 0 10px;
    .profile {
      margin: 10px;
    }
    .logo {
      grid-column-start: 2;
      justify-self: center;
    }
    .nav-menu {
      grid-column-start: 1;
      grid-row-start: 1;
      width: 50px;
      margin: 10px;
    }
    .social-links {
      display: none;
    }
  }

  .ant-menu {
    line-height: 36px;
  }
  &&& .ant-menu-item {
    padding: 0 10px;
    font-size: ${(props) => props.theme.fontSizes.header};
  }
  box-shadow: ${(props) => props.theme.colors.componentShadow};
`;

const StyledFooter = styled(Layout.Footer)`
  display: grid;
  grid-template-columns: 152px auto 152px;
  padding: ${(props) => `0 ${props.theme.layoutSizes.horizontalMargin.M}`};
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 64px;
  align-items: center;

  grid-template-columns: 152px auto 152px;
  .social-links {
    display: none;
  }
  padding: ${(props) => `0 ${props.theme.layoutSizes.horizontalMargin.XXL}`};
  @media (${DEVICE_SIZES.laptopXL}) {
    padding: ${(props) => `0 ${props.theme.layoutSizes.horizontalMargin.XL}`};
  }
  @media (${DEVICE_SIZES.laptopL}) {
    padding: ${(props) => `0 ${props.theme.layoutSizes.horizontalMargin.L}`};
  }
  @media (${DEVICE_SIZES.laptopM}) {
    padding: ${(props) => `0 ${props.theme.layoutSizes.horizontalMargin.M}`};
  }
  @media (${DEVICE_SIZES.laptopS}) {
    padding: ${(props) => `0 ${props.theme.layoutSizes.horizontalMargin.S}`};
  }
  @media (${DEVICE_SIZES.tablet}) {
    grid-template-columns: auto 100px;
    padding: 0 5px;
    .ant-menu-item {
      padding: 0 5px;
    }
    .logo {
      display: none;
    }
    .back-to-top {
      display: none;
    }
    .social-links {
      justify-self: flex-end;
      display: block;
    }
  }
`;

const Content = styled(Layout.Content)`
  padding: ${(props) => `0 ${props.theme.layoutSizes.horizontalMargin.XXL}`};
  @media (${DEVICE_SIZES.laptopXL}) {
    padding: ${(props) => `0 ${props.theme.layoutSizes.horizontalMargin.XL}`};
  }
  @media (${DEVICE_SIZES.laptopL}) {
    padding: ${(props) => `0 ${props.theme.layoutSizes.horizontalMargin.L}`};
  }
  @media (${DEVICE_SIZES.laptopM}) {
    padding: ${(props) => `0 ${props.theme.layoutSizes.horizontalMargin.M}`};
  }
  @media (${DEVICE_SIZES.laptopS}) {
    padding: ${(props) => `0 ${props.theme.layoutSizes.horizontalMargin.S}`};
  }
  @media (${DEVICE_SIZES.tablet}) {
    padding: 0;
  }
`;

function App() {
  const [user, loading] = useAuthState(firebase.auth());
  const location = useLocation();
  const [needsToUpdateTracking, setNeedsToUpdateTracking] = useState(true);

  useEffect(() => {
    if (needsToUpdateTracking) {
      if (isTrackingAllowed()) {
        TwitterTracking.init("o4b5s");
        ReactPixel.init("532518027465916", undefined, {
          autoConfig: true,
          debug: false,
        });
        ReactGA.initialize("UA-132391848-3");
      }
      setNeedsToUpdateTracking(false);
    }
  }, [needsToUpdateTracking]);

  return (
    <UserProfileProvider uid={user?.uid}>
      <NotificationProvider>
        <CookieConsent
          enableDeclineButton
          buttonText="Accept"
          declineButtonText="Decline"
          declineButtonStyle={{
            marginRight: 0,
            backgroundColor: "transparent",
            color: "#fff",
          }}
          buttonClasses="ant-btn"
          style={{
            background: "#000",
            color: "#fff",
          }}
          buttonStyle={{
            backgroundColor: "#fff",
          }}
          onAccept={() => setNeedsToUpdateTracking(true)}
          onDecline={() => setNeedsToUpdateTracking(true)}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
        <Layout style={{ minHeight: "100vh", position: "relative" }}>
          <StyledHeader>
            <Logo />
            <NavMenu className="nav-menu" />

            <SocialLinks
              style={{
                margin: "0 10px 0 auto",
              }}
            />

            <div className="profile">
              {loading ? (
                <LoadingOutlined />
              ) : user ? (
                <>
                  <NotificationPopup />
                  <ProfileMenu />
                </>
              ) : (
                <Link
                  to={{
                    pathname: "/sign-in",
                    state: { backdropLocation: location },
                  }}
                >
                  <Button
                    css={`
                      &&& {
                        @media (${DEVICE_SIZES.tablet}) {
                          height: 23px;
                          line-height: 16px;
                          padding: 0 10px;
                        }
                      }
                    `}
                  >
                    Sign In
                  </Button>
                </Link>
              )}
            </div>
          </StyledHeader>

          <Suspense
            fallback={
              <div
                css={`
                  position: absolute;
                  width: 100%;
                  height: 100vh;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                `}
              >
                <LoadingOutlined
                  css={`
                    font-size: 24px;
                  `}
                />
              </div>
            }
          >
            <Content
              style={{
                paddingBottom: "100px", //64px (height of footer) + 10px (extra space between last content and footer)
              }}
            >
              <Routes />
            </Content>
          </Suspense>

          {!loading && (
            <StyledFooter>
              <Logo />
              <FooterNav />
              <BackToTop
                className="back-to-top"
                onClick={() => {
                  document.body.scrollTop = 0;
                  document.documentElement.scrollTop = 0;
                }}
              >
                <ArrowUpOutlined style={{ fontSize: 24 }} />
              </BackToTop>
              <SocialLinks />
            </StyledFooter>
          )}
        </Layout>
      </NotificationProvider>
    </UserProfileProvider>
  );
}

export default App;
