import { Menu, Dropdown } from "antd";
import { Link, useLocation } from "react-router-dom";
import useWindowSize, { WindowSize } from "../hooks/useWindowSize";
import Button from "./Button";
import theme from "../theme";
import styled from "styled-components/macro";
import { MenuOutlined } from "@ant-design/icons";

const BurgerButton = styled(Button)`
  &&& {
    background: ${(props) => props.theme.colors.btnPrimaryBg};
    border: ${(props) => props.theme.colors.btnPrimaryBg};
    color: ${(props) => props.theme.colors.btnPrimaryColor};
    width: 20px;
    padding: 0;
    i {
      font-size: 20px;
    }
  }
`;
const menuDetails = [
  {
    path: "/",
    text: "Home",
    smallScreenOnly: true,
  },
  {
    path: "/games",
    text: "Games",
  },
  {
    path: "/goals",
    text: "Goals",
  },
  {
    path: "/wips",
    text: "WiPs",
  },
  {
    path: "/discussions",
    text: "Discuss",
  },
  {
    path: "/learn-more",
    text: "Learn More",
  },
];
function NavMenu(props: { className?: string }) {
  const { pathname } = useLocation();

  const windowSize = useWindowSize();
  const isSmallSize = windowSize <= WindowSize.md;
  const NavRawMenu = () => (
    <Menu {...props} theme={isSmallSize ? "light" : "dark"} mode={"horizontal"}>
      {menuDetails
        .filter((menu) => (isSmallSize ? menu : !menu.smallScreenOnly))
        .map((menu, index) => (
          <Menu.Item
            key={index}
            style={{
              background:
                pathname === menu.path && isSmallSize
                  ? theme.colors.mobileSelectedNavMenuBg
                  : "",
            }}
            className={
              pathname === menu.path && !isSmallSize
                ? "ant-menu-item-active"
                : ""
            }
          >
            <Link to={menu.path}>{menu.text}</Link>
          </Menu.Item>
        ))}
    </Menu>
  );
  return (
    <>
      {isSmallSize ? (
        <Dropdown
          overlay={NavRawMenu}
          placement="bottomLeft"
          trigger={["click"]}
        >
          <BurgerButton {...props}>
            <MenuOutlined />{" "}
          </BurgerButton>
        </Dropdown>
      ) : (
        <NavRawMenu />
      )}
    </>
  );
}

export default NavMenu;
